/* eslint-disable no-return-assign */
import { html, css, LitElement } from 'lit';
import { html as staticHtml, unsafeStatic } from 'lit/static-html.js';

import { customElement, property } from 'lit/decorators.js';
import '@kisters/wcp-base/components/ki-icon/ki-icon-btn';

@customElement('ww-simple-dashboard')
export default class WwSimpleDashboard extends LitElement {
  static styles = css`
    :host {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    .dashboard-container {
      padding: 10px;
      flex: 1;
      overflow: auto;
      height: 100%;
      display: grid;
      gap: 5px;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
    .dashboard-container > * {
      min-height: 250px;
      padding: 10px;
      display: flex;
      z-index: 1;
      flex-direction: column;
    }
    .count {
      padding: 0 5px;
      font-size: 0.8em;
      cursor: pointer;
    }

    .count:hover {
      font-size: 1em;
    }

    .count.selected {
      font-weight: bold;
      font-size: 1em;
    }

    @media only screen and (max-width: 1280px) {
      .dashboard-container {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    @media only screen and (max-width: 800px) {
      .dashboard-container {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    @media only screen and (max-width: 600px) {
      .dashboard-container {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  `;

  @property({ type: Array, attribute: false })
  stations = [];

  @property({ type: String, attribute: false })
  popup = '';

  @property({ type: String, attribute: false })
  layerAlias = '';

  @property({ type: Number })
  selectedStep = 1;

  _getNavItems() {
    const steps = [];
    let step = 1;
    for (let i = 0; i < this.stations.length; i += 8) {
      steps.push(html`<span
        @click="${e => {
          this.selectedStep = parseInt(e.target.innerText, 10);
        }}"
        class="count ${this.selectedStep === step ? 'selected' : ''}"
      >
        ${step}
      </span>`);
      step += 1;
    }
    return steps;
  }

  update(changedProperties: Map<string, any>) {
    if (
      changedProperties.has('stations') &&
      changedProperties.get('stations')
    ) {
      this.selectedStep = 1;
    }
    super.update();
  }

  render() {
    const tag = unsafeStatic(this.popup);
    return html`
      <div class="dashboard-container">
        ${this.stations
          .slice(this.selectedStep * 8 - 8, this.selectedStep * 8)
          .map(
            station =>
              staticHtml`<${tag} flexSize .layerAlias="${
                this.layerAlias
              }" .stations="${[station]}"></${tag} >`,
          )}
      </div>
      <div style="text-align: center;">${this._getNavItems()}</div>
    `;
  }
}

import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { Mix } from '@kisters/wcp-base/common';
import { i18nMixin } from '@kisters/wcp-base/decorators';
import dayjs from '@kisters/wcp-base/common/dayjsext';
import { echartsConfigTemplate } from '@kisters/ts-graph/EchartsConfigTemplate';
import nls from '../../locales/index';
import { getCurrentApi } from '../../api/API';
import { LayerIndexData, TsValue } from '../../api/zarrpi';

import '@kisters/ts-graph/ts-graph-echarts';
import { getIntervalTimeseriesData } from '../../api/zarrpi_helper';

// eslint-disable-next-line import/extensions

@customElement('ww-map-popup-zarr')
export default class WwMapPopupZarr extends Mix(LitElement, [
  i18nMixin,
  { nls },
]) {
  static styles = css`
    :host {
      display: block;
    }
    .station-name {
      padding-right: 5px;
      padding-left: 10px;
      padding-top: 10px;
      font-size: 1.2em;
    }

    hr {
      margin: 5px 0;
    }
    .table {
      display: table;
      line-height: 1.5em;
    }
    .row {
      display: table-row;
    }

    .hidden {
      display: none !important;
    }

    .highlight {
      font-weight: bold;
    }

    .cell {
      display: table-cell;
      padding: 0 10px;
      min-width: 50px;
      color: gray;
    }

    .label {
      color: #4a4a49;
    }

    .highcharts-label {
      display: none;
    }

    #chartcontainer {
      border-top: 1px dotted lightgray;
      height: 200px;
      min-width: 360px;
      width: 100%;
    }

    .remark {
      padding: 2px 10px;
      color: red;
      max-width: fit-content;
    }
  `;

  @property({ type: Array })
  stations: Array<LayerIndexData> = [];

  @property({ type: String })
  stationRemark; // Additional comment in left Menu

  _lastLoaded = '';

  @property({ type: Object })
  graphOptions;

  fontFamily: string | undefined;

  constructor() {
    super();
    this.stations = [];
    this.api = getCurrentApi();
    const formatter = new Intl.NumberFormat(this.i18n.language, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    this.valFormatter = {
      format(val) {
        return val === 0 ? val : formatter.format(val);
      },
    };
  }

  _setChartOptions(station: LayerIndexData) {
    const dataIndex = `${station.station_no}-${station.param_shortname}`;

    if (dataIndex === this._lastLoaded) {
      return;
    }
    this._lastLoaded = dataIndex;
    const graphOptions = echartsConfigTemplate;
    const now = dayjs();
    const max = now.valueOf();
    const min = now.add(-7, 'day').valueOf();
    graphOptions.xAxis.max = max;
    graphOptions.xAxis.min = min;
    graphOptions.graphic.style.text = this.i18n.t('NO_DATA');
    graphOptions.grid.bottom = '14%';
    graphOptions.grid.left = '3%';

    if (this.fontFamily && this.fontFamily.length > 0) {
      graphOptions.textStyle = {
        fontFamily: this.fontFamily,
      };
    }

    graphOptions.toolbox = {};

    graphOptions.yAxis[0] = {
      type: 'value',
      name: station.param_name,
      nameRotate: 90,
      nameLocation: 'center',
      nameGap: 30,
      alignTicks: true,
      index: 0,
      axisPointer: {
        label: {
          show: true,
          formatter: `{value} ${station.units}`,
        },
      },
    };

    this.graphOptions = graphOptions;

    this._loadChartData(station, min, max);
  }

  async _loadChartData(station: LayerIndexData, min: number, max: number) {
    const timeseriesMetadata =
      station.parameter[station.param_shortname]?.timeseries;

    if (!timeseriesMetadata) {
      return;
    }

    const timeseries = Object.keys(timeseriesMetadata);

    if (timeseries.length === 0) {
      return;
    }

    const tsData = await getIntervalTimeseriesData(
      station.site_no,
      station.station_no,
      station.param_shortname,
      timeseries[0],
      min,
      max,
    );

    const data = tsData?.data
      .filter(dat => dat.timestamp >= min && dat.timestamp <= max)
      .map(x => [x.timestamp, x.value]);

    this.graphOptions.graphic.invisible = (data?.length ?? 0) > 0;

    const yAxis = this.graphOptions.yAxis[0];

    if (yAxis) {
      yAxis.min = 'dataMin';
    }

    this.graphOptions = {
      ...this.graphOptions,
      series: [
        {
          type: 'line',
          name: station.param_name,
          parameterName: station.param_name,
          data,
          id: station.param_shortname,
          symbol: 'none',
          lineStyle: {
            width: 1,
          },
          animation: false,
          large: true,
          showSymbol: false,
          sampling: 'lttb',
          tooltip: {},
          yAxisIndex: 0,
        },
      ],
    };
  }

  /* eslint-disable-next-line class-methods-use-this */
  _getRow(tsValue: TsValue) {
    const parameter = tsValue.param_name;
    const value =
      tsValue.ts_value !== undefined
        ? `${this.valFormatter.format(tsValue.ts_value)} ${
            // Fixme: unit
            tsValue.units
          }`
        : '-';
    const timestamp = tsValue.timestamp
      ? dayjs(tsValue.timestamp).tz().format('L LT')
      : '-';
    return html`<div class="row">
      <div class="cell label">${parameter}</div>
      <div class="cell"><b>${value}</b> (${timestamp})</div>
    </div>`;
  }

  _valueTmpl() {
    const station = this.stations[0];
    return html`<div class="station-name">
        ${station.station_no} | ${station.station_name}
      </div>
      <hr />
      <div class="table">
        ${Object.values(station.ts_values)
          .reverse()
          .map(st => this._getRow(st))}
      </div> `;
  }

  updated(changedProperties) {
    if (changedProperties.has('stations')) {
      if (this.stations && this.stations.length < 2) {
        this._setChartOptions(this.stations[0]);
      }
    }
  }

  _stationTmpl(station: LayerIndexData) {
    return html`<div class="station-name">
        ${station.station_no} | ${station.station_name}
      </div>
      <div class="remark">${this.stationRemark}</div>
      <hr />
      <div class="table">
        ${station.ts_values
          ? Object.values(station.ts_values)
              .reverse()
              .map(st => this._getRow(st))
          : ''}
      </div> `;
  }

  render() {
    if (!this.stations || this.stations.length === 0) {
      return html``;
    }

    return html`${this.stations.map(station => this._stationTmpl(station))}
      <ts-graph-echarts
        style="height: 200px;${this.stations.length === 1
          ? ''
          : ' display: none;'}"
        .options="${this.graphOptions}"
      ></ts-graph-echarts>`;
  }
}

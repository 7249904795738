import { getConfig } from '@kisters/wcp-base/app';
import { getCurrentApi } from '../../api/API';
import { TsTemplate, ViewConfig } from '../../defs/GraphTypes';

/** Provide TsGraph with a basic set of default options and load additional meta information
 * @remark Loads/Stores TsTemplates-Wizard config
 * @remark Overwrite default configs by files in public folder
 */
export class ConfigLoader {
  private _api = getCurrentApi();

  private _viewConfig: Map<String, ViewConfig>;

  private _tsTemplates: Map<String, TsTemplate>;

  private _parameterColors: Map<string, string>;

  _appConfig;

  get appConfig() {
    return this._appConfig ? this._appConfig : getConfig();
  }

  constructor() {
    this.init();
  }

  init() {
    this._viewConfig = this.appConfig.viewConfig
      ? this.asMap(this.appConfig.viewConfig)
      : new Map();
    this._tsTemplates = this.appConfig.tsTemplate
      ? this.asMap(this.appConfig.tsTemplate)
      : new Map();
    this._parameterColors = this.appConfig.colors
      ? this.asMap(this.appConfig.colors)
      : new Map();
  }

  // eslint-disable-next-line class-methods-use-this
  asMap(jsonObject) {
    const map = new Map();
    for (const [key, value] of Object.entries(jsonObject)) {
      map.set(key, value);
    }
    return map;
  }

  /**
   * @param key in ViewConfig
   * @returns ViewConfig item or undefined
   */
  public getView(key: string): ViewConfig | undefined {
    return this._viewConfig?.get(key);
  }

  /**
   * @param key in TsTemplates
   * @returns TsTemplates item or undefined
   */
  public getTsTemplates(key: string): TsTemplate | undefined {
    return this._tsTemplates?.get(key);
  }

  /**
   * @param parameter Parameter shortName
   * @returns Color as a string or undefined if parameter is not found
   */
  public getParameterBaseColor(parameter: string): string | undefined {
    const color = this._parameterColors?.get(parameter);
    if (!color) console.warn('Color not found for paramter', parameter);
    return color;
  }
}

import * as echarts from 'echarts/core';

const localeObj = {
    "time": {
        "month": [
            "Janúar", "Febrúar", "Mars", "Apríl", "Maí", "Júní",
            "Júlí", "Ágúst", "September", "Október", "Nóvember", "Desember"
        ],
        "monthAbbr": [
            "Jan", "Feb", "Mar", "Apr", "Maí", "Jún",
            "Júl", "Ágú", "Sep", "Okt", "Nóv", "Des"
        ],
        "dayOfWeek": [
            "Sunnudagur", "Mánudagur", "Þriðjudagur", "Miðvikudagur", "Fimmtudagur", "Föstudagur", "Laugardagur"
        ],
        "dayOfWeekAbbr": [
            "Sun", "Mán", "Þri", "Mið", "Fim", "Fös", "Lau"
        ]
    },
    "legend": {
        "selector": {
            "all": "Allt",
            "inverse": "Öfugt"
        }
    },
    "toolbox": {
        "brush": {
            "title": {
                "rect": "Velja kassa",
                "polygon": "Velja lössu",
                "lineX": "Velja línulega átt",
                "lineY": "Velja loðrétt átt",
                "keep": "Halda vali",
                "clear": "Hreinsa val"
            }
        },
        "dataView": {
            "title": "Gagnaðsýning",
            "lang": ["Gagnaðsýning", "Loka", "Endurglæða"]
        },
        "dataZoom": {
            "title": {
                "zoom": "Ná nærri",
                "back": "Endurræsa nærmynd"
            }
        },
        "magicType": {
            "title": {
                "line": "Skiptu yfir í línugraf",
                "bar": "Skiptu yfir í súlurit",
                "stack": "Stafla",
                "tiled": "Flísarit"
            }
        },
        "restore": {
            "title": "Endurræsa"
        },
        "saveAsImage": {
            "title": "Vista sem mynd",
            "lang": ["Hægri smelltu til að vista mynd"]
        }
    },
    "series": {
        "typeNames": {
            "pie": "Hringurit",
            "bar": "Súlurit",
            "line": "Línurit",
            "scatter": "Dreifirit",
            "effectScatter": "Árásardreifirit",
            "radar": "Geimferðarit",
            "tree": "Tré",
            "treemap": "Trémynd",
            "boxplot": "Boxplot",
            "candlestick": "Kerzenstickrit",
            "k": "K-línu rit",
            "heatmap": "Hita rit",
            "map": "Kort",
            "parallel": "Samhliðar samhengiskort",
            "lines": "Línuferill",
            "graph": "Sambandsferill",
            "sankey": "Sankey ferlakort",
            "funnel": "Munahornrit",
            "gauge": "Mæli",
            "pictorialBar": "Myndrás súlurit",
            "themeRiver": "Mótumálarit",
            "sunburst": "Sólbjúgur"
        }
    },
    "aria": {
        "general": {
            "withTitle": "Þetta er graf um \"{title}\"",
            "withoutTitle": "Þetta er grafur"
        },
        "series": {
            "single": {
                "prefix": "",
                "withName": " með gerð {seriesType} sem heitir {seriesName}.",
                "withoutName": " með gerð {seriesType}."
            },
            "multiple": {
                "prefix": ". Hann samanstendur af {seriesCount} fjölda gerða.",
                "withName": " {seriesId} gerðin er {seriesType} sem táknar {seriesName}.",
                "withoutName": " {seriesId} gerðin er {seriesType}.",
                "separator": {
                    "middle": "",
                    "end": ""
                }
            }
        },
        "data": {
            "allData": "Gögnin eru eftirfarandi: ",
            "partialData": "Fyrstu {displayCnt} atriðin eru: ",
            "withName": "gögnin fyrir {name} eru {value}",
            "withoutName": "{value}",
            "separator": {
                "middle": ", ",
                "end": ". "
            }
        }
    }
};


echarts.registerLocale('IS', localeObj);

export default localeObj;
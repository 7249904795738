import './ki-loader';

const loaderElement = document.createElement('ki-loader');
export function Loader(): any {
  return (target: any, propertyKey: string, descriptor: any) => {
    console.log(target, propertyKey);
    let oldFunc = descriptor.value;

    descriptor.value = async function () {
      this.renderRoot.appendChild(loaderElement);
      const result = await oldFunc.apply(this, arguments);
      try {
        this.renderRoot.removeChild(loaderElement);
      } catch (e) {
        console.log(e);
      }
      return result;
    };
    return descriptor;
  };
}

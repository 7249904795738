import { groupBy } from 'lodash-es';
import dayjs from '@kisters/wcp-base/common/dayjsext';

import { Series, Station, Group } from '../../defs/GraphTypes';
import { KiwisQueryFromTo, KiwisQueryPeriod } from './KiwisQuery';
import { ConfigLoader } from '../ConfigLoader/ConfigLoader';
import { IService } from '../IService';

export class KiwisGraphService implements IService {
  url;

  showErrorMsg = false;

  constructor(path: string) {
    this.url = path.includes('//')
      ? new URL(path)
      : new URL(`${window.location.protocol}//${window.location.host}/${path}`);
  }

  private error(e) {
    console.error(
      `Connection to ${this.url} failed`,
      this.showErrorMsg ? e : '',
    );
  }

  /**
   * Fetch data routine
   * @param seriesList List of timeseries to fetch
   * @param configLoader Handle styling and meta information; e.g. import/configure via TsTemplates Wizard
   * @param from Unix (ms)
   * @param until Unix (ms)
   * @returns
   */
  async data(
    seriesList: Array<Series>,
    configLoader: ConfigLoader,
    from: Date | undefined,
    until: Date | undefined,
  ): Promise<Series[]> {
    const seriesMeta = seriesList.map(series => ({
      id: series.id,
      ...(configLoader.getTsTemplates(series.shortName) ?? {
        type: 'undefined',
        label: series.shortName,
        style: {},
      }),
    }));

    const seriesByType = groupBy(seriesMeta, 'type');

    if (seriesByType.threshold) {
      const thresholdIds = seriesByType.threshold.map(s => s.id).join();
      const thresholdQuery = {
        ts_id: thresholdIds,
        dateformat: 'UNIX',
        format: 'json',
        request: 'getTimeseriesValues',
        period: 'complete',
      };
      await this._fetchDataPeriodComplete(seriesList, thresholdQuery);
    }

    const typeList: Array<string> = Object.keys(seriesByType).filter(
      k => !['threshold'].includes(k),
    );

    if (typeList) {
      const seriesQueries: Array<KiwisQueryFromTo> = typeList.map(t => ({
        ts_id: seriesByType[t].map(s => `${s.id};Compress(2000)`).join(),
        dateformat: 'UNIX',
        format: 'json',
        request: 'getTimeseriesValues',
        from: from?.getTime().toString(),
        to: until?.getTime().toString(),
      }));

      await Promise.all(
        seriesQueries.map(query => this._fetchDataFromTo(seriesList, query)),
      );
    }

    return seriesList;
  }

  async _fetchDataFromTo(seriesList: Array<Series>, query: KiwisQueryFromTo) {
    try {
      const urlSearchParams = new URLSearchParams(query);
      const dataReq = await fetch(
        `${this.url.href}&${urlSearchParams.toString()}`,
      );
      const data = await dataReq.json();
      const resultMap = new Map(data.map(d => [d.ts_id.split(';')[0], d.data]));
      for (const series of seriesList) {
        if (resultMap.has(series.id)) {
          series.data = resultMap.get(series.id) ?? [];
        }
      }
      return seriesList;
    } catch (e) {
      this.error(e);
      return seriesList;
    }
  }

  async _fetchDataPeriodComplete(seriesList, query: KiwisQueryPeriod) {
    try {
      const urlSearchParams = new URLSearchParams(query);
      const dataReq = await fetch(
        `${this.url.href}&${urlSearchParams.toString()}`,
      );
      const data = await dataReq.json();
      for (const e of data) {
        // Add pivot point to ensure line is printed until today
        const x = e.data[e.data.length - 1];
        e.data.push([dayjs().add(3, 'year').valueOf(), x[1], x[2], x[3]]);

        const result = seriesList.find(
          series => series.id === e.ts_id.split(';')[0],
        );
        if (result) {
          result.data = e.data ?? [];
        }
      }
      return seriesList;
    } catch (e) {
      this.error(e);
      return seriesList;
    }
  }

  async groups(filter: object = {}): Promise<Group[]> {
    try {
      const query = {
        format: 'objson',
        returnfields: 'all',
        request: 'getGroupList',
        group_type: 'timeseries',
        ...filter,
      };
      const listReq = await fetch(
        `${this.url.href}&${new URLSearchParams(query).toString()}`,
      );
      const list = await listReq.json();
      return list.map((group: any) => ({
        id: group.group_id,
        name: group.group_name,
        description: group.group_remark,
      }));
    } catch (e) {
      this.error();
      return [];
    }
  }

  async timeseries(filter: object): Promise<Series[]> {
    try {
      const query = {
        format: 'objson',
        returnfields:
          'ts_clientvalue1,station_name,station_no,station_id,ts_id,coverage,stationparameter_name,stationparameter_longname,stationparameter_no,ts_shortname,ts_name,ts_type_name,ts_unitsymbol',
        request: 'getTimeseriesList',
        ...filter,
      };
      const listReq = await fetch(
        `${this.url.href}&${new URLSearchParams(query).toString()}`,
      );
      const list = await listReq.json();
      return list.map((series: any) => ({
        id: series.ts_id,
        name: series.ts_clientvalue1 || series.ts_name,
        shortName: series.ts_shortname,
        parameter: {
          unit: series.ts_unitsymbol,
          name:
            series.stationparameter_longname ?? series.stationparameter_name,
          shortName: series.stationparameter_no,
        },
        station: {
          id: series.station_id,
          name: series.station_name,
          shortName: series.station_no,
        },
      }));
    } catch (e) {
      this.error();
      return [];
    }
  }

  async stations(filter: object = {}): Promise<Station[]> {
    try {
      const query = {
        format: 'objson',
        returnfields: 'station_id,station_name,station_no,site_no,site_name',
        request: 'getStationList',
        ...filter,
      };
      const listReq = await fetch(
        `${this.url.href}&${new URLSearchParams(query).toString()}`,
      );
      const list = await listReq.json();
      return list.map((station: any) => ({
        id: station.station_id,
        name: station.station_longname ?? station.station_name,
        shortName: station.station_no,
        site: {
          id: station.site_id,
          name: station.site_name,
          shortName: station.site_no,
        },
      }));
    } catch (e) {
      this.error();
      return [];
    }
  }
}

/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */
import { html, css, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { initConfig, getRoutes } from '@kisters/wcp-base/app';
import { LoaderMixin } from '@kisters/wcp-base/common';
import { init } from '@kisters/wiski-web/api';

import importActions from './import-actions/import-actions';

// Custom Components
import '@kisters/ww-lanuv-common/components/ww-lanuv-start';
import '@kisters/ww-lanuv-common/components/ww-station-detail-lanuv-external';
import '@kisters/ww-lanuv-common/components/ww-lanuv-info';
import '@kisters/ww-lanuv-common/components/ww-lanuv-downloads';
import '@kisters/ww-lanuv-common/components/wwp-lanuv-berichte/wwp-lanuv-berichte';

// Popups
import '@kisters/ww-lanuv-common/components/ww-map-popup-lanuv'; //
import '@kisters/ww-lanuv-common/components/ww-map-popup-lanuv-simple'; //

// Graph
import '@kisters/ww-lanuv-common/components/ww-lanuv-graph/ww-lanuv-graph';
import '@kisters/ww-lanuv-common/components/ww-lanuv-graph/ww-lanuv-graph-precip';
import '@kisters/ww-lanuv-common/components/lanuv-ts-annotations/lanuv-ts-annotations';

import '@kisters/ww-lanuv-common/views/wwp-overview-lanuv';

@customElement('ww-app-index')
export default class wwAppIndex extends LoaderMixin(LitElement) {
  static styles = css`
    :host {
      height: 100%;
      width: 100%;
    }
  `;

  constructor() {
    super();
    this.configLoaded = false;
    this.configLocation = './config/app.json';
  }

  render() {
    if (!this.configLoaded) {
      return this._renderLoader();
    }
    // language=html
    return html` <ki-app-index .route="${getRoutes()}"></ki-app-index> `;
  }

  firstUpdated() {
    this.promiseLoader(
      initConfig(this.configLocation, importActions).then(config => {
        init({ dataPath: config.apiPath });
        this._config = config;
        this.configLoaded = true;
      }),
    );
  }
}

import { css } from 'lit';
// language=CSS
export default css`
  :host {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  #separator {
    width: 5px;
    height: 10%;
    background-color: #ccc;
    position: relative;
    top: 45%;
    cursor: col-resize;
  }

  header {
    height: 40px;
    display: flex;
    justify-content: space-between;
    padding: 2px 5px;
    border-bottom: 1px solid var(--theme-color-secondary);
  }

  .title {
    font-size: 1.3em;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    margin-left: 10px;
  }

  :host(.sm-screen) .title {
    margin-left: 50px;
  }

  ts-graph-echarts,
  main {
    display: flex;
    flex: 3;
  }

  main {
    overflow: hidden;
  }

  ki-icon-btn {
    box-shadow: none;
  }

  ts-graph-list {
    padding: 5px;
    flex: 1;
  }

  .actions {
    display: flex;
    align-items: center;
  }

  .actions > ki-icon-btn:hover {
    fill: var(--theme-color-primary, #111);
    background-color: #eee;
  }

  .actions > * {
    margin-left: 5px;
  }
`;

import deCommon from './de/common.json';
import enCommon from './en/common.json';
import isCommon from './is/common.json';

export default {
  de: {
    common: deCommon,
  },
  is: {
    common: isCommon,
  },
  en: {
    common: enCommon,
  },
};

// Shared modules:        @kisters/ww-lanuv-common/<module>
// App specific module:   ../<module>

export default [
  {
    component: 'ww-app',
    importAction: async () => {
      await import('../app/ww-app');
    },
  },
];

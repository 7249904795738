/* eslint-disable func-names */
/* eslint-disable array-callback-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */
import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
// eslint-disable-next-line import/extensions
import { property } from 'lit/decorators/property.js';
import { i18nMixin } from '@kisters/wcp-base/decorators';
import { Mix } from '@kisters/wcp-base/common';
import dayjs from '@kisters/wcp-base/common/dayjsext';
import nls from '../locales/index';

@customElement('ww-lanuv-info')
export default class WwLanuvInfo extends Mix(LitElement, [i18nMixin, { nls }]) {
  // language=CSS
  static styles = css`
    :host {
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
    }

    .box {
      margin: 10pt;
      width: 45vw;
      max-width: 400px;
      float: left;
    }

    fieldset {
      min-height: 300px;
    }

    .kennzahlen-table {
      margin: 10px;
      width: calc(100% - 20px);
    }

    @media screen and (max-width: 600px) {
      :host {
        overflow: auto;
        display: block;
      }

      .box,
      .box-wide {
        width: 95vw;
        max-width: 950px;
        font-size: 0.9em;
        margin: 5px;
      }

      fieldset {
        height: auto;
        min-height: unset;
      }

      .kennzahlen-table {
        margin: 0px;
      }
    }

    h3 {
      color: #383838;
    }

    table,
    th,
    td {
      border-bottom: 1px solid #686868;
      border-collapse: collapse;
      color: #383838;
    }

    th {
      text-align: left;
      padding: 10px;
    }

    th.val {
      text-align: right;
      padding: 10px;
    }

    td {
      padding: 10px;
    }

    td:nth-child(2) {
      text-align: right;
    }

    a:link {
      text-decoration: none;
      color: rgba(0, 158, 224, 1);
    }

    a:visited {
      text-decoration: none;
      color: #005596;
    }

    a:hover {
      text-decoration: none;
      font-weight: bold;
      color: #005596;
    }

    legend {
      color: #686868;
    }

    fieldset {
      border: 1px #686868 solid;
    }

    a:active {
      text-decoration: none;
    }

    tr:last-child td {
      border-bottom-style: hidden;
    }
  `;

  static get properties() {
    return {
      content: { type: String },
    };
  }

  render() {
    return html`<div>
      <div class="box">
        <fieldset>
          <legend>Erläuterungen</legend>
          <div>
            <table class="kennzahlen-table">
              <tr>
                <td>
                  <a
                    href="https://www.lanuv.nrw.de/umwelt/wasser/hochwasserschutz/hochwasser-meldedienst-nrw/"
                    >Hochwassermeldedienst NRW</a
                  >
                </td>
              </tr>
              <tr>
                <td>
                  <a
                    href="https://www.lanuv.nrw.de/umwelt/wasser/wasserkreislauf/wasserstaende/"
                    >Erläuterungen zu Wasserständen</a
                  >
                </td>
              </tr>
              <tr>
                <td>
                  <a href="https://www.lanuv.nrw.de/umwelt/wasser/niederschlag/"
                    >Erläuterungen zu Niederschlägen</a
                  >
                </td>
              </tr>
              <tr>
                <td>
                  <a
                    href="https://www.lanuv.nrw.de/umwelt/wasser/gewaesserueberwachung/gewaessertemperatur"
                    >Erläuterungen zu Wassertemperatur</a
                  >
                </td>
              </tr>
            </table>
          </div>
        </fieldset>
      </div>

      <div class="box">
        <fieldset>
          <legend>Links</legend>
          <div>
            <table class="kennzahlen-table">
              <tr>
                <td>
                  <a href="https://www.hochwasserzentralen.de/"
                    >Hochwasserzentralen der Länder (LHP)</a
                  >
                </td>
              </tr>
              <tr>
                <td>
                  <a
                    href="https://www.wettergefahren.de/warnungen/warnsituation.html"
                    >Wetterwarnungen (DWD)</a
                  >
                </td>
              </tr>
              <tr>
                <td>
                  <a
                    href="https://www.wettergefahren.de/warnungen/warnsituation/warnlagebericht/nordrhein_westfalen.html"
                    >Meteorologischer Warnlagebericht NRW (DWD)</a
                  >
                </td>
              </tr>
              <tr>
                <td><a href="http://www.elwasweb.nrw.de/">ELWAS-Web NRW</a></td>
              </tr>
              <tr>
                <td>
                  <a href="https://www.flussgebiete.nrw.de/"
                    >Flussgebiete NRW</a
                  >
                </td>
              </tr>
            </table>
          </div>
        </fieldset>
      </div>
    </div> `;
  }
}

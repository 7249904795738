import { LitElement, css, html, PropertyValues } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import '@ui5/webcomponents/dist/List.js';
import '@ui5/webcomponents/dist/StandardListItem.js';
import '@ui5/webcomponents/dist/CustomListItem.js';
import '@ui5/webcomponents/dist/GroupHeaderListItem.js';
import '@ui5/webcomponents/dist/Input.js';
import '@ui5/webcomponents/dist/ComboBox';
import { Mix } from '@kisters/wcp-base/common';
import { i18nMixin } from '@kisters/wcp-base/decorators';
import type { Series, Station, Group } from '@kisters/wiski-web/types';
import nls from './locales/index';
import { IService } from '@kisters/wiski-web/services';
// import { Loader } from './util/decorators';

@customElement('ts-graph-list')
export class TsList extends Mix(LitElement, [i18nMixin, { nls }]) {
  @property({ type: Object })
  service: IService | null = null;

  @property({ type: Array, attribute: false })
  private tsList: Array<Series> = [];

  @property({ type: Array })
  private selectedIds: Array<String> = [];

  @property({ type: String })
  private searchTs: RegExp = new RegExp('');

  @property({ type: Array })
  private stations: Array<Station> = [];

  @property({ type: Array })
  private groups: Array<Group> = [];

  @property({ type: String })
  stationId: String = '';

  @property({ type: String })
  groupId: String = '';

  /** True: ts groups; False: station view  */
  @property({ type: Boolean })
  layers: Boolean = false;

  /** Hide Header */
  @property({ type: Boolean })
  noactions: Boolean = false;

  /** Show checkbox for (de)selection of all items */
  @property({ type: Boolean })
  showMultiSelect: Boolean = true;

  /** Show searchbox */
  @property({ type: Boolean })
  showSearchbox: Boolean = false;

  @property({ type: Boolean })
  preselectTimeseries: Boolean = false;

  private selectedSeriesIds: Set<String> = new Set();

  @query('#searchbox')
  searchbox!: HTMLInputElement;

  // ${this.layers ? this.createGroupSelect() : this.createStationSelect()}
  render() {
    return html`
      <header
        style="display:${this.noactions
          ? 'none'
          : 'inital'}; border-bottom: 2px solid #ccc"
      >
        ${this.showMultiSelect ? this._renderMultiSelect() : ``}
        ${this.showSearchbox ? this._renderSearchbox() : ``}
      </header>
      ${this.createSeriesList()}
    `;
  }

  _renderMultiSelect() {
    return html` <ui5-checkbox
      indeterminate
      id="multiSelectCheckbox"
      text="${this.i18n.t('selectDeselectAll')}"
      @change="${this._updateMultiSelect}"
      style=""
    ></ui5-checkbox>`;
  }

  /**
   * Update "(De)Select all timeseries" checkbox
   */
  _updateMultiSelectIndeterminate() {
    const multiSelectionBox = this.shadowRoot.querySelector(
      '#multiSelectCheckbox',
    );
    // Query all checkboxes
    const allBoxes = Array.from(
      this.shadowRoot.querySelectorAll('[id^=listitem]'),
    );

    // Count the number of selected and unselected boxes and determine the state of the "selectAll"-Button
    const countSel = allBoxes.filter(b => b.selected).length;
    const countNotSel = allBoxes.filter(b => !b.selected).length;
    if (countSel === 0 && countNotSel === 0) multiSelectionBox.disabled = true;
    else if (countSel === 0 && countNotSel > 0) {
      multiSelectionBox.checked = false;
      multiSelectionBox.indeterminate = false;
    } else if (countSel > 0 && countNotSel === 0) {
      multiSelectionBox.checked = true;
      multiSelectionBox.indeterminate = false;
    } else {
      multiSelectionBox.checked = true;
      multiSelectionBox.indeterminate = true;
    }
  }

  _updateMultiSelect(e: CustomEvent) {
    const multiSelectionBox = this.shadowRoot.querySelector(
      '#multiSelectCheckbox',
    );
    // Query/Get all checkboxes from DOM
    const allBoxes = Array.from(
      this.shadowRoot.querySelectorAll('[id^=listitem]'),
    );
    // Set new status on all boxes - set all boxes as (un)checked
    allBoxes.forEach(b => {
      const select = multiSelectionBox.checked;
      b.selected = select;
      select
        ? this.selectedSeriesIds.add(b.tsId)
        : this.selectedSeriesIds.delete(b.tsId);
    });

    // If checked, load/select all timeseries
    this._dispatchElementSelected(this.tsList, multiSelectionBox.checked);
  }

  _renderSearchbox() {
    return html` <ui5-input
      id="searchbox"
      show-clear-icon
      placeholder="${this.i18n.t('searchTimeseries')}"
      @input="${() => {
        this.searchTs = new RegExp(this.searchbox.value, 'i');
      }}"
    ></ui5-input>`;
  }

  /**
   * Add/Remove multiple timeseries
   * @remark Emit event to update timeseries in graph and graph legend
   * @param tsList List opf timeseries objects
   * @param select Remove timeseries if deselected; Add to graph if selected
   */
  _dispatchElementSelected(tsList: Array<Series>, select: boolean) {
    const _select = select ?? true;
    this.dispatchEvent(
      new CustomEvent('selected', {
        detail: {
          series: tsList,
          remove: !_select,
        },
      }),
    );
  }

  /**
   *  Add/Remove single timeseries
   * @param tsId Timeseries ID to add or remove from graph and graph legend
   * @param select Remove timeseries if deselected; Add to graph if selected
   */
  _dispatchElementSelectedById(tsId: string, select: boolean) {
    this._dispatchElementSelected(
      [this.tsList.find(s => s.id === tsId)],
      select,
    );
  }

  /**
   * Internal handling of list state
   * @param e Event
   */
  _changeSelection(e: CustomEvent) {
    const tsId = e.detail.targetItem.getAttribute('tsId');

    if (e.detail.targetItem.selected) {
      this.selectedSeriesIds.add(tsId);
    } else {
      this.selectedSeriesIds.delete(tsId);
    }
    this._dispatchElementSelectedById(tsId, e.detail.targetItem.selected);
    this._updateMultiSelectIndeterminate();
  }

  private createSeriesList() {
    return html`<ui5-list
      noDataText="${this.i18n.t('noTimeseriesSelected')}"
      mode="MultiSelect"
      @selection-change="${(e: CustomEvent) => {
        this._changeSelection(e);
      }}"
    >
      ${this.getFilteredItems()}
    </ui5-list>`;
  }

  /** Sorting routine fot tsList-items */
  private getFilteredItems() {
    function tsSort(a: Series, b: Series) {
      if (a.parameter.name < b.parameter.name) {
        return -1;
      }
      if (a.parameter.name > b.parameter.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    }

    return this.layers
      ? html`${this.tsList
          .filter(
            series =>
              series.name.match(this.searchTs) ||
              series.parameter.name.match(this.searchTs) ||
              series.station.name.match(this.searchTs),
          )
          .sort(tsSort)
          .map(
            series =>
              html`<ui5-li-custom
                style="display: flex; justify-content: space-between;
    align-items: center; border-bottom: 1px solid #bbb; height: 70px; border-left: 8px solid ${series
                  ?.meta?.style?.color && !this.useDefaultColors
                  ? series?.meta?.style?.color
                  : `rgba(0,0,0,0)`}"
                ?selected="${this.selectedSeriesIds.has(series.id)}"
                .tsId="${series.id}"
                tsId="${series.id}"
                id="listitem${series.id}"
                ><div
                  style="display: flex; width: 100%; height: 100%; justify-content: space-between;
    align-items: center;"
                >
                  <div style="display: flex; flex-direction: column">
                    <ui5-label>${series.station.name}</ui5-label>
                    <ui5-title level="H6">${series.name}</ui5-title>
                    <ui5-label
                      >${series.parameter.name}
                      [${series.parameter.unit}]</ui5-label
                    >
                  </div>
                  <div style="display: flex; align-items: center; gap: 2px">
                    ${series.meta?.tsInfo?.status &&
                    (series.meta?.tsInfo?.status !== 'OK' ||
                      series.meta?.tsInfo?.remark)
                      ? html`<ui5-button
                            @click=${() => this._openListPopover(series.id)}
                            icon="alert"
                            design="Negative"
                            id="button${series.id}"
                            tooltip="${this.i18n.t(
                              series.meta?.tsInfo?.status,
                            )}"
                          ></ui5-button>

                          <ui5-popover
                            id="popover${series.id}"
                            header-text="${this.i18n.t(
                              series.meta?.tsInfo?.status,
                            )}"
                            style="max-width: 300px; min-width: 200px"
                          >
                            <div
                              slot="header"
                              style="padding: 3px; width: 100%; display: flex; justify-content: space-between; align-items: center;"
                            >
                              <b>${this.i18n.t(series.meta?.tsInfo?.status)}</b>
                              <ui5-button
                                @click=${() =>
                                  this._closeListPopover(series.id)}
                                icon="decline"
                                design="Default"
                                id="closebutton${series.id}"
                              ></ui5-button>
                            </div>
                            <div class="popover-content">
                              ${series.meta?.tsInfo?.remark
                                ? series.meta?.tsInfo?.remark
                                : this.i18n.t('noRemark')}
                            </div>
                          </ui5-popover>`
                      : ``}

                    <ui5-button
                      @click=${() => this._removeTimeseries(series.id)}
                      icon="decline"
                      design=""
                      id="removebutton${series.id}"
                      tooltip="${this.i18n.t('removeTimeseries')}"
                    ></ui5-button>
                  </div>
                </div>
              </ui5-li-custom>`,
          )}`
      : html`${this.tsList
          .filter(
            series =>
              series.name.match(this.searchTs) ||
              series.shortName.match(this.searchTs),
          )
          .map(
            series =>
              html`<ui5-li
                ?selected="${this.selectedSeriesIds.has(series.id)}"
                tsId="${series.id}"
                description="${series.shortName}"
                additional-text="${series.parameter.name}"
                >${series.name}</ui5-li
              >`,
          )}`;
  }

  _openListPopover(id) {
    const btn = this.shadowRoot.querySelector(`#button${id}`);
    this.shadowRoot.querySelector(`#popover${id}`).showAt(btn);
  }

  _closeListPopover(id) {
    this.shadowRoot.querySelector(`#popover${id}`).close();
  }

  _removeTimeseries(id) {
    this.dispatchEvent(new CustomEvent('removeTs', { detail: id }));
  }

  protected firstUpdated() {
    this.updateList();
  }

  updateList() {
    this.selectedSeriesIds = new Set(this.selectedIds);
    this._dispatchElementSelected(
      this.tsList.filter((series: Series) =>
        this.selectedSeriesIds.has(series.id),
      ),
    );
    this.requestUpdate();
  }

  private async loadStations() {
    if (!this.service) {
      console.warn('Service not initialized');
      return;
    }
    this.stations = (await this.service.stations())
      .sort((a: Station, b: Station) => (a.name < b.name ? 1 : -1))
      .sort((a: Station, b: Station) => (a.site.name > b.site.name ? 1 : -1));
    this.stationId = this.stationId || this.stations[0]?.id;
  }

  private async loadGroups() {
    if (!this.service) {
      console.warn('Service not initialized');
      return;
    }
    this.groups = (await this.service.groups()).sort((a: Group, b: Group) =>
      a.name < b.name ? 1 : -1,
    );
    this.groupId = this.groupId || this.groups[0]?.id;
  }

  private async getTsList(filter: object) {
    this.tsList = (await this.service!.timeseries(filter))
      .sort((a: Series, b: Series) => (a.name < b.name ? 1 : -1))
      .sort((a: Series, b: Series) =>
        a.parameter.name > b.parameter.name ? 1 : -1,
      )
      .sort((a: Series, b: Series) =>
        a.station.name > b.station.name ? 1 : -1,
      );
  }

  updated(changed: PropertyValues<this>) {
    if (changed.has('stationId') && this.stationId && this.noactions) {
      this.getTsList({ station_id: this.stationId });
    }

    if (changed.has('groupId') && this.groupId && this.noactions) {
      this.getTsList({ timeseriesgroup_id: this.groupId });
    }

    if (
      (changed.has('noactions') || changed.has('layers')) &&
      !this.noactions
    ) {
      this.layers ? this.loadGroups() : this.loadStations();
    }

    if (changed.has('selectedIds')) {
      this.selectedSeriesIds = new Set(this.selectedIds);
      this.tsList.forEach(ts => {
        this._dispatchElementSelectedById(
          ts.id,
          this.selectedIds.indexOf(ts.id) >= 0,
        );
      });
      this._updateMultiSelectIndeterminate();
      this.requestUpdate();
    }
  }

  static styles = css`
    :host {
      display: flex;
      flex-direction: column;
      position: relative;
      border-left: 1px solid #ccc;
      overflow-y: auto;
      min-width: 300px;
    }

    header {
      display: flex;
      justify-content: space-between;
    }

    ui5-label {
      font-size: small;
    }
  `;
}

declare global {
  interface HTMLElementTagNameMap {
    'ts-graph-list': TsList;
  }
}

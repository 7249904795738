import { css } from 'lit';

// language=CSS
export default css`
  :host {
    display: flex;
    flex-direction: column;
    overflow: auto;
    margin: 10px;
    margin-left: 25px;
    font-family: Calibri, Candara, Segoe, Segoe UI, Optima, Arial, sans-serif;
    font-size: 18px;
  }

  .header .row .cell {
    vertical-align: bottom;
  }

  .preserve-line-breaks {
    white-space: pre-line;
  }

  .table-wrapper {
    margin: 15px;
  }

  h2 {
    color: #282828;
    font-size: 1.15em;
  }

  h4 {
    font-size: 0.9em;
    margin-left: 10px;
  }

  h4 .error {
    color: #ff0000;
  }

  .filename {
    word-break: break-all;
  }

  .table-container {
    position: relative;
    overflow: auto;
  }

  .table {
    border-collapse: collapse;
    border-spacing: 0;
    display: table;
  }

  .header {
    display: table-header-group;
    text-align: left;
  }

  .body {
    display: table-row-group;
  }

  .row {
    display: table-row;
    border-width: 1px 0;
    border-color: #ececec;
    border-style: solid;
  }

  .hrefrow {
    cursor: pointer;
  }

  .berichtrow:hover {
    color: rgba(0, 10, 0, 1);
  }

  .row:hover {
    background: lightgray;
  }

  .cell.number {
    text-align: right;
  }

  .cell {
    padding: 5px 10px;
    display: table-cell;
  }

  .header .row {
    border-top-width: 0;
    background-color: white;
  }

  .header .cell {
    position: sticky;
    top: 0;
    background: inherit;
    z-index: 2;
    padding: 0;
  }

  .header-content {
    padding: 10px;
    border-bottom: 1px solid darkgray;
    white-space: nowrap;
  }

  .header-content.sortable {
    cursor: pointer;
  }

  .header-content.sortable ki-icon {
    visibility: hidden;
  }

  .header-content.sortable:hover ki-icon {
    visibility: visible;
  }

  .file {
    background: none !important;
    border: none;
    /*input has OS specific font-family*/
    cursor: pointer;
    font-size: 18px;
    font-weight: 900;
  }

  .nofile {
    cursor: auto;
  }

  .file:hover {
    color: rgb(0, 50, 100);
  }

  legend {
    color: #686868;
  }

  fieldset {
    border: 1px #686868 solid;
  }

  .stationname {
    font-weight: bold;
  }

  a {
    color: rgba(0, 158, 224, 1);
  }

  a:link {
    text-decoration: none;
    color: rgba(0, 158, 224, 1);
  }

  a:visited {
    text-decoration: none;
    color: #005596;
  }

  a:hover {
    text-decoration: none;
    font-weight: bold;
    color: #005596;
  }

  .maplink {
    width: 100%;
    float: right;
  }

  .breaklong {
    min-width: 200px;
    word-break: break-word;
  }

  .breaklong:nth-child(n + 2) {
    margin-top: 40px;
  }

  .dl-icon {
    box-shadow: none;
    display: inline;
    margin-left: 10px;
  }

  .left {
    margin-bottom: -4px;
    float: left;
    width: 50%;
    text-align: left;
  }

  .right {
    margin-bottom: -4px;
    float: right;
    width: 50%;
    text-align: right;
  }

  .multi-tsvalue {
    width: 300px;
    display: table-cell;
    padding: 5px 10px;
  }

  .iconcontainer {
    display: grid;
    place-items: center;
    grid-template-areas: 'inners';
  }

  .inner {
    grid-area: inners;
  }

  .cover {
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    overflow: hidden;
    position: fixed;
    opacity: 0.5;
    background-color: #eeeeee;
    z-index: 1000;
  }

  .centered {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
  }

  .loader {
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #3498db;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
    z-index: 1001;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @media only screen and (max-width: 600px) {
    :host {
      margin-left: 5px;
    }

    .table-wrapper {
      margin: 0px;
    }

    .hideable-s {
      display: none;
    }

    h2 {
      font-size: 16px;
    }

    h4 {
      font-size: 12px;
    }

    .cell {
      font-size: 12px;
      max-width: 150px;
      vertical-align: top;
      padding: 5px 5px;
    }

    .header-content {
      padding: 5px;
      white-space: normal;
      vertical-align: bottom;
    }

    .multi-tsvalue {
      width: 150px;
      display: table-cell;
    }

    .left {
      display: none;
      margin-bottom: 0px;
    }

    .right {
      width: 100%;
      margin-bottom: 0px;
    }
  }

  @media screen and (max-width: 450px) and (orientation: portrait) {
    .hideable-xs {
      display: none;
    }

    h2 {
      margin: 0px;
    }

    .table-container {
      margin: 0px;
    }
  }

  @media screen and (max-width: 320px) and (orientation: portrait) {
    .break-xs {
      word-break: break-all;
      min-width: 60px;
    }
  }

  @media screen and (max-width: 1250px) and (min-width: 601px) {
    :host {
      margin-left: 5px;
    }
    h2 {
      font-size: 16px;
    }

    h4 {
      font-size: 12px;
    }

    .cell {
      font-size: 12px;
      max-width: 200px;
    }

    .header-content {
      padding: 5px;
      white-space: normal;
      vertical-align: bottom;
    }

    .multi-tsvalue {
      width: 200px;
      display: table-cell;
      padding: 5px 10px;
      vertical-align: bottom;
    }

    .body .row .cell {
      vertical-align: top;
    }

    .left {
      width: 75%;
    }

    .right {
      width: 25%;
    }
  }
`;

import { Meta, MetaMapping } from '../defs/GraphTypes';
import { WiskiTs } from '../defs/WiskiTypes';

export class WiskiMetaHelper {
  /**
   * Build new MetaObject from MetaMapping
   * Evaluates properties of object
   */
  public static resolveMeta(
    ts: WiskiTs,
    metaMapping: MetaMapping = {},
  ): Meta | undefined {
    const result = {} as Meta;

    Object.keys(metaMapping).forEach(prop => {
      // Resolve property
      if (typeof metaMapping[prop] === 'string')
        result[prop] = ts[metaMapping[prop]];
      // Go deeper
      else if (typeof metaMapping[prop] === 'object')
        result[prop] = this.resolveMeta(ts, metaMapping[prop]);
      else
        console.warn(
          'Something went wrong; Mappings should only contains strings as objects!',
          metaMapping,
          prop,
          typeof metaMapping[prop],
        );
    });
    return result;
  }
}

import { MetaMapping, Series } from '../defs/GraphTypes';
import { WiskiMetaHelper } from '../util/WiskiMetaHelper';
import { WiskiTs } from '../defs/WiskiTypes';

/**
 * Helper functions to transform Wiski/Kiwis .json-style data into generic structure "Series"
 * Filter external stations
 */
export class WiskiTsTransformation {
  public static normalizeTsArray(
    input: Array<WiskiTs>,
    metaMapping: MetaMapping = {},
  ): Array<Series> {
    return input
      .filter(i => i.external === undefined || !i.external)
      .map(ts => this.normalizeTs(ts, metaMapping));
  }

  /** Workaround for LANUV and legacy data structure */
  public static normalizeMultiTsArray(
    input: Array<WiskiTs>,
    metaMapping: MetaMapping = {},
  ): Array<Series> {
    // For each timeseries extract all ts_values objects
    const res = input.map(wiskiTs => {
      const subRes = Object.keys(wiskiTs.ts_values).map(para => {
        // Detect if there is only a single timeseries in the parameter // TODO: Get rid of this shitty data structure asap
        if (Object.keys(wiskiTs.ts_values[para]).includes('ts_id')) {
          const i = {
            ...wiskiTs.ts_values[para],
            station_id: wiskiTs.station_id,
            station_name: wiskiTs.station_name,
            station_no: wiskiTs.station_no,
            selected: wiskiTs.ts_id === wiskiTs.ts_values[para].ts_id, // Only select one timeseries of each station
          };
          return this.normalizeTs(i, metaMapping);
        }
        return Object.keys(wiskiTs.ts_values[para]).map(ts => {
          // Transform object to include all necessary meta information from parent
          const i = {
            ...wiskiTs.ts_values[para][ts],
            station_id: wiskiTs.station_id,
            station_name: wiskiTs.station_name,
            station_no: wiskiTs.station_no,
            selected: wiskiTs.ts_id === wiskiTs.ts_values[para][ts].ts_id, // Only select one timeseries of each station
          };
          return this.normalizeTs(i, metaMapping);
        });
      });
      return subRes.flat();
    });
    return res.flat();
  }

  /** Convert WISKI like structure */
  public static normalizeTs(
    ts: WiskiTs,
    metaMapping: MetaMapping = {},
  ): Series {
    const res = {
      id: String(ts.ts_id),
      name: ts.ts_name,
      shortName: ts.ts_shortname,
      parameter: {
        name: ts.stationparameter_longname ?? ts.stationparameter_name,
        shortName: ts.stationparameter_no,
        id: ts.stationparameter_id || '',
        unit: ts.ts_unitsymbol === 'null' ? '---' : ts.ts_unitsymbol, // Workaround for KIWIS Bug () - returns null as a string and not as null
      },
      station: {
        name: ts.station_name,
        shortName: ts.station_no,
        id: ts.station_id,
        site: { name: ts.site_no, id: ts.site_id },
      },
      from: undefined,
      until: undefined,
      data: undefined,
      meta: WiskiMetaHelper.resolveMeta(ts, metaMapping) || {},
    } as Series;
    return res;
  }
}

/* eslint-disable func-names */
/* eslint-disable array-callback-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */
import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
// eslint-disable-next-line import/extensions
import { property } from 'lit/decorators/property.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { map } from 'lit/directives/map.js';
import { i18nMixin, responsiveMixin } from '@kisters/wcp-base/decorators';
import { orderBy } from 'lodash-es';
import { getCurrentApi } from '@kisters/wiski-web/api';
import { Mix } from '@kisters/wcp-base/common';
import {
  navigateTo,
  getRouteOptionsAndParams,
} from '@kisters/wcp-base/components';
import dayjs from '@kisters/wcp-base/common/dayjsext';
import nls from '../locales/index';

@customElement('ww-lanuv-start')
export default class WwpLanuvStart extends Mix(LitElement, responsiveMixin, [
  i18nMixin,
  { nls },
]) {
  // language=CSS
  static styles = css`
    :host {
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      overflow: auto;
      flex-direction: row;
    }
    .container {
      display: flex;
      flex: 1 1 0%;
      padding-right: 5px;
      align-items: flex-start;
      flex-direction: row;
    }

    .alarm-container {
      display: flex;
      flex-flow: column nowrap;
      place-content: center space-between;
    }

    .box {
      margin: 10pt;
      flex: 1;
    }

    .box fieldset {
      height: 320px;
    }

    .kennzahlen-table {
      margin: 8px;
      width: 97%;
    }

    h4 {
      color: #383838;
      margin: 5px;
    }

    table,
    th,
    td {
      border-bottom: 1px solid #686868;
      border-collapse: collapse;
      color: #383838;
    }

    th {
      text-align: left;
      padding: 10px;
    }

    th.val {
      text-align: right;
      padding: 10px;
    }

    td {
      padding: 6px;
    }

    td:nth-child(2) {
      text-align: right;
    }

    a:link {
      text-decoration: none;
      color: rgba(0, 158, 224, 1);
    }

    a:visited {
      text-decoration: none;
      color: #005596;
    }

    a:hover {
      text-decoration: none;
      font-weight: bold;
      color: #005596;
    }

    legend {
      color: #686868;
    }

    .contentbox {
      margin: 10pt;
      flex: 2;
      min-height: 537px;
      padding-bottom: 20pt;
    }

    .contentbox .content {
      min-height: 285px;
    }

    fieldset {
      border: 1px #686868 solid;
    }

    a:active {
      text-decoration: none;
    }

    .maplink {
      width: 100%;
      float: right;
    }

    .box {
      overflow: hidden;
    }

    #content-static {
      width: 100%;
    }

    img {
      max-width: 100%;
    }

    @media screen and (max-width: 875px) {
      :host {
        overflow: auto;
        display: block;
      }

      .container {
        display: unset;
        flex: 1;
      }

      .box,
      .contentbox {
        font-size: 0.9em;
        margin: 5px;
        max-width: unset;
        min-height: unset;
        padding-bottom: 0pt;
      }

      .alarm-container {
        flex-flow: column nowrap;
      }

      .contentbox fieldset {
        margin-bottom: 15px;
      }

      .kennzahlen-table {
        margin: 0px;
      }

      td {
        padding: 10px;
      }
    }
  `;

  api = getCurrentApi();

  wasserstand = {
    IW1: [],
    IW2: [],
    IW3: [],
    Veraltet: [],
    Inaktiv: [],
    PegelOK: [],
    HN7W: [],
  };

  niederschlag = [];

  popup = 'ww-map-popup-lanuv';

  @property({ type: String }) htmlPath = '';

  @property({ type: String, attribute: true }) embeddedHtml = '';

  onAfterEnter(location) {
    this.htmlPath =
      getRouteOptionsAndParams(location, ['htmlPath']).options?.htmlPath || '';

    this.popup =
      getRouteOptionsAndParams(location, ['popup']).options?.popup || '';

    this.api = getCurrentApi();
    this.fetchData();
  }

  _renderInfobox() {
    return html` <div class="contentbox">
      <fieldset>
        <legend>Weitere Informationen</legend>
        <div class="content">${this.embeddedHtml}</div>
      </fieldset>
    </div>`;
  }

  _renderAlarmBoxes() {
    const selection = this.niederschlag.slice(0, 5);

    return html`
      <div class="alarm-container">
        <div class="box">
          <fieldset>
            <legend>Wasserstand</legend>
            <div>
              <div class="header-table">
                <h4>Aktuelle Lage</h4>
              </div>
              <table class="kennzahlen-table">
                <tr>
                  <th>Hochwassermeldepegel</th>
                  <th class="val">Anzahl</th>
                </tr>
                <tr title=${this.getStationNames(this.wasserstand.IW3)}>
                  <td>Pegel über Informationswert 3</td>
                  <td>${this.wasserstand.IW3.length}</td>
                </tr>
                <tr title=${this.getStationNames(this.wasserstand.IW2)}>
                  <td>Pegel über Informationswert 2</td>
                  <td>${this.wasserstand.IW2.length}</td>
                </tr>
                <tr title=${this.getStationNames(this.wasserstand.IW1)}>
                  <td>Pegel über Informationswert 1</td>
                  <td>${this.wasserstand.IW1.length}</td>
                </tr>
                <tr title=${this.getStationNames(this.wasserstand.Inaktiv)}>
                  <td>zurzeit inaktive <b>Meldepegel</b></td>
                  <td>${this.wasserstand.Inaktiv.length}</td>
                </tr>
                <tr title=${this.getStationNames(this.wasserstand.Veraltet)}>
                  <td><b>Meldepegel</b> mit nicht aktuellen Werten</td>
                  <td>${this.wasserstand.Veraltet.length}</td>
                </tr>
              </table>
            </div>
            <p class="maplink">
              <a
                href="#/overview/Wasserstand"
                @click="${() => navigateTo(`#/overview/Wasserstand`)}"
                >zur Karte</a
              >
            </p>
          </fieldset>
        </div>

        <div class="box">
          <fieldset>
            <legend>Niederschläge</legend>
            <div>
              <div class="header-table">
                <h4>Höchste 24h-Niederschlagssummen (aktive Stationen)</h4>
              </div>
              <table class="kennzahlen-table">
                <tr>
                  <th>Station</th>
                  <th class="val">Messwert (mm/24h)</th>
                </tr>
                ${map(
                  selection,
                  s => html` <tr
                    @mouseenter="${e => {
                      this.showPopup(s, e);
                    }}"
                    @mouseleave="${this.hidePopup}"
                  >
                    <td>
                      <span title="${s.station_name}"
                        ><a
                          href="#/overview/Niederschlag/station/${s.station_id}/${s.station_name}?selected=Niederschlag"
                          @click="${() =>
                            navigateTo(
                              `#/overview/Niederschlag/station/${s.station_id}/${s.station_name}?selected=Niederschlag`,
                            )}"
                          >${s.station_name.length > 21
                            ? s.station_name.replace(/(.{18})..+/, '$1…')
                            : s.station_name}</a
                        ></span
                      >
                    </td>
                    <td
                      title="Letzte Messung ${dayjs(s.timestamp).format(
                        'L LT',
                      )}"
                    >
                      ${this.numberFormatter.format(s.ts_value)}
                    </td>
                  </tr>`,
                )}
              </table>
            </div>
            <p class="maplink">
              <a
                href="#/overview/Niederschlag"
                @click="${() => navigateTo(`#/overview/Niederschlag`)}"
                >zur Karte</a
              >
            </p>
          </fieldset>
        </div>
      </div>
    `;
  }

  render() {
    // Customer wants different box order for different screen sizes (LANUVTEMP-56)
    return html`<div class="container">
      ${this._renderAlarmBoxes()} ${this._renderInfobox()}
    </div>`;
  }

  async fetchData() {
    this.numberFormatter = new Intl.NumberFormat(this.i18n.language || 'de', {
      minimumFractionDigits: 1,
    });
    const yesterday = new Date().getTime() - 24 * 60 * 60 * 1000;
    const _wasserstand = await this.api.getLayer('Wasserstand');

    /* Requirement by customer (SDWTR-6481/LANUV-423):
     * Only show "Meldepegel" which is identical to "Infopegel"
     * Ignore "Gewässerkundliche Pegel"
     * 'Weiter Betreiber Infostufen' means "External stations with thresholds/warning levels"
     */
    this.wasserstand_data = _wasserstand.data.filter(
      item =>
        item.WEB_STATYPE === 'Infopegel' ||
        item.WEB_STATYPE === 'Weiter Betreiber Infostufen',
      // || item.WEB_STATYPE === 'Gewässerkundlicher Pegel',
    );
    const _niederschlag = await this.api.getLayer('Niederschlag');
    this.niederschlag_data = _niederschlag.data.filter(
      ts =>
        ts.timestamp &&
        new Date(ts.timestamp).getTime() > yesterday &&
        ts.INTERNET_AKTIV !== 'no',
    );

    this.wasserstand_data.map(e => {
      if (e.INTERNET_AKTIV === 'no') this.wasserstand.Inaktiv.push(e);
      else if (new Date(e.timestamp).getTime() < yesterday) {
        this.wasserstand.Veraltet.push(e);
      } else
        switch (e.classification) {
          case null:
            this.wasserstand.PegelOK.push(e);
            break;
          case 'Info 1':
            this.wasserstand.IW1.push(e);
            break;
          case 'Info 2':
            this.wasserstand.IW2.push(e);
            break;
          case 'Info 3':
            this.wasserstand.IW3.push(e);
            break;
          case 'NORMAL':
            this.wasserstand.PegelOK.push(e);
            break;
          case 'N7W':
            this.wasserstand.PegelOK.push(e);
            break;
          case 'MN7W':
            this.wasserstand.PegelOK.push(e);
            break;
          case 'IVorW':
            this.wasserstand.PegelOK.push(e);
            break;
          case 'VorW':
            this.wasserstand.PegelOK.push(e);
            break;
          case 'HN7W':
            this.wasserstand.HN7W.push(e);
            break;
          default:
            console.log('Unhandled classification', e.classification);
        }
    });

    this.niederschlag = orderBy(
      this.niederschlag_data,
      [
        function (o) {
          return !!o.ts_value && parseFloat(o.ts_value);
        },
      ],
      ['desc'],
    );

    await this._fetchHtml();
  }

  async _fetchHtml() {
    if (this.htmlPath && this.htmlPath !== '') {
      const _html = await this.api.requestText(`${this.htmlPath}`, '');
      this.embeddedHtml = unsafeHTML(_html);
    } else {
      this.embeddedHtml = unsafeHTML('<b>HTML konnte nicht geladen werden</b>');
    }
  }

  getStationNames(stationList) {
    let result = '';
    stationList.map(s => {
      result += `${s.station_name}, `;
    });
    // Remove trailing comma
    result = result.substring(0, result.length - 2);
    return result;
  }

  showPopup(station, e) {
    const pelement = this.popupElement;
    pelement.style.display = 'block';
    const maxTop = window.document.body.clientHeight - pelement.clientHeight;
    const maxLeft = window.document.body.clientWidth - pelement.clientWidth;
    const posY =
      e.clientY > maxTop ? e.clientY - pelement.clientHeight : e.clientY;
    const posX =
      e.clientX > maxLeft
        ? e.clientX - pelement.clientWidth - 30
        : e.clientX + 30;
    pelement.stations = [station];
    pelement.style.left = `${posX}px`;
    pelement.style.top = `${posY}px`;
  }

  hidePopup() {
    this.popupElement.style.display = 'none';
  }

  get popupElement() {
    if (!this._popup) {
      this._popup = this.popup;
      this._popupElement && this.popupElement.remove();
      this._popupElement = document.createElement(this._popup);
      this.renderRoot.appendChild(this._popupElement);
      this._popupElement.style.position = 'fixed';
      this._popupElement.style.background = 'white';
      this._popupElement.style.border = '1px solid #ddd';
    }

    return this._popupElement;
  }
}

/* eslint-disable camelcase */
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { Mix, LoaderMixin } from '@kisters/wcp-base/common';
import { responsiveMixin, i18nMixin } from '@kisters/wcp-base/decorators';
import { getConfig } from '@kisters/wcp-base/app';
import { getCurrentApi } from '@kisters/wiski-web/api';
import '@ui5/webcomponents/dist/Input.js';
import '@ui5/webcomponents/dist/features/InputSuggestions.js';
import '@ui5/webcomponents/dist/ComboBox.js';
import '@ui5/webcomponents/dist/Popover.js';
import '@ui5/webcomponents-icons/dist/AllIcons.js';
import { KIWIS } from '@kisters/wcp-water/kiwis';
import { orderBy, remove } from 'lodash-es';
import { TimeseriesSelectionItem } from './TimeseriesSelectionItem';
import { StationSelectionItem } from './StationSelectionItem';
import style from './ki-timeseries-explorer.css';
import nls from './../../locales/index';
import { DynamicSelectionUtil } from './dynamic-selection-helper';
/**
 * Timeseries Explorer to find all available timeseries
 */
@customElement('ki-timeseries-explorer')
export default class KiTimeseriesExplorer extends Mix(
  LitElement,
  LoaderMixin,
  responsiveMixin,
  [i18nMixin, { nls }],
) {
  static styles = [style];

  api = getCurrentApi();

  @property({ type: Boolean })
  filterOtherParameters: boolean = false;

  /** Selected station represented in the upper selection box */
  @property({ type: Object })
  selectedStation: StationSelectionItem = new StationSelectionItem();

  @property({ type: Array })
  availableStations: Array<StationSelectionItem> = [];

  /** Timeseries which are available at the station, but are not filtered and not selected/staged
   * @remarks Those timeseries will be visualized in the searchfield by suggestion-items when clicking on the searchfield without input
   */
  @property({ type: Array })
  availableTimeseries: Array<TimeseriesSelectionItem> = [];

  /** Timeseries which are available at the station and meeting the search criteria, but are not selected/staged
   * @remarks Those timeseries will be visualized in the searchfield by suggestion-items
   */
  @property({ type: Array })
  filteredTimeseries: Array<TimeseriesSelectionItem> = [];

  /** Timeseries which have been selected, but are not added to the graph
   * @remarks Those timeseries will be visualized in the popover as list-items and can be deleted/unstaged
   */
  @property({ type: Array })
  stagedTimeseries: Array<TimeseriesSelectionItem> = [];

  /** Custom filter is used for reducing the timeseries neduced by KIWIS
   * @remark If not defined, the received tsList will be returned without modifications
   * @example Simple filter based on property
   * ```
   * function (list) {
              return list.filter(ts =>
                ['LVN', 'LVN-Web', 'LVN-Web-App', 'LVN-Web-LHP-App'].includes(
                  ts.LANUV_PARA_PUBLIC,
                ),
              );

   * ```
   */
  @property({ type: Function, attribute: false })
  customFilter = function (list) {
    return list;
  };

  async connectedCallback() {
    if (super.connectedCallback) super.connectedCallback();
    this.kiwis = new KIWIS(getConfig().kiwis);
    Object.assign(this, this.options);
    await this._fetchData();
  }

  getPopover() {
    return this.shadowRoot.querySelector('#ts-selection-dynamic-popover');
  }

  async _fetchData() {
    const stations = await this.api.getStations();
    /** Transform API Response to Combobox-Items */
    this.availableStations = orderBy(
      stations.map(({ station_name, station_no, station_id }) => ({
        text: station_name,
        additionalText: station_no,
        station_id,
        station_no,
        station_name,
      })),
      'station_name',
    );
  }

  async _fetchStationTimeseriesList() {
    let tsList = await this.kiwis.getTimeseriesList(
      {
        metadata: true,
        station_id: this.selectedStation?.station_id,
      },
      [
        'ts_id',
        'ts_name',
        'ts_shortname',
        'coverage',
        'ts_path',
        'ca_ts',
        'stationparameter_name',
        'stationparameter_longname',
        'stationparameter_no',
        'ts_unitsymbol',
        'ca_par', // Custom attribute
        'ts_clientvalue1',
        'station_id',
        'station_name',
        'station_no',
        'site_id',
        'site_no',
      ],
    );

    tsList = tsList.filter(ts => ts.to !== '');
    tsList = this.customFilter(tsList);

    /** Add UI relevant data */
    /** TODO: Set thresholdPattern via external input, e.g. in graph or by config file
     * Current implementation is specified for LANUV
     */

    this.availableTimeseries = tsList.map(ts => ({
      ...ts,
      text: ts.ts_name,
      additionalText: `${ts.stationparameter_name} (${ts.stationparameter_no}) [${ts.ts_unitsymbol}]`,
      description: `${ts.ts_shortname}`,
      isDistinct: DynamicSelectionUtil.getIsDistinct(ts.ts_path),
      isThreshold: false, // TODO: Meta injection?
    }));
  }

  firstUpdated() {
    if (!this.selectedStation.isEmpty)
      this._handleStationSelect({ detail: { item: this.selectedStation } });
  }

  /**
   * @param text StationName
   * @param additionalText StationNo (not station_id!)
   * @param station_id station_id (for searching)
   * @returns ComboBox-Items to select station
   */
  _renderStationSelectionItems() {
    return this.availableStations.map(
      i => html`<ui5-cb-item
        .text="${i.text}"
        .additionalText="${i.additionalText}"
        .station_id="${i.station_id}"
      ></ui5-cb-item> `,
    );
  }

  /**
   * Triggers after each new char
   * @remaks All properties get inspected during search by input value
   * @returns Filtered Suggestion-Items to select timeseries
   */
  _renderSuggestionItems() {
    return this.filteredTimeseries.map(
      item => html`<ui5-suggestion-item
        .text="${item.text}"
        .additionalText="${item.additionalText}"
        .additionalTextState="${DynamicSelectionUtil.getValueState(item)}"
        .description="${item.description}"
        .icon="${DynamicSelectionUtil.getSuggestionItemIcon(item)}"
      ></ui5-suggestion-item>`,
    );
  }

  /**
   *
   * @returns List of timeseries objects ready to be loaded into the timeseries table
   */
  _renderStagedTimeseries() {
    return this.stagedTimeseries.map(
      item => html`<ui5-li
        title="${item.ts_path}"
        .icon="${DynamicSelectionUtil.getSuggestionItemIcon(item)}"
        .description="${item.description}"
        .additionalText="${item.additionalText}"
        .additionalTextState="${DynamicSelectionUtil.getValueState(item)}"
        .ts_path="${item.ts_path}"
        .text=${item.text}
        >${item.text}</ui5-li
      >`,
    );
  }

  render() {
    return html`
      <ui5-popover
        id="ts-selection-dynamic-popover"
        header-text="${this.i18n.t('timeseriesExplorer')}"
      >
        <div id="ts-selection-dynamic">
          <ui5-combobox
            id="station-selection"
            placeholder="${this.i18n.t('availableStations')} (${this
              .availableStations.length})"
            value="${this.selectedStation.text}"
            @selection-change=${this._handleStationSelect}
          >
            ${this._renderStationSelectionItems()}
          </ui5-combobox>
          <ui5-input
            id="suggestions-input"
            show-suggestions
            show-clear-icon
            noTypeahead
            placeholder="${this.i18n.t('availableTimeseriesFor')} ${this
              .selectedStation?.text} (${this.availableTimeseries.length})"
            @input=${this._handleTimeseriesTyping}
            @suggestion-item-select="${this._handleTimeseriesItemSelected}"
            @click="${e => {
              this._handleTimeseriesTyping(e);
            }}"
          >
            ${this._renderSuggestionItems()}
          </ui5-input>
        </div>
        <div style="margin-top: 10px;">
          <ui5-label for="selectedTimeSeriesList"
            >${this.i18n.t('selectedTimeseries')}</ui5-label
          >
          <ui5-list
            id="selectedTimeSeriesList"
            class="full-width"
            mode="Delete"
            no-data-text="${this.i18n.t('noTimeseriesSelected')}"
            @item-delete="${this._handleTimeseriesDelete}"
          >
            ${this._renderStagedTimeseries()}
          </ui5-list>
        </div>
        <div slot="footer" class="popover-footer">
          <ui5-button
            id="addTimeseriesButton"
            design="Emphasized"
            .disabled="${!this.stagedTimeseries.length}"
            @click="${this._handleAddTimeseries}"
            >${this.i18n.t('add')}
            ${this.stagedTimeseries.length
              ? `(${this.stagedTimeseries.length})`
              : ``}</ui5-button
          >
          <ui5-button
            id="closePopoverButton"
            design="Transparent"
            @click="${() =>
              this.shadowRoot
                .querySelector('#ts-selection-dynamic-popover')
                ?.close()}"
            >${this.i18n.t('cancel')}</ui5-button
          >
        </div>
      </ui5-popover>
    `;
  }

  async _handleStationSelect(e) {
    this.selectedStation = e.detail.item;
    /** Reset Timeseries-Search field */
    this.shadowRoot.querySelector('#suggestions-input').value = '';
    this.filteredTimeseries = [];
    this.stagedTimeseries = [];

    await this._fetchStationTimeseriesList();
  }

  _handleTimeseriesDelete(e) {
    remove(this.stagedTimeseries, i => i.text === e.detail.item.text);
    this.requestUpdate();
  }

  /** @returns Filtered objects (suggestion items) matching search criteria */
  _handleTimeseriesTyping(e) {
    const value = e.target.value.toLowerCase();
    if (value === '')
      this.filteredTimeseries = orderBy(this.availableTimeseries, [
        'stationparameter_name',
        'text',
      ]);
    else
      this.filteredTimeseries = orderBy(
        DynamicSelectionUtil.match(value, this.availableTimeseries, [
          'description',
          'text',
        ]),
        ['stationparameter_name', 'text'],
      );

    e.target.openPicker();
  }

  /**
   * Resolves input and evaluates possible wildcards
   * @param e input event
   */
  _handleTimeseriesItemSelected(e) {
    const value = e.target.value.toLowerCase();

    this.stagedTimeseries = DynamicSelectionUtil.addToStagedTimeseries(
      this.stagedTimeseries,
      DynamicSelectionUtil.matchExact(value, this.filteredTimeseries),
    );

    this.requestUpdate();
  }

  /** Add staged timeseries to graph */
  _handleAddTimeseries() {
    this.dispatchEvent(
      new CustomEvent('addTimeseries', {
        bubbles: true,
        composed: true,
        detail: { value: this.stagedTimeseries },
      }),
    );
    /** Reset staged timeseries after adding them to the graph */
    this.stagedTimeseries = [];

    /** TODO: Add toast to visualize succesful operation */
  }
}

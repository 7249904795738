import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '@ui5/webcomponents/dist/Table.js';
import '@ui5/webcomponents/dist/TableColumn.js';
import '@ui5/webcomponents/dist/TableRow.js';
import '@ui5/webcomponents/dist/TableCell.js';
import '@ui5/webcomponents/dist/ComboBox.js';
import '@ui5/webcomponents/dist/Button.js';
import '@ui5/webcomponents/dist/Badge.js';
import '@ui5/webcomponents/dist/Select.js';
import '@ui5/webcomponents/dist/CheckBox.js';
import '@ui5/webcomponents/dist/Label';

import dayjs from '@kisters/wcp-base/common/dayjsext';
import { Mix } from '@kisters/wcp-base/common';
import { i18nMixin } from '@kisters/wcp-base/decorators';
import { getCsvDelimiter } from '@kisters/wcp-base/components/ki-app/services/ki-app-config-service';
import { cloneDeep, first, last } from 'lodash-es';
import type { Series } from './defs/types';
import nls from './locales/index';
import { TsGraphHelper } from './util/TsGraphHelper';

@customElement('ts-value-table')
export class TsValueTable extends Mix(LitElement, [i18nMixin, { nls }]) {
  static styles = css`
    :host {
      display: flex;
      flex-direction: column;
      position: relative;
      border-left: 1px solid #eee;
    }

    header {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }

    main {
      overflow-y: auto;
      overflow-x: hidden;
    }

    footer {
      display: flex;
      flex-direction: column;
      border-top: 1px solid #ccc;
    }

    footer > div {
      display: flex;
      padding: 5px;
    }

    ui5-list {
      margin-top: 10px;
      flex: 1;
      overflow: auto;
    }

    ui5-combobox {
      flex: 1;
    }

    .sortable:hover {
      color: dodgerblue;
    }

    #sortIcon {
      color: inherit;
    }

    table {
      margin: 0;
      border: none;
      border-collapse: separate;
      border-spacing: 0;
      table-layout: fixed;
    }

    table thead th {
      padding: 3px;
      position: sticky;
      top: 0;
      z-index: 1;
      background: white;
      min-width: 80px;
      border-bottom: 2px lightgrey solid;
    }

    .sortable {
      cursor: pointer;
      padding-left: 16px;
    }

    .sortable ki-icon {
      visibility: hidden;
    }

    .sort ki-icon,
    .sortable:hover ki-icon {
      visibility: visible;
    }

    .sort {
      background-color: #e8e8e8;
    }

    table td {
      padding: 4px 5px;
    }

    table thead th:first-child {
      position: sticky;
      left: 0;
      z-index: 2;
      border-bottom: 2px lightgrey solid;
    }

    table tbody th {
      position: sticky;
      left: 0;
      background: white;
      z-index: 1;
      overflow: hidden;
    }

    .value {
      text-align: right;
      padding-right: 8px;
    }
  `;

  datePattern = 'DD.MM.YYYY HH:mm';

  @property({ type: Object })
  _selected: Series | undefined;

  @property({
    type: Map,
    attribute: false,
  })
  selectedSeries: Map<String, Series> = new Map();

  @property({ type: Array })
  timeseries: Array<Series> = [];

  from: Date = dayjs().subtract(12, 'month').toDate();

  until: Date = dayjs().toDate();

  @property({ type: Array, attribute: false })
  orderedTableData: Array<[timestamp: string, value: string]> = [];

  @property({ type: Boolean })
  filterEmptyValues = true;

  @property({ type: Boolean })
  sortAsc = false;

  switchSort(e: Event) {
    this.sortAsc = !this.sortAsc;
  }

  set selected(item: Series | undefined) {
    const data = item?.data?.map(i => ({
      ts: dayjs(i[0]).format(this.datePattern),
      value: i[1],
    }));

    const d =
      item?.meta?.type === 'threshold'
        ? cloneDeep(data).slice(0, data.length - 1)
        : cloneDeep(data);
    this.orderedTableData = d?.slice(0, data.length - 1);
    const i = cloneDeep(item);
    if (item?.meta?.type === 'threshold')
      i.data = i.data.slice(0, data.length - 1);
    this._selected = i;
  }

  get selected() {
    return this._selected;
  }

  // eslint-disable-next-line class-methods-use-this
  exportCsv(e) {
    /** https://stackoverflow.com/a/24922761/4236831 */
    const filename =
      `exp_${this.selected?.station.name}_${this.selected?.name}`.replace(
        /\./g,
        '',
      );
    const csv = this._getCsv();
    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, 'filename');
    } else {
      var link = document.createElement('a');
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  exportClipboard(e) {
    navigator.clipboard.writeText(this._getCsv());
  }

  // eslint-disable-next-line class-methods-use-this
  _getCsv(): string {
    const exportData = this.sortAsc
      ? this.orderedTableData
      : [...this.orderedTableData].reverse();
    const filtered = this.filterEmptyValues
      ? exportData.filter(i => i.value !== null)
      : exportData;

    const result: string = `#${this.i18n.t(
      'timestamp',
    )}${getCsvDelimiter()}${this.i18n.t('measurement')}\r\n`;
    const content = filtered
      .map(i => i.ts + getCsvDelimiter() + i.value)
      .join('\r\n');
    return result.concat(content);
  }

  handleTsSelected(e) {
    this.selected = this.selectedSeries.get(e.detail.selectedOption.id);
  }

  _handleFilterChange(e) {
    this.filterEmptyValues = !this.filterEmptyValues;
  }

  protected updateSelection() {
    // Remove all thresholds
    this.timeseries = Array.from(this.selectedSeries, entry => entry[1]).filter(
      s => s.type !== 'threshold',
    );
    this.selected = first(this.timeseries);
    this.requestUpdate();
  }

  _renderTableRows(
    data: Array<Array<[number, number | null, number, number]>>,
  ) {
    if (data?.length) {
      if (this.sortAsc) return data.map(d => this._renderTableRow(d[0], d[1]));
      return [...data].reverse().map(d => this._renderTableRow(d[0], d[1]));
    }
    return html``;
  }

  _renderTableRow(ts: number, value: number | null) {
    if (this.filterEmptyValues && value === null) {
      return html``;
    }
    return html`<tr>
      <td class="timestamp">
        ${TsGraphHelper.formatTimestamp(ts, `${this.datePattern}`)}
      </td>
      <td class="value">${value?.toFixed(2)}</td>
    </tr>`;
  }

  render() {
    return html`
      <header>
        <div
          style="display: flex; align-items: center; padding: 5px; justify-content: space-between"
        >
          <ui5-label
            ><b
              >${this.selected?.station.name ||
              this.i18n.t('noTimeseriesSelected')}</b
            ></ui5-label
          >
          <div>
            <ui5-button
              icon="copy"
              design="Transparent"
              icon-end
              tooltip="${this.i18n.t('exportAsClipboard')}"
              ?disabled=${this.selected === undefined}
              @click="${this.exportClipboard}"
            ></ui5-button>
            <ui5-button
              icon="download"
              design="Transparent"
              icon-end
              tooltip="${this.i18n.t('exportAsCsv')}"
              ?disabled=${this.selected === undefined}
              @click="${this.exportCsv}"
            ></ui5-button>
          </div>
        </div>
        <div style="display: flex; margin: 3px; margin-bottom: 5px;">
          <ui5-select
            style="width: 100%"
            ?disabled=${this.selected === undefined}
            design="Transparent"
            placeholder="${this.i18n.t('pleaseSelectATimeseries')}"
            @change="${this.handleTsSelected}"
          >
            ${this.timeseries.map(
              s =>
                html`<ui5-option
                  additional-text="${s.station.name}"
                  id="${s.id}"
                  ?selected=${this.selected?.id === s.id}
                  >${s.name}</ui5-option
                >`,
            )}
          </ui5-select>
        </div>
      </header>
      <main>
        <table class="" id="valuetable">
          <thead>
            <th
              style="position: sticky"
              slot="columns"
              @click="${this.switchSort}"
            >
              <ui5-label
                ><span
                  class=" sortable"
                  style="display: inline-flex; min-width: 120px"
                  >${this.i18n.t('timestamp')}
                  <ui5-icon
                    id="sortIcon"
                    style="margin-left: 5px"
                    name="sort"
                  ></ui5-icon
                ></span>
              </ui5-label>
            </th>
            <th slot="columns">
              <ui5-label
                ><span style="min-width: 120px"
                  >${this.i18n.t('measurement')}
                  [${this.selected?.parameter.unit || ' '}]</span
                ></ui5-label
              >
            </th>
          </thead>
          <tbody>
            ${this._renderTableRows(this.selected?.data)}
          </tbody>
        </table>
      </main>
      ${this.selected?.data?.length
        ? html`<footer>
            <div>
              <ui5-badge color-scheme="6">
                ${this.selected?.data
                  ? TsGraphHelper.formatTimestamp(first(this.selected?.data)[0])
                  : ``}
                -
                ${this.selected?.data
                  ? TsGraphHelper.formatTimestamp(last(this.selected?.data)[0])
                  : ``}</ui5-badge
              >
            </div>
            <ui5-checkbox
              text=${this.i18n.t('FILTER_EMPTY_VALUES')}
              ?checked=${this.filterEmptyValues}
              @change="${this._handleFilterChange}"
            ></ui5-checkbox>
          </footer>`
        : html``}
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ts-value-table': TsValueTable;
  }
}

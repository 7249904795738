import { WwpStationDetail } from '@kisters/wiski-web/views';
import { customElement } from 'lit/decorators.js';
import { navigateTo } from '@kisters/wcp-base/components';
import { Mix } from '@kisters/wcp-base/common';
import { find, first } from 'lodash-es';
import { LanuvStation } from './LanuvStation';

@customElement('ww-station-detail-lanuv-external')
export default class WwStationDetailLanuvExternal extends Mix(
  WwpStationDetail,
) {
  async fetchData(layerName) {
    if (this.api && this.stationId) {
      this._stations = await this.api.getStations();
      this._sortedStations = this._stations.sort((a, b) => {
        const aId = parseFloat(a.station_id);
        const bId = parseFloat(b.station_id);
        return aId > bId;
      });

      this._station = await this.api.getStation(this.stationId);
      this._fetchMapData();

      const dataToFetch: Array<string> = [];

      this._station.timeseries.forEach(ts => {
        dataToFetch.push(ts.href);
      });
      const _data = [];
      await Promise.all(
        dataToFetch.map(async href => {
          const _l = href.split('/').slice(-4);
          const _id = _l[3].replace('.json', '');
          if (!_data[_id]) _data[_id] = [];
          _data[_id].push(
            first(
              await this.api.getTs({
                siteNo: _l[0],
                stationNo: _l[1],
                parameterName: _l[2],
                tsName: _id,
              }),
            ),
          );
        }),
      );

      const _weekData: Array<LanuvStation> = _data.week;

      this._menuList = this._menuFilter(this._menuListRaw);
      this._menuList = this._parameterMenuFilter(_weekData);

      if (!this._menuList.some(item => item.path === this._selectedMenuItem)) {
        this._selectedMenuItem = null;
      }

      if (!this._selectedMenuItem) {
        const initalSelection =
          find(this._menuList, { path: `/${layerName}` })?.path ||
          this.defaultSelection ||
          this._menuList[0]?.path;

        setTimeout(() => {
          navigateTo(`${this._mainRoute}${initalSelection}`);
        }, 10);

        this._selectedMenuItem = initalSelection;
      }
      this.__loaded = true;

      this.requestUpdate();
    }
  }

  _parameterMenuFilter(weekData: Array<LanuvStation>) {
    if (!weekData)
      console.error('No week resolution data found for this station');
    const filteredMenu = [];
    this._menuList?.forEach(li => {
      const i = find(weekData, wd => wd.stationparameter_name === li.label);
      if (
        li.label === 'Stationsinformationen' ||
        (i?.LANUV_PARA_PUBLIC &&
          ['LVN-Web', 'LVN-Web-App', 'LVN-Web-LHP-App'].includes(
            i.LANUV_PARA_PUBLIC,
          ))
      )
        filteredMenu.push(li);
    });
    return filteredMenu;
  }
}

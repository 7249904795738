import { css } from 'lit';

// language=CSS
export default css`
  #ts-selection-dynamic {
    display: flex;
    flex-direction: column;
  }

  #station-selection {
    margin: 2px;
    width: 450px;
  }

  #suggestions-input {
    margin: 2px;
    width: 450px;
  }

  ui5-li {
    height: 70px;
    background: #f7f7f7;
    border: 2px solid white;
  }

  .popover-footer {
    padding: 5px;
  }
`;

/* eslint-disable class-methods-use-this */
import WwMapPopupBase from '@kisters/wiski-web/components/common/ww-map-popup-base';
import { customElement } from 'lit/decorators.js';
import { html, css } from 'lit';
import '@kisters/ww-lanuv-common/components/ww-lanuv-graph/ww-lanuv-graph';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import dayjs from '@kisters/wcp-base/common/dayjsext';

const niederschlag = {
  miniGraph: true,
  onlyFetchSpecifiedRanges: true,
  showAccuracy: false,
  parameter_label: 'Niederschlag',
  // eslint-disable-next-line no-template-curly-in-string
  graphTitle: '${station_name} - Niederschlag',
  station_parameter: 'N',
  chartType: 'column',
  ranges: [
    {
      label: 'Woche',
      value: 'P7D',
      data: 'week.json',
      selected: true,
      tslabel: 'Stundensummen  Niederschlag',
      collabel: 'Stundensumme [mm]',
    },
  ],
  yAxisLabel: 'Niederschlag [mm]',
};

const behaelterinhalt = {
  miniGraph: true,
  onlyFetchSpecifiedRanges: true,
  showAccuracy: false,
  parameter_label: 'Behälterinhalt',
  // eslint-disable-next-line no-template-curly-in-string
  graphTitle: '${station_name} - Behälterinhalt',
  station_parameter: 'N',
  ranges: [
    {
      label: 'Woche',
      value: 'P7D',
      data: 'topf.json',
      selected: true,
      tslabel: 'Behälterinhalt',
      collabel: 'Behälterinhalt [mm]',
    },
  ],
  yAxisLabel: 'Behälterinhalt [mm]',
  numberFormat: {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  },
};

const wassertemperatur = {
  miniGraph: true,
  onlyFetchSpecifiedRanges: true,
  additionalAnnotationUrl:
    'https://www.lanuv.nrw.de/umwelt/wasser/gewaesserueberwachung/gewaessertemperatur',
  numberFormat: {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  },

  // eslint-disable-next-line no-template-curly-in-string
  graphTitle: '${station_name} (${WTO_OBJECT}) - Wassertemperatur',
  parameter_label: 'Wassertemperatur',
  yAxisLabel: 'Wassertemperatur [°C]',
  station_parameter: 'WT',
  ranges: [
    {
      label: 'Woche',
      value: 'P7D',
      data: 'week.json',
      selected: true,
    },
  ],
};

const wasserstand = {
  miniGraph: true,
  onlyFetchSpecifiedRanges: true,
  additionalAnnotationUrl:
    'https://www.lanuv.nrw.de/umwelt/wasser/wasserkreislauf/wasserstaende/pegeldaten-online',
  annotations: [
    {
      text: '* Bezugszeitraum 2008 - 2018',
      dependsOnOtherAnnotations: true,
    },
  ],
  numberFormat: {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  },

  parameter_label: 'Wasserstand',
  // eslint-disable-next-line no-template-curly-in-string
  graphTitle: '${station_name} (${WTO_OBJECT}) - Wasserstand',
  station_parameter: 'S',
  yAxisLabel: 'Wasserstand [cm]',
  ranges: [
    {
      label: 'Woche',
      value: 'P7D',
      data: 'week.json',
      selected: true,
    },
  ],
  alarms: {
    file: ['alarmlevel.json'],
    items: [
      {
        label: 'Informationswert 1',
        tsList: ['Cmd.Sys.W.Info1'],
        active: 'auto',
      },
      {
        label: 'Informationswert 2',
        tsList: ['Cmd.Sys.W.Info2'],
        active: 'auto',
      },
      {
        label: 'Informationswert 3',
        tsList: ['Cmd.Sys.W.Info3'],
        active: 'auto',
      },
      {
        label: 'Niedrigwasserkennwerte',
        tsList: ['Cmd.Sys.W.N7W', 'Cmd.Sys.W.MN7W', 'Cmd.Sys.W.HN7W'],
        active: false,
      },
    ],
  },
  threshholdMappings: {
    'Cmd.Sys.W.MNW': {
      width: 2,
      color: 'rgba(64,64,64, 1)',
      dashStyle: 'dash',
      label: {
        text: 'MNW',
        shortText: 'MNW',
        align: 'right',
        description: 'Mittleres Niedrigwasser*',
        y: 12,
        x: -5,
      },
    },
    'Cmd.Sys.W.MW': {
      width: 2,
      color: 'rgba(64,64,64, 1)',
      dashStyle: 'dash',
      label: {
        text: 'MW',
        shortText: 'MW',
        align: 'right',
        description: 'Mittelwasser*',
        y: 12,
        x: -5,
      },
    },
    'Cmd.Sys.W.MHW': {
      width: 2,
      color: 'rgba(64,64,64, 1)',
      dashStyle: 'dash',
      label: {
        text: 'MHW',
        shortText: 'MHW',
        align: 'right',
        description: 'Mittleres Hochwasser*',
        y: 12,
        x: -5,
      },
    },
    'Cmd.Sys.W.N7W': {
      width: 2,
      color: 'rgba(197,142,69, 1)',
      dashStyle: 'dash',
      label: {
        text: 'N7W',
        shortText: 'N7W',
        align: 'center',
        description: 'Minimum der niedrigsten jährl. 7-Tages-Mittel*',
        y: 12,
        x: 25,
      },
    },
    'Cmd.Sys.W.MN7W': {
      width: 2,
      color: 'rgba(255,221,128,1)',
      dashStyle: 'dash',
      label: {
        text: 'MN7W',
        shortText: 'MN7W',
        align: 'center',
        description: 'Mittel der niedrigsten jährl. 7-Tages-Mittel*',
        y: 12,
        x: -25,
      },
    },
    'Cmd.Sys.W.HN7W': {
      width: 2,
      color: 'rgba(99,195,199,1)',
      dashStyle: 'dash',
      label: {
        text: 'HN7W',
        shortText: 'HN7W',
        align: 'center',
        description: 'Maximum der niedrigsten jährl. 7-Tages-Mittel*',
        y: 12,
        x: -75,
      },
    },
    'Cmd.Sys.W.Info1': {
      width: 2,
      color: 'rgba(255, 231, 76, 1)',
      dashStyle: 'dash',
      label: {
        text: 'Informationswert 1',
        shortText: 'Info 1',
        align: 'left',
        description:
          'Ggfs. Ausuferung des Gewässers, land- und forstwirtschaftliche Flächen können überflutet werden; leichte Verkehrsbehinderungen auf Hauptverkehrs- und Gemeindestraßen sind möglich.',
        y: 12,
        x: -5,
      },
    },
    'Cmd.Sys.W.Info2': {
      width: 2,
      color: 'rgba(252, 186, 2, 1)',
      dashStyle: 'dash',
      label: {
        text: 'Informationswert 2',
        shortText: 'Info 2',
        align: 'left',
        description:
          'Gefahr der Überflutung einzelner bebauter Grundstücke oder Keller; Sperrung überörtlicher Verkehrsverbindungen oder vereinzelter Einsatz der Wasser- oder Dammwehr (Feuerwehr, Katastrophenschutz) möglich.',
        y: 12,
        x: -5,
      },
    },
    'Cmd.Sys.W.Info3': {
      width: 2,
      color: 'rgba(252, 81, 47, 1)',
      dashStyle: 'dash',
      label: {
        text: 'Informationswert 3',
        shortText: 'Info 3',
        align: 'left',
        description:
          'Bebaute Gebiete in größerem Umfang können überflutet werden; Einsatz der Wasser- oder Dammwehr (Feuerwehr, Katastrophenschutz) in großem Umfang möglich.',
        y: 12,
        x: -5,
      },
    },
  },
};

const niedrigwasser = {
  miniGraph: true,
  onlyFetchSpecifiedRanges: true,
  numberFormat: {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  },

  parameter_label: 'Wasserstand',
  // eslint-disable-next-line no-template-curly-in-string
  graphTitle: '${station_name} (${WTO_OBJECT}) - Wasserstand',
  station_parameter: 'S',
  yAxisLabel: 'Wasserstand [cm]',
  ranges: [
    {
      label: 'Woche',
      value: 'P7D',
      data: 'week.json',
      selected: true,
    },
  ],
  alarms: {
    file: ['alarmlevel.json'],
    items: [
      {
        label: 'MW',
        tsList: ['Cmd.Sys.W.MW'],
        active: true,
      },
      {
        label: 'N7W',
        tsList: ['Cmd.Sys.W.N7W'],
        active: 'automin',
      },
      {
        label: 'MN7W',
        tsList: ['Cmd.Sys.W.MN7W'],
        active: 'automin',
      },
      {
        label: 'HN7W',
        tsList: ['Cmd.Sys.W.HN7W'],
        active: 'automin',
      },
    ],
  },
  threshholdMappings: {
    'Cmd.Sys.W.MW': {
      width: 2,
      color: 'rgba(64,64,64, 1)',
      dashStyle: 'dash',
      label: {
        text: 'MW',
        shortText: 'MW',
        align: 'right',
        description: 'Mittelwasser*',
        y: 12,
        x: -5,
      },
    },
    'Cmd.Sys.W.N7W': {
      width: 2,
      color: 'rgba(197,142,69, 1)',
      dashStyle: 'dash',
      label: {
        text: 'N7W',
        shortText: 'N7W',
        align: 'center',
        description: 'Minimum der niedrigsten jährl. 7-Tages-Mittel*',
        y: 12,
        x: 25,
      },
    },
    'Cmd.Sys.W.MN7W': {
      width: 2,
      color: 'rgba(255,221,128,1)',
      dashStyle: 'dash',
      label: {
        text: 'MN7W',
        shortText: 'MN7W',
        align: 'center',
        description: 'Mittel der niedrigsten jährl. 7-Tages-Mittel*',
        y: 12,
        x: -25,
      },
    },
    'Cmd.Sys.W.HN7W': {
      width: 2,
      color: 'rgba(99,195,199,1)',
      dashStyle: 'dash',
      label: {
        text: 'HN7W',
        shortText: 'HN7W',
        align: 'center',
        description: 'Maximum der niedrigsten jährl. 7-Tages-Mittel*',
        y: 12,
        x: -75,
      },
    },
  },
};

const graph = {
  miniGraph: true,
  onlyFetchSpecifiedRanges: true,
  parameter_label: 'Wasserstand',
  // eslint-disable-next-line no-template-curly-in-string
  graphTitle: '${station_name} (${WTO_OBJECT}) - Wasserstand',
  station_parameter: 'S',
  yAxisLabel: 'Wasserstand [cm]',
  ranges: [
    {
      label: 'Woche',
      value: 'P7D',
      data: 'week.json',
      selected: true,
    },
  ],
};
@customElement('ww-map-popup-lanuv')
export default class WwMapPopupLanuv extends WwMapPopupBase {
  // eslint-disable-next-line class-methods-use-this
  getGraph(station) {
    if (this.layerAlias === 'Niedrigwasser') {
      return html`<ww-lanuv-graph
        id="chartcontainer"
        miniGraph
        .options="${niedrigwasser}"
        stationId="${station.station_id}"
      ></ww-lanuv-graph>`;
    }
    if (
      station.stationparameter_no === 'N' &&
      this.layerAlias !== 'LVN_Niederschlag_Topf'
    ) {
      return html`<ww-lanuv-graph-precip
        id="chartcontainer"
        miniGraph
        .options="${niederschlag}"
        stationId="${station.station_id}"
      ></ww-lanuv-graph-precip>`;
    }
    if (station.stationparameter_no === 'S') {
      return html`<ww-lanuv-graph
        id="chartcontainer"
        miniGraph
        .options="${wasserstand}"
        stationId="${station.station_id}"
      ></ww-lanuv-graph>`;
    }
    if (station.stationparameter_no === 'WT') {
      return html`<ww-lanuv-graph
        id="chartcontainer"
        miniGraph
        .options="${wassertemperatur}"
        stationId="${station.station_id}"
      ></ww-lanuv-graph>`;
    }
    if (this.layerAlias === 'LVN_Niederschlag_Topf') {
      return html`<ww-lanuv-graph
        id="chartcontainer"
        miniGraph
        .options="${behaelterinhalt}"
        stationId="${station.station_id}"
      ></ww-lanuv-graph>`;
    }
    graph.station_parameter = station.stationparameter_no;
    graph.parameter_label = station.stationparameter_name;
    return html`<ww-lanuv-graph
      id="chartcontainer"
      miniGraph
      .options="${graph}"
      stationId="${station.station_id}"
    ></ww-lanuv-graph>`;
  }

  _stationExternal(station) {
    return html`<div class="station-name">${station.station_name}</div>
      <hr />
      <div class="table">
        <div class="row">
          <div class="cell">${unsafeHTML(station.description)}</div>
        </div>
      </div> `;
  }

  _stationOutdated(station) {
    const parameter =
      station.stationparameter_longname || station.stationparameter_name;
    const value =
      station.ts_value != null
        ? `${this.valFormatter(station.ts_value)} ${station.ts_unitsymbol}`
        : '-';
    const timestamp = station.timestamp
      ? dayjs(station.timestamp).tz().format('L LT')
      : '-';

    return html`<div class="station-name">${station.station_name}</div>
      <div class="remark">${this.stationRemark}</div>
      <hr />
      <div class="table">
        <div class="row">
          <div class="cell label">${parameter}</div>
          <div class="cell"><b>${value}</b> (${timestamp})</div>
        </div>
      </div>
      <div class="table">
        <div class="row">
          <div class="cell" style="text-align: center; padding-top: 5px;">
            <span style="font-weight: bold"
              >Keine aktuellen Messwerte vorhanden</span
            >
          </div>
        </div>
      </div> `;
  }

  _stationInactive(station) {
    const parameter =
      station.stationparameter_longname || station.stationparameter_name;
    const value =
      station.ts_value != null
        ? `${this.valFormatter(station.ts_value)} ${station.ts_unitsymbol}`
        : '-';
    const timestamp = station.timestamp
      ? dayjs(station.timestamp).tz().format('L LT')
      : '-';

    return html`<div class="station-name">${station.station_name}</div>
      <div class="remark">${this.stationRemark}</div>
      <hr />
      <div class="table">
        <div class="row">
          <div class="cell label">${parameter}</div>
          <div class="cell"><b>${value}</b> (${timestamp})</div>
        </div>
      </div>
      <div class="table">
        <div class="row">
          <div class="cell" style="text-align: center; padding-top: 5px;">
            <span style="font-weight: bold"
              >Diese Station ist aktuell nicht aktiv</span
            >
          </div>
        </div>
      </div> `;
  }

  _stationTmpl(stations) {
    const station = stations[0];
    if (!station) return html`<div></div>`;
    if (station.INTERNET_AKTIV === 'no') return this._stationInactive(station);
    if (station.external) return this._stationExternal(station);
    if (this._graphIsOutdated(station)) return this._stationOutdated(station);
    const res =
      stations.length === 1
        ? super._stationTmpl(stations)
        : this.stations.map(st => super._stationTmpl([st], false));
    return html` ${res}`;
  }

  /** Checks if graph will contain data
   * @return - True if latest data is older than 7 days
   */
  _graphIsOutdated(station) {
    const ts = dayjs(station.timestamp);
    const diff = dayjs().diff(ts, 'day');
    return diff >= 6;
  }
}

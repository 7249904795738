/* eslint-disable max-classes-per-file */
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import {
  last,
  merge,
  find,
  head,
  groupBy,
  flatten,
  orderBy,
  compact,
  first,
  some,
} from 'lodash-es';
import {
  SM,
  ViewPort,
  responsiveMixin,
  i18nMixin,
  queryParamMixin,
} from '@kisters/wcp-base/decorators';
import '@kisters/wiski-web/components/ki-wwp-graph/ki-wwp-switcher-bottom';
import {
  LoaderMixin,
  template,
  copyTextToClipboard,
  Mix,
} from '@kisters/wcp-base/common';

import { getRouteOptionsAndParams } from '@kisters/wcp-base/components';
import dayjs from '@kisters/wcp-base/common/dayjsext';
import '@kisters/wiski-web/components/ki-wwp-graph/ki-wwp-ts-table';
import hclocales from '@kisters/wcp-water/common/highcharts-locales';
import Highcharts from 'highcharts/highstock';
import nodata from 'highcharts/modules/no-data-to-display';
import exporting from 'highcharts/modules/exporting';
import boost from 'highcharts/modules/boost';
import more from 'highcharts/highcharts-more';
import offlineexporting from 'highcharts/modules/offline-exporting';

import serieslabel from 'highcharts/modules/series-label';
import { getCurrentApi } from '@kisters/wiski-web/api/API';
import nls from '@kisters/wiski-web/locales/index';

import style from '@kisters/wiski-web/components/ki-wwp-graph/ki-wwp-graph.css';

import LanuvTsAnnotations from '../lanuv-ts-annotations';

import '@ui5/webcomponents/dist/Button';
import '@ui5/webcomponents/dist/Select';
import '@ui5/webcomponents/dist/Option';
import { TsAnnotation } from '../lanuv-ts-annotations/TsAnnotation';

@customElement('ww-lanuv-graph')
export default class WwLanuvGraph extends Mix(
  LitElement,
  LoaderMixin,
  [i18nMixin, { nls }],
  [
    queryParamMixin,
    {
      targetProperty: '_selectedPeriod',
      selector: 'period',
      defaultValue: '',
    },
  ],
  responsiveMixin,
) {
  // language=CSS
  static styles = [
    style,
    css`
      #annotation-wrapper {
        position: relative;
        overflow: auto;
        flex: 0 0 400px;
      }

      #annotation-wrapper.hidden {
        position: relative;
        overflow: auto;
        flex: 0 0 0;
      }

      lanuv-ts-annotations {
        position: absolute;
        height: 100%;
        width: 100%;
      }

      :host(.sm-screen) #table-wrapper,
      :host(.sm-screen) #annotation-wrapper {
        flex: 1 1 100%;
        background: white;
      }

      :host(.sm-screen) #table-wrapper.hidden,
      :host(.sm-screen) #annotation-wrapper.hidden {
        position: relative;
        overflow: auto;
        flex: 0 0 0;
      }

      .adminbemerkung {
        color: red;
        font-size: 0.65em;
      }
    `,
  ];

  __selectedPeriod;

  hydroYearStart = '01-01';

  hydroYearEnd = '01-01';

  @property({ type: Object })
  options = {};

  @property({ type: Boolean })
  miniGraph = false;

  @property({ type: String })
  stationId;

  @property({ type: String })
  adminStatus = '';

  @property({ type: String })
  adminBemerkung = '';

  get _selectedPeriod() {
    return this.__selectedPeriod;
  }

  set _selectedPeriod(value) {
    this.__selectedPeriod = value;
    this.requestUpdate();
  }

  get files() {
    return this._files;
  }

  set files(files) {
    this._files = files;
    this.adminBemerkung = first(files['week.json'])?.AdminBemerkung || '';
    this.adminStatus = first(files['week.json'])?.AdminStatus || '';
  }

  constructor() {
    super();
    serieslabel(Highcharts);
    exporting(Highcharts);
    offlineexporting(Highcharts);
    nodata(Highcharts);
    more(Highcharts);
    boost(Highcharts);

    this._tableOpen = false;
    this._annotationsOpen = false;
  }

  connectedCallback() {
    if (super.connectedCallback) super.connectedCallback();
    if (this.renderRoot.host.classList.contains('sm-screen')) {
      this._tableOpen = false;
    }
    this.numberFormatter = new Intl.NumberFormat(
      this.i18n.language,
      this.numberFormat || {},
    );

    this._annotations = [];

    let resize;
    window.addEventListener('resize', () => {
      window.clearTimeout(resize);
      resize = setTimeout(() => {
        if (this.chart) {
          this.chart.update({
            legend: this.legendOptions,
            credits: this.creditOptions,
          });
          this.chart.reflow();
        }
      }, 220);
    });
  }

  firstUpdated() {
    if (!this.miniGraph) {
      this.refreshInterval = setInterval(() => {
        this.fetchData();
      }, 300000);
    }
    Object.assign(this, this.options);
    if (
      this.renderRoot.host.classList.contains('sm-screen') ||
      this.miniGraph
    ) {
      this.tableOpen = false;
    }
    this.api = getCurrentApi();
    this.fetchData();
  }

  async onAfterEnter(location) {
    const params = getRouteOptionsAndParams(location, [
      'layerName',
      'stationId',
      'stationLabel',
    ]);

    Object.assign(this, params.options);
    this.apppath = location.route;
    this.stationId = params.stationId;
  }

  linkToUrl() {
    if (this.additionalAnnotationUrl) {
      console.log('Go to ...', this.additionalAnnotationUrl);
      window.open(this.additionalAnnotationUrl, '_blank');
    } else console.warn('No additionalAnnotationUrl provided');
  }

  createChart() {
    if (!this.chart) {
      if (hclocales[this.i18n.language.substring(0, 2)]) {
        Highcharts.setOptions({
          lang: hclocales[this.i18n.language.substring(0, 2)],
        });
      }
      this.chart = new Highcharts.StockChart(
        this.renderRoot.querySelector('#chart-node'),
        this.defaultOptions(),
        this.chartCreated.bind(this),
      );
    }
  }

  chartCreated(chart) {
    const e = new CustomEvent('load', {
      detail: chart,
    });
    this.dispatchEvent(e);
  }

  updated(changedProperties) {
    if (changedProperties.get('stationId')) {
      //   console.log(this.stationId, changedProperties.get('stationId'))
      this.station = null;
      this.fetchData();
    }
    if (this.chart) {
      //   console.log("resize bfeore", this.renderRoot.querySelector('#contentnode').clientHeight, this.chart.chartHeight)
      this.chart.reflow();
      //    console.log("resize", this.renderRoot.querySelector('#contentnode').clientHeight, this.chart.chartHeight)
    }
  }

  disconnectedCallback() {
    if (super.disconnectedCallback) super.disconnectedCallback();
    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
    }
    clearInterval(this.refreshInterval);
  }

  // eslint-disable-next-line class-methods-use-this
  get legendOptions() {
    let legendOptions = {
      layout: 'horizontal',
      align: 'left',
      verticalAlign: 'bottom',
      alignColumns: false,
      enabled: !this.miniGraph,
      symbolPadding: 5,
      itemMarginBottom: 10,
      itemDistance: ViewPort.size === SM ? 15 : 35,
      width: '80%',
      symbolRadius: 0,
      squareSymbol: false,
    };

    if (ViewPort.size === SM && ViewPort.landscape) {
      legendOptions = {
        layout: 'vertical',
        align: 'left',
        verticalAlign: 'top',
        alignColumns: false,
        itemMarginBottom: 5,
        enabled: !this.miniGraph,
        symbolPadding: 5,
        itemDistance: ViewPort.size === SM ? 15 : 35,
        width: ViewPort.size === SM ? '20%' : '80%',
        symbolRadius: 0,
        squareSymbol: false,
      };
    }
    return legendOptions;
  }

  get creditOptions() {
    const credits = this.credits ? { ...this.credits } : { enabled: false };
    if (credits?.text) {
      credits.text = template(credits.text, this.station);
    }
    if (this.credits && ViewPort.size === SM) {
      credits.position = credits.positionMobile || credits.position;
    }
    credits.enabled = !this.miniGraph;
    return credits;
  }

  // eslint-disable-next-line class-methods-use-this
  getTableValuesFromTemplate(cfg, data, minDate, maxDate = Infinity) {
    const tsIndex = {};
    cfg.tableTemplate.forEach(tsshortname => {
      const ts = find(data, { ts_shortname: tsshortname });
      const tsdata = groupBy(ts.data, head);
      tsIndex[ts.ts_shortname] = tsdata;
    });

    const firstts = find(data, { ts_shortname: cfg.tableTemplate[0] });
    const tsdata = [];
    firstts.data.forEach(dp => {
      const ts = dp[0];
      const tsms = new Date(ts).getTime();
      if (tsms >= minDate && tsms <= maxDate) {
        const row = [tsms];
        cfg.tableTemplate.forEach(item => {
          const tempts = tsIndex[item];
          const val = tempts[ts] ? tempts[ts][0][1] : null;
          row.push(val);
        });
        tsdata.push(row);
      }
    });
    return { data: tsdata };
  }

  // eslint-disable-next-line consistent-return
  _getSeries(cfg, data, minDate, maxDate = Infinity) {
    if (cfg.timeseries) {
      const tslist = [];
      cfg.timeseries.forEach((tscfg, i) => {
        if (tscfg.ts_shortname_min && tscfg.ts_shortname_max) {
          const minTs = find(data, {
            ts_shortname: tscfg.ts_shortname_min,
          });
          const maxTs = find(data, {
            ts_shortname: tscfg.ts_shortname_max,
          });
          const minIdx = groupBy(minTs.data, head);
          const tsdata = [];

          maxTs.data.forEach(dp => {
            const ts = dp[0];
            const tsms = new Date(ts).getTime();
            const max = dp[1];
            const min = minIdx[ts] ? minIdx[ts][0][1] : null;
            if (tsms >= minDate && tsms <= maxDate) {
              tsdata.push([tsms, min, max]);
            }
          });

          tslist.push(
            merge(
              {
                data: tsdata,
                id: `data_${i}`,
                name: this.parameter_label,
                color: 'rgba(0, 86, 160, 0.3)',
                type: 'arearange',
                fillOpacity: 0.3,
                xAxis: 'mainXAxis',

                //   gapSize: 2,
                selected: true,
                marker: {
                  enabled: false,
                },
                tooltip: {
                  valueSuffix: ` ${minTs.ts_unitsymbol}`,
                  xDateFormat: '%d.%m.%Y',
                },
              },
              tscfg.options,
            ),
          );
        } else {
          const ts = find(data, { ts_shortname: tscfg.ts_shortname });
          if (ts) {
            if (tscfg.minDate) {
              minDate = dayjs()
                .tz()
                .subtract(dayjs.duration(tscfg.minDate))
                .valueOf();
            }
            tslist.push(
              merge(
                {
                  data: this._formatGraphData(ts.data, minDate, maxDate),
                  id: `data_${i}`,
                  name: this.parameter_label,
                  color: '#0056a0',
                  xAxis: 'mainXAxis',
                  selected: true,
                  marker: {
                    radius: 1,
                    enabled: true,
                  },
                  tooltip: {
                    valueSuffix: ` ${ts.ts_unitsymbol}`,
                  },
                },
                tscfg.options,
              ),
            );
          }
        }
      });
      return tslist;
    }
    if (data[0]) {
      const options = cfg.options || {};
      const ts = data[0];
      return [
        merge(
          {
            data: this._formatGraphData(ts.data, minDate, maxDate),
            id: 'data',
            color: '#0056a0',
            name: this.parameter_label,
            xAxis: 'mainXAxis',
            selected: true,
            marker: {
              radius: 1,
              enabled: true,
            },
            tooltip: {
              valueSuffix: ` ${ts.ts_unitsymbol}`,
              xDateFormat: '%d.%m.%Y %H:%M',
            },
          },
          options,
        ),
      ];
    }
  }

  // eslint-disable-next-line class-methods-use-this
  _formatGraphData(data = [], minDate = -Infinity, maxDate = Infinity) {
    const tsData = [];
    //  const start = dayjs().valueOf()
    data.forEach(dp => {
      const ts = new Date(dp[0]).getTime();
      if (ts >= minDate && ts <= maxDate) {
        tsData.push([ts, dp[1]]);
      }
    });
    // console.log("duration", tsData.length,  dayjs().valueOf()-start)
    return tsData;
    // return data.map(dp => [new Date(dp[0]).getTime(), dp[1]]);
  }

  _formatNumber(val) {
    return val === undefined || val === null || val === '' || Number.isNaN(val)
      ? ''
      : this.numberFormatter.format(val);
  }

  _getTimestampFromDuration(dur) {
    if (!dur) {
      return dayjs().tz().valueOf();
    }
    if (dur === 'PoR') {
      return dayjs(this.coverage.min).tz().valueOf();
    }
    if (dur.charAt(0) === '+') {
      return dayjs().tz().add(dayjs.duration(dur)).valueOf();
    }
    return dayjs().tz().subtract(dayjs.duration(dur)).valueOf();
  }

  drawSeries(period, min?, max?) {
    const cfg = find(this.ranges, { value: period }) || this.ranges[0];

    if (cfg.yearSelector) {
      this.renderRoot
        .querySelector('#yearselector')
        .classList.remove('hideButton');
    } else {
      this.renderRoot
        .querySelector('#yearselector')
        .classList.add('hideButton');
    }
    if (
      this.station.BODY_RESPONSIBLE &&
      this.station.BODY_RESPONSIBLE === 'WSV' // TODO: Remove LANUV?! artifact
    ) {
      this.renderRoot
        .querySelector('#yearselector')
        .classList.add('hideButton');
    }
    this.chart.series
      .filter(ser => ser.userOptions.id && ser.userOptions.id.includes('data'))
      .forEach(ser => ser.remove(false));
    const minDate = min || this._getTimestampFromDuration(period);
    let maxDate = max || this._getTimestampFromDuration();
    const xAxis = this.chart.get('mainXAxis');
    this.chart.zoomOut();
    if (cfg.yAxisLabel) {
      this.yAxisLabel = cfg.yAxisLabel;
      this.shadowRoot.getElementById('dataTable').columns = this.tablecolumns;
    }
    cfg.timeseries &&
      cfg.timeseries.forEach(ts => {
        if (ts.maxDate) {
          maxDate = this._getTimestampFromDuration(ts.maxDate);
        }
      });

    if (cfg.plotLines) {
      cfg.plotLines.forEach(pl => {
        const _pl = { ...pl };
        _pl.id = `dynamic_${pl.id}`;
        _pl.value = this._getTimestampFromDuration(pl.value);
        if (pl.filter) {
          cfg.timeseries.some(item => item.ts_shortname === pl.filter) &&
            xAxis.addPlotLine(_pl);
        } else {
          xAxis.addPlotLine(_pl);
        }
      });
    } else {
      xAxis.plotLinesAndBands.forEach(
        pl => pl.id.includes('dynamic_') && xAxis.removePlotLine(pl.id),
      );
    }
    xAxis.setExtremes(minDate, maxDate, false, false);

    this.chart.get('data') && this.chart.get('data').remove(false);
    if (this.files[cfg.data]) {
      const series = this._getSeries(
        cfg,
        this.files[cfg.data],
        minDate,
        maxDate,
      );
      // eslint-disable-next-line no-nested-ternary
      const tabledata = cfg.tableTemplate
        ? this.getTableValuesFromTemplate(
            cfg,
            this.files[cfg.data],
            minDate,
            maxDate,
          )
        : Array.isArray(series)
        ? series[0]
        : series;
      if (tabledata) {
        // Sort and format
        this.orderedTableData = orderBy(tabledata.data, [0], ['desc']).map(
          item => {
            const vals = [];
            item.forEach((_val, i) => {
              if (i > 0) {
                vals.push(this._formatNumber(_val));
              }
            });
            if (vals.length === 3) {
              vals[1] = `<b>${vals[1]}</b>`;
            }
            const val = vals.join(' - ');

            return {
              timestamp: dayjs(item[0]).format(cfg.dateformat || 'L LT'),
              value: val,
            };
          },
        );
        this.shadowRoot.getElementById('dataTable').data =
          this.orderedTableData;
      }
      series.forEach((ts, i) => {
        this.chart.addSeries(ts, i === series.length - 1);
      });
    }
    this.updateAnnotations();
  }

  updateAnnotations() {
    const result: Array<TsAnnotation> = [];

    // Extract visible plot lines from graph and show in annotations menu
    this.chart.series.map(s => {
      if (this.threshholdMappings) {
        Object.keys(this.threshholdMappings).map(thresholdMappingKey => {
          // Check chart visibility to configure export legend
          if (
            this.threshholdMappings[thresholdMappingKey].seriesId ===
              `${s.options.id}` &&
            this.threshholdMappings[thresholdMappingKey].label.description
          ) {
            const _series = this.threshholdMappings[thresholdMappingKey];
            if (s.visible) _series.visibleInChart = true;
            else _series.visibleInChart = false;
            _series.showInBox = true; // Annotation box
            _series.symbol = '█';
            result.push(_series);
          }
        });
      }

      if (['Wasserstand', 'Wassertemperatur'].some(v => s.name.includes(v))) {
        const _series = { label: {} };
        if (s.visible) _series.visibleInChart = true;
        else _series.visibleInChart = false;
        _series.showInBox = false;
        _series.label.text = s.name;
        _series.color = 'rgba(0, 86, 160, 1)';
        if (s.name.includes('Tagesmaximum')) _series.symbol = '▲';
        else _series.symbol = '█';
        result.unshift(_series); // Add" Wasserstand" to beginning
      }
    });

    // Add Range-specific annotations, see app.json -> options -> ranges -> annotations
    const r = find(
      this.getRangeOptions(),
      _r => _r.value === this._selectedPeriod,
    );
    if (r && r.annotations) {
      r.annotations.forEach(a => {
        if (typeof a === 'string')
          result.push({
            label: {
              text: a,
            },
            showInBox: true,
            exportAlways: true,
          } as TsAnnotation);
        else if (typeof a === 'object') {
          result.push({
            label: a,
            showInBox: true,
            exportAlways: true,
          } as TsAnnotation);
        }
      });
    }

    // Add Range-inidpendent annotations, see app.json -> optins -> annotations
    if (this.annotations)
      this.annotations.forEach(a => {
        if (typeof a === 'string')
          result.push({
            label: {
              text: a,
            },
            showInBox: true,
            exportAlways: true,
          } as TsAnnotation);
        else if (typeof a === 'object') {
          result.push({
            label: a,
            showInBox: true,
            exportAlways: true,
          } as TsAnnotation);
        }
      });

    // Update Component
    this._annotations = result;

    this._annotationsWidget = this.shadowRoot.querySelector('#annotations');
    this._annotationsWidget.updateAnnotations(result);
  }

  getRangeOptions() {
    return this.ranges || [];
  }

  toggleAnnotations() {
    this.updateAnnotations();
    this._annotationsOpen = !this._annotationsOpen;
    this.requestUpdate();
  }

  getImageShare() {
    return this.api
      ? `${window.location.origin}${window.location.pathname.replace(
          /[^/]*$/,
          '',
        )}graph.html?stationId=${this.station.station_id}&station_parameter=${
          this.station_parameter
        }&dataPath=${this.api.dataPath}&key=${this.key}#/?period=${
          this._selectedPeriod
        }`
      : '';
  }

  render() {
    // language=html
    const title =
      this.graphTitle && this.station
        ? template(this.graphTitle, this.station)
        : '';
    return html`<div id="nav-node" class="${this.miniGraph ? 'hidden' : ''}">
        <div class="label" style="display: flex; flex-direction: column">
          <span>${title}</span> ${this._renderAdminStatus()}
        </div>
        <ki-wwp-switcher-bottom
          @changed="${e => {
            if (this.chart) {
              if (this._annotationsOpen) this._annotationsOpen = false;
              this.shadowRoot.querySelector('#descriptionButton').active =
                false;
              this._selectedPeriod = e.detail.value;
              this.drawSeries(e.detail.value);
            }
          }}"
          .options="${this.getRangeOptions()}"
          class="buttons"
          .value=${this._selectedPeriod}
        >
        </ki-wwp-switcher-bottom>
        <div class="actions">
          <ki-icon-btn
            class="ripple clipboard-btn ${this.imageShare &&
            ViewPort.size !== SM
              ? ''
              : 'hideButton'}"
            title="${this.i18n.t('shareLink')}"
            icon="ki ki-share"
            id="link-btn"
            @click=${() => {
              this.renderRoot.querySelector(
                '#embeddedgraph',
              ).value = `<iframe src="${this.getImageShare()}" style="width:80%;height:700px;border:0;"></iframe>"`;
              const popup = this.renderRoot.querySelector('#download-popup');
              window.open(this.getImageShare(), '_blank');
              popup.visible ? popup.hide({}) : popup.show({});
            }}
          ></ki-icon-btn>
          <ki-popup
            for="link-btn"
            style="margin-right: 20px;"
            top="40px"
            left="-100"
            id="download-popup"
          >
            <div
              style="padding: 10px;font-size: 1.2em;border-bottom: 1px solid gray;display:flex;justify-content:space-between;align-items: center;"
            >
              ${this.i18n.t('embedGraph')}<ki-icon-btn
                title="schließen"
                @click="${() => {
                  this.renderRoot.querySelector('#download-popup').hide({});
                }}"
                style="margin:0;height: 20px;width: 20px;line-height: 20px;"
                icon="ki ki-times"
              ></ki-icon-btn>
            </div>
            <textarea
              disabled
              id="embeddedgraph"
              style="border: none;outline: none;resize:none;padding:10px;background:white;width: 90%;"
              rows="12"
            >
            </textarea>
            <ui5-button
              @click="${() => {
                window.open(this.getImageShare(), '_blank');
              }}"
              title="${this.i18n.t('previewTitle')}"
              >${this.i18n.t('preview')}</ui5-button
            >
            <ui5-button
              @click="${() => {
                copyTextToClipboard(
                  this.renderRoot.querySelector('#embeddedgraph').value,
                );
              }}"
              title="${this.i18n.t('copyToClipboard')}"
              >${this.i18n.t('copy')}</ui5-button
            >
          </ki-popup>
          ${this._renderAnnotations()}
          <ki-icon-btn
            class="ripple"
            id="graph-download-button"
            title="${this.i18n.t('downloadGraph')}"
            icon="ki ki-download"
            @click="${this.downloadGraph}"
          ></ki-icon-btn>

          <ki-icon-btn
            class="ripple clipboard-btn ${this._tableOpen ? '' : 'hideButton'}"
            id="table-clipboard-btn"
            tooltip="${this.i18n.t('copyTableMessage')}"
            ,
            title="${this.i18n.t('copyTableTitle')}"
            icon="ki ki-clipboard"
            .value=${this.orderedTableData}
            @click=${() => {
              copyTextToClipboard(
                this.renderRoot
                  .querySelector('#dataTable')
                  .toCSV()
                  .replace(/(<([^>]+)>)/gi, ''),
              );
            }}
          ></ki-icon-btn>
          <ki-icon-btn
            toggle
            title="${this.i18n.t('tableTitle')}"
            icon="ki ki-table"
            .selected="${this._tableOpen}"
            @click="${this.showTable}"
          ></ki-icon-btn>
        </div>
      </div>
      <div class="contentnode">
        <div id="chart-wrapper">
          <ui5-select
            class="hideButton"
            id="yearselector"
            @change="${this._handleYearChange}"
          >
            ${this._years.map(
              year =>
                html`<ui5-option
                  ?selected=${year.value === 'YTD'}
                  value="${year.value}"
                  >${year.label}</ui5-option
                >`,
            )}
          </ui5-select>
          <div id="chart-node"></div>
        </div>
        <div id="table-wrapper" class="${this._tableOpen ? '' : 'hidden'}">
          <ki-wwp-ts-table
            id="dataTable"
            .columns="${this.tablecolumns}"
            sort='[{"field":"timestamp", "ascending":false}]'
            idproperty="timestamp"
          >
          </ki-wwp-ts-table>
        </div>
        <div
          id="annotation-wrapper"
          class="${this._annotationsOpen ? '' : 'hidden'}"
        >
          <lanuv-ts-annotations
            id="annotations"
            .additionalAnnotationUrl="${this.additionalAnnotationUrl}"
          ></lanuv-ts-annotations>
        </div>
      </div>`;
  }

  _renderAdminStatus() {
    return this.adminStatus || this.adminBemerkung
      ? html`<span class="adminbemerkung"
          >${!['', '---'].includes(this.adminStatus)
            ? html`<b>${this.adminStatus}: </b>`
            : ``}
          ${this.adminBemerkung}
        </span>`
      : html``;
  }

  /* Graph export */
  get _internetBemerkungHtml() {
    return this.station && this.station.INTERNET_BEMERKUNG !== ''
      ? `</br><span style="color: red"><b>Bemerkung:</b> ${this.station.INTERNET_BEMERKUNG}</span>`
      : '';
  }

  /* Graph export */
  get _adminBemerkungHtml() {
    return this.adminBemerkung || this.adminStatus
      ? `</br><span style="color: red"><b> ${
          this.adminStatus ? this.adminStatus + ': ' : ''
        } </b> ${this.adminBemerkung}</span>`
      : '';
  }

  get _yAxisLabel() {
    return template(this.yAxisLabel, this.station);
  }

  get _years() {
    if (this.coverage) {
      let max = dayjs(this.coverage.max).tz().get('year');
      const min = dayjs(this.coverage.min).tz().get('year');
      const years = [{ value: 'YTD', label: this.i18n.t('currentYear') }];
      while (max >= min) {
        years.push({ value: max, label: max.toString() });
        max -= 1;
      }
      return years;
    }
    return [{ value: 'YTD', label: this.i18n.t('currentYear') }];
  }

  get tablecolumns() {
    return (
      this.tableColumns ?? [
        {
          field: 'timestamp',
          label: this.timestampLabel || this.i18n.t('timestamp'),
          sortable: true,
          hideAble: false,
        },
        {
          field: 'value',
          label: this._yAxisLabel || this.i18n.t('waterlevelcm'),
          sortable: false,
          format: 'html',
          css: 'text-align:right;padding-right:10px;',
        },
      ]
    );
  }

  _renderAnnotations() {
    // Show Box if there are items to render
    // dependsOnOtherAnnotations - only shown if there is at least one not depending annotation
    const _showBox = some(
      this._annotations,
      a => a.showInBox && !a.label?.dependsOnOtherAnnotations,
    );
    if (_showBox)
      return html`<ki-icon-btn
        toggle
        id="descriptionButton"
        class="${this.hasAlarms ? '' : 'hidden'} ${this._annotationsOpen
          ? 'selected'
          : ''}"
        title="${this.i18n.t('descriptions')}"
        icon="ki ki-question"
        @click="${this.toggleAnnotations}"
      ></ki-icon-btn> `;
    // Link to provided URL if no boxitems available
    if (this.additionalAnnotationUrl)
      return html`<ki-icon-btn
        id="descriptionButton"
        title="${this.i18n.t('descriptions')}"
        icon="ki ki-question"
        @click="${this.linkToUrl}"
      ></ki-icon-btn> `;
    // Do not render Button if there is nothing to show or link to
    return html``;
  }

  _handleYearChange(e) {
    const { value } = e.target.selectedOption;
    const prevYear = (parseInt(value, 10) - 1).toString();
    const min =
      value && value !== 'YTD'
        ? dayjs(`${prevYear}${this.hydroYearStart}`, `YYYYMM-DD`).tz()
        : dayjs().tz().startOf('year');

    const max =
      value && value !== 'YTD'
        ? dayjs(`${value}${this.hydroYearEnd}`, `YYYYMM-DD`).tz()
        : dayjs().tz();
    this.drawSeries('P1Y', min.valueOf(), max.valueOf());
  }

  showTable() {
    this.renderRoot.querySelector('#table-wrapper').classList.toggle('hidden');
    this.renderRoot
      .querySelector('#table-clipboard-btn')
      .classList.toggle('hideButton');
    this._tableOpen = !this._tableOpen;
    this.chart && this.chart.reflow();
  }

  downloadGraph() {
    this.updateAnnotations();
    this.chart.update(
      {
        exporting: {
          chartOptions: {
            caption: {
              text: this._annotationsWidget.getAnnotationsAsHtml(),
            },
            legend: {
              enabled: false,
            },
          },
          filename: `${this.parameter_label}_${this.station.station_name}_${
            this.station.station_no
          }_${dayjs().tz().format('YYYYMMDD')}`,
          fallbackToExportServer: false,
          buttons: {
            contextButton: {
              enabled: false,
            },
          },
          sourceWidth: 1600,
          sourceHeight: 800,
          scale: 1,
        },
      },
      false,
    );
    this.chart.exportChartLocal();
  }

  async fetchData() {
    this.chart?.destroy();
    this.chart = null;

    const _files = {};
    this.station = this.station || (await this.api.getStation(this.stationId));

    const dataToFetch = [];

    this.station.timeseries.forEach(ts => {
      if (ts.station_parameter === this.station_parameter) {
        dataToFetch.push(ts.href);
      } else if (this.additionalTsFiles) {
        this.additionalTsFiles.forEach(addts => {
          if (ts.href.includes(addts)) {
            dataToFetch.push(ts.href);
          }
        });
      }
    });

    await Promise.all(
      dataToFetch.map(async href => {
        _files[last(href.split('/'))] = await this.api.getTsData(href);
      }),
    );
    this.files = _files;
    let selected = find(this.ranges, { selected: true });
    const yearcfg = find(this.ranges, range =>
      ['P1Y', 'PoR'].includes(range.value),
    );
    if (yearcfg && this.files[yearcfg.data]) {
      const tsdata = this.files[yearcfg.data][0].data;
      if (tsdata && tsdata[0]) {
        this.coverage = {
          max: last(tsdata)[0],
          min: tsdata[0][0],
        };
      }
    }
    selected = this._selectedPeriod || selected.value || this.ranges[0].value;
    if (!find(this.ranges, { value: selected })) {
      selected = this.ranges[0].value;
    }
    this._selectedPeriod = selected;
    this._setMaxValue(selected);
    this.createChart();
    this.ranges.forEach(range => {
      if (range.timeseries) {
        range.timeseries = range.timeseries.filter(
          ts =>
            !ts.ts_shortname ||
            this.files[range.data].some(
              tsfile => ts.ts_shortname === tsfile.ts_shortname,
            ),
        );
      }
    });

    this.drawSeries(selected);
    this.updateAnnotations();
    this.requestUpdate();
    return this.files;
  }

  _setMaxValue(period) {
    const cfg = find(this.ranges, { value: period });
    if (this.files[cfg.data]) {
      let currentMax = -Infinity;
      let currentMin = Infinity;
      this.files[cfg.data].forEach(ts => {
        ts.data.forEach(dp => {
          currentMax = Math.max(dp[1], currentMax);
          currentMin = Math.min(dp[1], currentMin);
        });
      });
      this.maxInitalValue = currentMax;
      this.minInitalValue = currentMin;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  _getLastValue(data) {
    return data && data[0] ? last(last(data)) : null;
  }

  // eslint-disable-next-line class-methods-use-this
  _splitEventValues(data) {
    const artificalTs = [];
    data.forEach(ts => {
      ts.data.forEach(dp =>
        artificalTs.push({
          ts_name: dayjs(dp[0]).tz().format('L'),
          data: [dp],
        }),
      );
    });
    return artificalTs;
  }

  _getThreshold(ts, i, key, ploptions) {
    const _id = `${key}_${i}PL`;
    const _thres = merge(
      merge(
        {
          id: _id,
          value: this._getLastValue(ts.data),
          width: 2,
          color: 'rgba(19, 19, 19, 1)',
          dashStyle: 'dash',
          zIndex: 111,
          label: {
            text: ts.ts_shortname
              ? ts.ts_shortname.replace('Cmd.', '')
              : ts.ts_name,
            align: 'right',
            y: 12,
            x: 0,
          },
        },
        ploptions,
      ),
      (this.threshholdMappings && this.threshholdMappings[ts.ts_shortname]) ||
        {},
    );
    this.threshholdMappings[ts.ts_shortname].seriesId = _id;
    return _thres;
  }

  // eslint-disable-next-line class-methods-use-this
  _getLevelData(data) {
    const lastValue = last(data);
    return data.length
      ? [
          [1, lastValue[1]],
          [2, lastValue[1]],
        ]
      : [];
  }

  _getThreshholds(
    graphOptions,
    list,
    key,
    label,
    ploptions = null,
    visible = true,
  ) {
    if (visible !== 'auto' && typeof visible === 'string') {
      visible = this.layerContext === visible;
    }

    let color;
    list.forEach((ts, i) => {
      const al = this._getThreshold(ts, i, key, ploptions);
      color = al.color || 'rgba(255,255,255,0)';
      graphOptions.series.push({
        label: {
          enabled: true,
          style: {
            color: 'rgb(19,19,19)',
            fontWeight: 'bold',
          },
        },
        dashStyle: al.dashStyle,
        width: al.width,
        xAxis: 'alarmAxis',
        marker: { enabled: false },
        enableMouseTracking: false,
        linkedTo: key,
        showInLegend: false,
        data: this._getLevelData(ts.data),
        description: al.label.description,
        shortName: al.label.shortText || al.label.text,
        name: al.label.text,
        color,
        id: al.id,
      });
    });
    graphOptions.series.push({
      name: label,
      color: list.length === 1 ? color : 'rgba(255,255,255,0)',
      id: key,
      visible,
      selected: visible,
    });
  }

  createGraphOptions() {
    const alarmFileName = this.alarms?.file;
    const alarmFile = alarmFileName
      ? compact(flatten(alarmFileName.map(file => this.files[file])))
      : null;
    const graphOptions = {
      chart: {
        //  zoomType: "x",
        animation: false,
        type: this.chartType,
        pinchType: undefined,
        zoomType: 'x',
        panning: {
          enabled: false,
        },
      },
      boost: {
        useGPUTranslations: true,
      },
      series: [],
      plotOptions: {
        area: {
          threshold: -Infinity,
        },
        column: {
          groupPadding: 0,
        },
        series: {
          animation: false,
          gapSize: this.gapSize !== undefined ? this.gapSize : 1,
          label: {
            enabled: false,
          },
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
      },
      yAxis: merge(
        {
          plotLines: [],
        },
        this.yAxis,
      ),
      xAxis: [
        {
          id: 'mainXAxis',
          type: 'datetime',
          ordinal: false,
          labels: {
            formatter() {
              return this.dateTimeLabelFormat === '%e. %b' &&
                this.tickPositionInfo.unitName === 'hour'
                ? `<b>${this.axis.defaultLabelFormatter.call(this)}</b>`
                : this.axis.defaultLabelFormatter.call(this);
            },
          },
        },
        {
          visible: false,
          min: 1,
          max: 2,
          id: 'alarmAxis',
        },
      ],
    };
    this.hasAlarms = false;

    // Read alarms/thresholds provided as station/timeseries attributes (e.g. Station, week.json, year.json)
    if (this.attributeThresholds) {
      this._addAttributeThresholds(graphOptions);
    }

    if (alarmFile && alarmFile.some(ts => ts.data.length > 0)) {
      let spare = true;
      this.alarms?.items?.forEach((item, i) => {
        const alarms = alarmFile.filter(ts =>
          item.tsList.includes(ts.ts_shortname),
        );
        if (alarms.some(ts => ts.data.length)) {
          let { active } = item;
          if (item.active === 'auto') {
            if (alarms[0].data[0][1] > this.maxInitalValue) {
              active = false;
            } else {
              active = true;
            }

            if (!active && spare) {
              active = true;
              spare = false;
            }
          } else if (item.active === 'automin') {
            if (alarms[0].data[0][1] < this.minInitalValue) {
              active = false;
            } else {
              active = true;
            }

            if (!active && spare) {
              active = true;
              spare = false;
            }
          }
          this._getThreshholds(
            graphOptions,
            alarms,
            `alerts${i}_`,
            item.label || this.i18n.t('alarmlevels'),
            null,
            active,
          );
          this.hasAlarms = true;
        }
      });
    }

    if (this.files[this.events]) {
      const defaultPlOptions = {
        width: 2,
        color: 'rgba(0, 86, 160, 1)',
        dashStyle: 'DashDot',
        label: {
          y: 12,
          x: 0,
        },
      };
      this._getThreshholds(
        graphOptions,
        this._splitEventValues(this.files[this.events]),
        'events_',
        'HW-Ereignisse',
        defaultPlOptions,
        false,
      );
    }
    return graphOptions;
  }

  // Searches through available data structures for attributes
  _findFile(fielditem) {
    // Check for station attributes (e.g. ww-me)
    if (this.station[fielditem.field]) return this.station;
    // Check for timeseries attributes (e.g. ww-brw))
    // Specify files to look at in app.json -> options -> thresholdFiles[]
    const res = this.thresholdFiles.map(_file => {
      if (this.files && this.files[_file]) return _file;
      return null;
    });
    if (res) return first(this.files[first(res)]);
    // Nothing found
    return undefined;
  }

  _addAttributeThresholds(graphOptions) {
    const _thresholds = [];
    this.attributeThresholds.forEach(item => {
      // Find desired field in files
      const _file = this._findFile(item);
      if (
        _file &&
        _file[item.field] &&
        _file[item.field] !== '' &&
        !Number.isNaN(_file[item.field])
      ) {
        _thresholds.push({
          ts_shortname: item.field,
          data: [[new Date().toISOString(), parseFloat(_file[item.field])]],
          label: item.label,
          color: item.color,
          visible: item.visible,
        });
      }
    });
    if (_thresholds) {
      _thresholds.forEach((thres, i) => {
        graphOptions.series.push({
          xAxis: 'alarmAxis',
          marker: { enabled: false },
          enableMouseTracking: false,
          name: thres.label,
          label: {
            text: thres.label,
            enabled: true,
          },
          color: thres.color || 'rgba(255,255,255,0)',
          dashStyle: 'dash',
          id: `thres_${i}`,
          visible: thres.visible || true,
          selected: thres.visible || true,
          data: this._getLevelData(thres.data),
        });
      });
      this.hasAlarms = true;
    }
  }

  _getExtremes(attr) {
    if (typeof this[attr] === 'number') {
      return this[attr];
    }
    if (this[attr] && this.__station[this[attr]]) {
      return this.__station[this[attr]]
        ? Number(this.__station[this[attr]])
        : null;
    }
    return null;
  }

  defaultOptions() {
    const timezoneOffset = -dayjs
      .tz(
        dayjs().tz(),
        dayjs.defaultZone ? dayjs.defaultZone.name : dayjs.tz.guess(),
      )
      .utcOffset();

    const conf = merge(
      {
        title: {
          text: '',
        },
        subtitle: {
          text: this.subTitle || '',
          floating: true,
        },
        credits: this.creditOptions,
        events: {},
        exporting: {
          chartOptions: {
            title: {
              text: this.graphTitle
                ? template(this.graphTitle, this.station)
                : '',
            },
            subtitle: {
              text: `${this.i18n.t('station_no')}: ${
                this.station.station_no
              } | ${this.i18n.t('request_at')} ${dayjs().tz().format('LLLL')} ${
                this._internetBemerkungHtml
              } ${this._adminBemerkungHtml}`,
            },
          },
          filename: `${this.parameter_label}_${this.station.station_name}_${
            this.station.station_no
          }_${dayjs().tz().format('YYYYMMDD_kkmmss')}`,
          fallbackToExportServer: false,
          buttons: {
            contextButton: {
              enabled: false,
            },
          },
          sourceWidth: 1600,
          sourceHeight: 800,
          scale: 1,
        },
        navigator: {
          enabled: false,
        },
        rangeSelector: {
          enabled: false,
        },
        scrollbar: {
          enabled: false,
        },
        navigation: {
          enabled: false,
        },
        plotOptions: {
          series: {
            //  gapSize: 1,
            dataGrouping: {
              enabled: false,
            },
            showCheckbox: ViewPort.size !== SM,
            events: {
              checkboxClick(event) {
                event.preventDefault();
                if (event.checked) {
                  this.show();
                } else {
                  this.hide();
                }
              },
              legendItemClick(event) {
                event.preventDefault();
                if (this.visible) {
                  if (this.checkbox) {
                    this.checkbox.checked = false;
                  }
                  this.hide();
                } else {
                  if (this.checkbox) {
                    this.checkbox.checked = true;
                  }
                  this.show();
                }
              },
            },
          },
        },
        legend: this.legendOptions,
        tooltip: {
          shared: true,
          split: false,
        },
        time: {
          // eslint-disable-next-line no-unused-vars
          getTimezoneOffset() {
            return timezoneOffset;
          },
          //    dayjs: dayjs,
          //     timezone: dayjs.defaultZone ? dayjs.defaultZone.name : dayjs.guess(),
          //  useUTC: false
        },
        yAxis: {
          reversed: this.yAxisReverse || false,
          minRange: this._getExtremes('yAxisMinRange'),
          softMax: this._getExtremes('yAxisMax'),
          softMin: this._getExtremes('yAxisMin'),
          opposite: false,
          title: {
            text: this._yAxisLabel,
          },
        },
        series: [],
      },
      this.createGraphOptions(),
    );
    return conf;
  }
}

/* eslint-disable no-return-assign */
/* eslint-disable max-classes-per-file */
import { filter, countBy, pickBy, every, orderBy, get } from 'lodash-es';
import { getConfig } from '@kisters/wcp-base/app';

import dayjs from '@kisters/wcp-base/common/dayjsext';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  navigateTo,
  getRouteOptionsAndParams,
} from '@kisters/wcp-base/components';
import {
  localStorageMixin,
  responsiveMixin,
  i18nMixin,
  getQueryObject,
  queryParamMixin,
  ViewPort,
  SM,
} from '@kisters/wcp-base/decorators';
import {
  LoaderMixin,
  Mix,
  PropertyDefaultValue,
} from '@kisters/wcp-base/common';

import '@kisters/ts-graph/ts-graph-viewer';

import LegendFactory from '@kisters/wcp-water/components/ki-station-map/LegendFactory';
import Classifier from '@kisters/wcp-water/components/ki-station-map/Classifier';
import { StatusIcon } from '@kisters/wcp-water/components/ki-status-icon/ki-status-icon';
import { getTrendArrow } from '@kisters/wcp-water/common/base';

import { html, LitElement } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import '@ui5/webcomponents/dist/Toast';
import '@ui5/webcomponents/dist/MessageStrip';
import '@ui5/webcomponents/dist/Dialog';
import '@ui5/webcomponents/dist/Table';
import '@ui5/webcomponents/dist/TableColumn';
import '@ui5/webcomponents/dist/TableRow';
import '@ui5/webcomponents/dist/TableCell';

import { getCsvDelimiter } from '@kisters/wcp-base/components/ki-app/services/ki-app-config-service';
import { WiskiTsTransformation } from '../../util';

import { getCurrentApi } from '../../api/API';

import nls from '../../locales/index';
import style from './wwp-overview.css';

import '../ww-dashboard/ww-simple-dashboard';

import { reproject, registerProjections } from '../../common/maputils';
import '../../components/ki-responsive-table/ki-responsive-table';

import '../../components/ki-wcp-wwp-filter/ki-wcp-wwp-filter';
import '../../components/common/ww-map-popup';
import '../../components/common/ww-map-popup-new';
import '../../components/common/ww-map-popup-base';
import '../../components/common/ww-map-popup-precip';
import '../../components/common/ww-map-popup-dynamic';

import '../../components/ki-wwp-graph/ki-wwp-sparkline';
import { SortedBy } from './SortedBy';
import { KiwisGraphService } from '../../services';

@customElement('ww-overview')
export default class WwOverview extends Mix(
  LitElement,
  LoaderMixin,
  PropertyDefaultValue,
  [
    localStorageMixin,
    { targetProperty: '__favouriteStationIds', typeParam: Array },
  ],
  [
    queryParamMixin,
    { targetProperty: 'viewSelection', selector: 'mode', defaultValue: 'map' },
  ],
  [
    queryParamMixin,
    {
      targetProperty: 'filterContent',
      selector: 'filter',
      defaultValue: '{}',
    },
  ],
  [
    queryParamMixin,
    {
      targetProperty: 'searchContent',
      selector: 'search',
      defaultValue: '',
    },
  ],
  responsiveMixin,
  [i18nMixin, { nls }],
) {
  @query('#map')
  stationMap;

  static styles = style;

  static get properties() {
    return {
      strictSearch: { type: Boolean },
      layersToggleAll: { type: Boolean },
      layersTreeList: { type: Boolean },
      layerName: { type: String },
      showCsvExport: { type: Boolean },
      _dataLayer: { type: Object },
      quickFilters: { type: Array, default: [] },
      homeExtent: { type: Array, default: [] },
      positionProjections: { type: Array, default: [] },
      _legends: { type: Array, default: [] },
      __selectedStations: { type: Array, default: [] },
      displayStationsInLayerControl: {
        type: Boolean,
        default: true,
        attribute: false,
      },
      _view: {
        type: Object,
        default: {
          projection: 'EPSG:3857',
          zoom: 8,
        },
        attribute: false,
      },
      layerGroups: {
        type: Array,
        default: [
          'Layers',
          'Information',
          { name: 'Backgrounds', type: 'radio' },
        ],
        attribute: false,
      },
    };
  }

  layersToggleAll = false;

  layersTreeList = false;

  _firstWWVisit = 'yes';

  _enableAutoZoom = false;

  tzFormat = 'zzz';

  zoomReset = false;

  refreshTimer = true;

  _filterContent = '{}';

  currentStationExtent = null;

  mapsLoaded = false;

  /** Toolbar Graph Button  */
  enableMultiSelectGraph = false;

  /** Toolbar Table Button */
  enableMultiSelectTable = false;

  @property({ type: Boolean })
  hideFavoriteButton = false;

  /** Table selection option */
  @property({ type: Boolean })
  showSelectedStationTable = false;

  @property({ type: Boolean })
  showGraphDialog = false;

  /** Table selection option */
  @property({ type: Boolean })
  selectionActive = false;

  __switcherOptions: Array<any> = [
    { label: 'Map', value: 'map', icon: 'ki ki-map' },
    { label: 'Table', value: 'table', icon: 'ki ki-table' },
    { label: 'Übersicht', value: 'overview', icon: 'ki ki-widgets2' },
  ];

  set switcherOptions(value: Array<any>) {
    this.__switcherOptions = value;
    this.requestUpdate('_switcherOptions');
  }

  // eslint-disable-next-line class-methods-use-this
  get service() {
    const appconfig = getConfig();
    return new KiwisGraphService(
      `${appconfig.kiwis.basePath}?datasource=${
        appconfig.kiwis.datasource || 0
      }`,
    );
  }

  get filterContent() {
    return this._filterContent;
  }

  set filterContent(value) {
    this._filterContent = value;
    this.requestUpdate();
  }

  // eslint-disable-next-line no-undef
  cachePrefix = __APP_NAME__;

  // eslint-disable-next-line no-undef
  version = __APP_VERSION__;

  _searchContent = '';

  get searchContent() {
    return this._searchContent;
  }

  set searchContent(value) {
    this._searchContent = value;
    if (
      this.stationMap &&
      (!this._searchContent || this._searchContent.length === 0)
    ) {
      console.log(this.homeExtent);
      this.stationMap
        .getView()
        .fit(ViewPort.size === SM ? this.homeExtentMobile : this.homeExtent, {
          duration: this._enableAutoZoom ? 500 : 0,
        });
    }
    this.requestUpdate();
  }

  _viewSelection: 'map' | 'table' | 'overview' = 'map';

  api = getCurrentApi();

  get viewSelection() {
    return this._viewSelection;
  }

  set viewSelection(value) {
    this._viewSelection = value;
    this.requestUpdate();
  }

  ___favouriteStationIds = [];

  get __favouriteStationIds() {
    return this.___favouriteStationIds;
  }

  set __favouriteStationIds(value) {
    this.___favouriteStationIds = value;
    this.requestUpdate();
  }

  get searchBox() {
    return getQueryObject(this, '#searchbox');
  }

  overViewLayer = {
    layer: [],
  };

  connectedCallback() {
    if (super.connectedCallback) super.connectedCallback();
    this.addEventListener('toggledPopupColumnHider', this._toggleLegend);

    this._loader._$loaderCount = 1;
    this.refresh = setInterval(() => {
      if (this.refreshTimer) {
        this.getDataLayer(true);
      }
    }, 5 * 60000);
    this._firstWWVisit = sessionStorage.getItem('firstWWVisit');
  }

  disconnectedCallback() {
    if (super.disconnectedCallback) super.disconnectedCallback();
    this.removeEventListener('toggledPopupColumnHider', this._toggleLegend);
    clearInterval(this.refresh);
  }

  _toggleLegend(e) {
    const l = this.shadowRoot.querySelector('#overview-legend');
    if (l && e.detail?.visible === true) l.collapsed = true;
  }

  exportTable() {
    const table = getQueryObject(this, 'ki-responsive-table');

    if (table) {
      navigator.clipboard.writeText(table.toCSV(getCsvDelimiter())).then(
        () => {
          getQueryObject(this, '#DownloadSuccessToast').show();
        },
        () => {
          getQueryObject(this, '#DownloadFailureToast').show();
        },
      );
    } else {
      getQueryObject(this, '#DownloadNoTableToast').show();
    }
  }

  get _switcherOptions() {
    return this.__switcherOptions.map(tab => ({
      label: this.i18n.t(tab.label),
      value: tab.value,
      icon: tab.icon,
    }));
  }

  // eslint-disable-next-line class-methods-use-this
  get localStorageId() {
    return `wwp-favorites-ids`;
  }

  /**
   * Create handle during rendering
   * Pass handle to table to avoid overlapping of menu elements over popup
   */
  initPopupContainer() {
    const elem = document.createElement('div');
    elem.id = 'popupContainer';
    this.popupContainerHandle = elem;
    return html`${elem}`;
  }

  // Vaadin Router Method that gets called after this component is called
  // eslint-disable-next-line no-unused-vars
  onAfterEnter(location) {
    this.filterContent = '{}';
    const params = getRouteOptionsAndParams(location, ['layerName']);
    this.layerName = params.layerName;
    Object.assign(this, params.options);
  }

  // eslint-disable-next-line class-methods-use-this
  additionalContent() {
    return html``;
  }

  // eslint-disable-next-line class-methods-use-this
  parseData(data) {
    return data;
  }

  render() {
    // eslint-disable-next-line wc/no-self-class
    this.classList.toggle('table', this.viewSelection === 'table');
    // eslint-disable-next-line wc/no-self-class
    this.classList.toggle('overview', this.viewSelection === 'overview');
    // TODO rename ki-station-layer to ki-maker-layer or value layer ?
    // language=html
    if (this.currentLayer !== this.layerName) {
      this.getDataLayer();
    }
    return (
      this._renderLoader() ||
      html`
        ${this.additionalContent()} ${this.initPopupContainer()}
        ${this.showGraphDialog ? this.renderMultiGraphDialog() : html``}
        ${this.showSelectedStationTable
          ? this.renderSelectedStationTable()
          : html``}
        <ki-searchbox
          placeholder="${this.i18n.t('stationsearch')}"
          .value="${this.searchContent}"
          @search="${e => {
            this._enableAutoZoom = true;
            this.searchContent = e.detail.value;
          }}"
        >
        </ki-searchbox>
        ${this.showCsvExport && this.viewSelection === 'table'
          ? this.renderExportButton()
          : html``}
        <ki-wcp-wwp-filter
          .hideFavorite="${this.hideFavoriteButton}"
          .favorites="${this.__favouriteStationIds}"
          .activeFilter="${this.filterContent}"
          .allStations="${this._dataLayer.data}"
          .stations="${this.filteredStations}"
          .filters="${this.quickFilters}"
          @change="${e => {
            this._enableAutoZoom = true;
            this.filterContent = JSON.stringify(e.detail.value);
          }}"
        >
        </ki-wcp-wwp-filter>
        <ki-switcher
          id="map-table-switcher"
          .options="${this._switcherOptions}"
          .value="${this.viewSelection}"
          @changed="${e => {
            this._enableAutoZoom = true;
            this.viewSelection = e.detail.value;
          }}"
        ></ki-switcher>
        <ki-stack class="slide-left" .selected="${this.viewSelection}">
          <div class="station-list" key="table">
            ${this.viewSelection === 'table' ? this.renderTable() : html``}
          </div>
          <div class="map-container" key="map">
            ${this.viewSelection === 'map' ? this.renderMap() : html``}
          </div>
          <div class="overview-container" key="overview">
            ${this.viewSelection === 'overview'
              ? this.renderOverview()
              : html``}
          </div>
        </ki-stack>
        <ki-legend
          closeable
          .options="${this.legendOptions}"
          @changed="${this.legendChanged}"
          id="overview-legend"
        ></ki-legend>
        <div class="lastUpdate" title="Version: ${this.version}">
          ${this.mapLastSyncedLabel
            ? this.mapLastSyncedLabel
            : this.i18n.t('lastupdated')}:
          ${dayjs.tz(this._dataLayer.creationDateInMillis).format('L LT')}
          ${this.__getTimezone()}
        </div>
        <ui5-message-strip
          id="selectedStations"
          hide-icon
          style="display:${this.__selectedStations?.length ? 'flex' : 'none'}"
          @close="${() => {
            this.unselectStations();
          }}"
          design="Information"
        >
          <span
            >${this.__selectedStations.length}
            ${this.i18n.t('OVERVIEW.STATIONSSELECTED')}</span
          >
          <ki-icon-btn
            style="display: inline-block;width: 30px;height: 30px;line-height: 30px;margin-left: 20px;"
            class="ripple"
            icon="ki ki-star"
            title="${this.i18n.t('OVERVIEW.ADDFAVORITES')}"
            @click="${this._addToFavorites}"
          ></ki-icon-btn>

          ${this.enableMultiSelectGraph
            ? html` <ki-icon-btn
                style="display: inline-block;width: 30px;height: 30px;line-height: 30px;margin-left: 5px;"
                class="ripple ${this.__selectedStations.length > 10
                  ? 'disabled'
                  : ''}"
                icon="ki ki-time-series"
                title="${this.i18n.t('OVERVIEW.OPENTS')}"
                @click="${() => (this.showGraphDialog = true)}"
              ></ki-icon-btn>`
            : html``}
          ${this.enableMultiSelectTable
            ? html` <ki-icon-btn
                style="display: inline-block;width: 30px;height: 30px;line-height: 30px;margin-left: 5px;"
                class="ripple"
                icon="ki ki-table"
                title="${this.i18n.t('OVERVIEW.SHOWINTABLE')}"
                @click="${() => (this.showSelectedStationTable = true)}"
              ></ki-icon-btn>`
            : ``}
        </ui5-message-strip>
      `
    );
  }

  _addToFavorites() {
    this.__favouriteStationIds = [
      ...this.__favouriteStationIds,
      ...this.__selectedStations,
    ];
  }

  __getTimezone() {
    if (this.tzFormat) {
      return this.i18n.exists(
        encodeURIComponent(dayjs().tz().format(this.tzFormat)),
      )
        ? this.i18n.t(encodeURIComponent(dayjs().tz().format(this.tzFormat)))
        : dayjs().tz().format(this.tzFormat);
    }
    return '';
  }

  renderExportButton() {
    return html`<ki-icon-btn
        class="ripple"
        id="copyToClipboardButton"
        icon="ki ki-clipboard"
        title="Copy table to clipboard"
        @click="${this.exportTable}"
      ></ki-icon-btn>
      <ui5-toast id="DownloadSuccessToast" class="success-toast"
        >${this.i18n.t('copyTableMessage')}</ui5-toast
      >
      <ui5-toast id="DownloadNoTableToast" class="failure-toast"
        >${this.i18n.t('copyTableNoTableMessage')}</ui5-toast
      >
      <ui5-toast id="DownloadFailureToast" class="failure-toast"
        >${this.i18n.t('copyTableFailureMessage')}</ui5-toast
      >`;
  }

  get filteredStations() {
    const regex = new RegExp(
      `${
        this.strictSearch
          ? `^${this.searchContent.replaceAll('*', '.')}`
          : this.searchContent.replaceAll('*', '.')
      }`,
      'i',
    );
    let filterOptions = JSON.parse(this.filterContent);

    if (filterOptions && this._dataLayer.config.filter) {
      filterOptions = pickBy(filterOptions, (value, key) =>
        this._dataLayer.config.filter.some(
          f => f.field === key || key === 'fav',
        ),
      );
    }

    let searchData = this._dataLayer.data?.filter(
      s =>
        regex.test(s.station_longname || s.station_name) ||
        regex.test(s.station_no),
    );
    if (filterOptions.fav) {
      searchData = searchData.filter(s =>
        this.__favouriteStationIds.includes(s.station_id),
      );
    }

    delete filterOptions.fav;
    return filter(searchData, obj =>
      every(filterOptions, (value, key) => {
        const val_ = get(obj, key);
        if (Array.isArray(val_)) {
          const valueIsArray = Array.isArray(value);
          return valueIsArray
            ? value
                .map(filterVal => val_.includes(filterVal))
                .reduce((a, b) => a || b)
            : val_.includes(value);
        }
        return Array.isArray(value) ? value.includes(val_) : val_ === value;
      }),
    ).filter(s => {
      const tag = s.__tag;
      if (tag && this._legends.length > 0) {
        return tag.split('|').every(t => this._legends.indexOf(t) !== -1);
      }
      return true;
    });
  }

  get quickFilters() {
    return this._dataLayer.config?.filter || [];
  }

  get popup() {
    return this._dataLayer.config?.popup || 'ww-map-popup-zarr';
  }

  get sortedBy(): Array<SortedBy> {
    return this._dataLayer.config?.sortedBy;
  }

  /** Filtered List of Stations selected with the multiSelect-Feature */
  get multiSelectedTsList() {
    return WiskiTsTransformation.normalizeMultiTsArray(
      this.filteredStations.filter(series =>
        this.__selectedStations.includes(series.station_id),
      ),
    );
  }

  get selectedIds() {
    return this.multiSelectedTsList.map(series => series.id);
  }

  // eslint-disable-next-line class-methods-use-this
  renderMultiGraphDialog() {
    return html`<ui5-dialog
      initial-focus="multiGraph"
      open
      id="multiGraphDialog"
      @before-close=${() => {
        this.showMultiGraphDialog = false;
      }}
      ><ts-graph-viewer
        noactions
        .tsList="${this.multiSelectedTsList}"
        .selectedIds="${this.selectedIds}"
        .service="${this.service}"
        .periods="${this.periods}"
        closeable
        @closeGraph="${() => (this.showGraphDialog = false)}"
        .renderHeaderTitle="${() => html`${this.layerName}`}"
        showStationNamesInLegend
        useDefaultColors
        id="multiGraph"
      ></ts-graph-viewer>
    </ui5-dialog>`;
  }

  renderSelectedStationTable() {
    const _stations = this.filteredStations.filter(s =>
      this.__selectedStations.includes(s.station_id),
    );
    if (_stations.length && this.showSelectedStationTable) {
      return html`
        <ui5-dialog
          id="selectedStationTableDialog"
          open
          @after-close="${(this.showSelectedStationTable = false)}"
        >
          <ki-responsive-table
            @selection="${this.selectStations}"
            .selection="${this.__selectedStations}"
            .data="${_stations}"
            idproperty="station_id"
            .layerAlias="${this.layerName}"
            .infoTag="${this.popup}"
            sort="${this.sortedBy
              ? JSON.stringify(this.sortedBy)
              : '[{"field":"station_name", "ascending":true}]'}"
            .columns="${this._dataLayer.config.columns}"
            .tablePopup="${this._dataLayer.config.tablePopup}"
            .formatters="${this.formatters}"
            .popupContainer=${this.popupContainerHandle}
            @row-click="${this.showStation}"
          ></ki-responsive-table>
          <div
            slot="footer"
            style="display: flex; justify-content: flex-end; width: 100%; padding-top: 5px;"
          >
            <ui5-button
              id="error-close"
              @click="${() =>
                this.shadowRoot
                  .querySelector('#selectedStationTableDialog')
                  .close()}"
              >Schließen</ui5-button
            >
          </div>
        </ui5-dialog>
      `;
    }
    return html``;
  }

  // Presort by
  renderTable() {
    return html`<ki-responsive-table
      @selection="${this.selectStations}"
      .selection="${this.__selectedStations}"
      ?selectionActive="${this.selectionActive}"
      .data="${this._initialOrder(this.filteredStations)}"
      idproperty="station_id"
      .layerAlias="${this.layerName}"
      .infoTag="${this.popup}"
      sort="${this.sortedBy
        ? JSON.stringify(this.sortedBy)
        : '[{"field":"station_name", "ascending":true}]'}"
      .columns="${this._dataLayer.config.columns}"
      .tablePopup="${this._dataLayer.config.tablePopup}"
      .formatters="${this.formatters}"
      .popupContainer=${this.popupContainerHandle}
      @row-click="${this.showStation}"
    ></ki-responsive-table>`;
  }

  _initialOrder(filteredStations: Array<object>) {
    // Transform provided dataset from layercfg
    const fields: Array<string> = []; // field name
    const orders: Array<'asc' | 'desc'> = [];
    if (this.sortedBy && Array.isArray(this.sortedBy)) {
      this.sortedBy.forEach(_sort => {
        _sort.ascending ? orders.push('asc') : orders.push('desc');
        _sort.sortBy ? fields.push(_sort.sortBy) : fields.push(_sort.field);
      });
      return orderBy(filteredStations, fields, orders);
    }
    return filteredStations;
  }

  renderOverview() {
    return html`<ww-simple-dashboard
      stations
      .layerAlias="${this.layerName}"
      .popup="${this.popup}"
      .stations="${this.filteredStations}"
    ></ww-simple-dashboard>`;
  }

  renderMap() {
    return html`<ki-station-map
      id="map"
      .view="${this._view}"
      .layers="${this.mapConfig?.layers || []}"
      persistancetimeout="3600"
      cachePrefix="${this.cachePrefix}"
    >
      ${this._dataLayer.config.popupControl
        ? html`<ki-popup-control></ki-popup-control>`
        : html``}

      <ki-stations-layer
        .displayStationLabelsOnStart="${this.displayStationLabelsOnStart}"
        .displayInLayerControl="${this.displayStationsInLayerControl}"
        .infoTag="${this.popup}"
        .tagMarkers="${this.legendFactory.markers}"
        .layerAlias="${this.layerName}"
        .labelTemplate="${this._dataLayer.config?.labelTemplate}"
        .trendKey=${this._dataLayer.config?.trendKey}
        .tagSelectedMarkers="${this.legendFactory.selectedMarkers}"
        .config="${this._dataLayer.config}"
        .stations="${this.filteredStations}"
        .dynamicMarkerSize="${this._dataLayer.config?.dynamicMarkerSize}"
        .selected="${this.__selectedStations}"
        multiSelectable
        @selection="${this.selectStations}"
        @station-click="${this.showStation}"
        @sourceChange="${e => {
          if (
            this.zoomReset ||
            this._enableAutoZoom ||
            this._firstWWVisit !== 'no'
          ) {
            if ((this._searchContent?.length ?? 0) > 0) {
              this.stationMap.zoomTo(
                e.detail.extent,
                this._enableAutoZoom ? 500 : 0,
                this.extentBuffer,
              );
            }
            this._firstWWVisit = 'no';
            sessionStorage.setItem('firstWWVisit', 'no');
          }
        }}"
      >
      </ki-stations-layer>
      <ki-layers-control
        .treeList="${this.layersTreeList}"
        .toggleAll="${this.layersToggleAll}"
        .context="${this.layerName}"
        .i18n=${this.i18n}
        .groups="${this.layerGroups}"
        cachePrefix="${this.cachePrefix}"
      ></ki-layers-control>
      <ki-station-map-zoom-control
        style="right:20px;left:auto;"
        label="map zoom"
        .homeExtent="${ViewPort.size === SM
          ? this.homeExtentMobile
          : this.homeExtent}"
      ></ki-station-map-zoom-control>
      <ki-station-map-position-scale-control
        .projections="${this.positionProjections}"
        label="position and scale"
      ></ki-station-map-position-scale-control>
      <ki-station-map-overview-control
        label="overview"
        .layerOptions="${this.mapConfig?.overViewLayer || { layer: [] }}"
      ></ki-station-map-overview-control>
    </ki-station-map>`;
  }

  unselectStations() {
    this.__selectedStations = [];
  }

  selectStations(e) {
    this.__selectedStations = e.detail.selection;
  }

  // eslint-disable-next-line class-methods-use-this
  showStation(e) {
    // TODO provide route-link ,  route.linkTo
    // TODO site_no ?
    const station = e.detail.data;
    const label = station.station_longname || station.station_name;
    if (station.external && station.projectName)
      /** Go to project detail page, see ww-worldmap
       *  station_id ~ project_id
       * */
      window.open(
        `#/overview/${this.layerName}/${station.station_id}`,
        '_self',
      );
    else if (station.external && station.url) {
      window.open(station.url, '_new');
    } else if (station.URLEXTERN) {
      window.open(station.URLEXTERN, '_new');
    } else {
      let navigationPath = `/overview/${this.layerName}/station/${
        station.station_id
      }/${encodeURIComponent(label)}`;

      let detailPaths =
        this.detailsPaths?.filter(path => path.layerName === this.layerName) ??
        [];

      const anyHits = detailPaths.filter(path => {
        if (!path.anyParameters) {
          return false;
        }

        for (const param of path.anyParameters) {
          if (station.ts_values[param]) {
            return true;
          }
        }
        return false;
      });

      const allHits = detailPaths.filter(path => {
        if (!path.parameters) {
          return false;
        }

        for (const param of path.parameters) {
          if (!station.ts_values[param]) {
            return false;
          }
        }
        return true;
      });

      detailPaths = detailPaths.filter(
        path => !path.anyParameters && !path.parameters,
      );

      let detailPath;

      /* eslint-disable prefer-destructuring */
      if (anyHits.length > 0) {
        detailPath = anyHits[0];
      } else if (allHits.length > 0) {
        detailPath = allHits[0];
      } else if (detailPaths.length > 0) {
        detailPath = detailPaths[0];
      }
      /* eslint-enable prefer-destructuring */

      if (detailPath) {
        navigationPath = `${navigationPath}/${detailPath.detailsPath}`;
      }

      navigateTo(`${navigationPath}?mode=${this.viewSelection}`);
    }
  }

  async getDataLayer(sameLayer: boolean = false) {
    if (!this.mapsLoaded) {
      const mapConfig = await this.api.getMapConfig();
      this.mapConfig = mapConfig;
    }
    if (this.layerName) {
      this.currentLayer = this.layerName;

      let resp;
      try {
        resp = await this.api.getLayer(this.layerName);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(
          `error loading layer "${this.layerName}". Going back to start...`,
        );
        navigateTo('/');
        return;
      }

      if (!resp?.config) {
        // eslint-disable-next-line no-console
        console.warn(
          `layer "${this.layerName}" not found or has no config. Going back to start`,
        );
        navigateTo('/');
        return;
      }

      if (this.zoomReset && sessionStorage) {
        sessionStorage.removeItem(`${this.cachePrefix}-ki-station-map-zoom`);
      }

      resp.data = reproject(resp.data, this.mapConfig);
      // console.time('clientFilter');
      if (this.parameterFilterAttribute) {
        try {
          resp.data.forEach(station => {
            if (station[this.parameterFilterAttribute]) {
              const parFilter = JSON.parse(
                station[this.parameterFilterAttribute],
              );
              if (Array.isArray(parFilter) && parFilter.length !== 0) {
                Object.keys(station.ts_values).forEach(par => {
                  if (!parFilter.includes(par)) {
                    delete station.ts_values[par];
                  }
                });
              }
            }
          });
          resp.data = resp.data.filter(
            item => Object.keys(item.ts_values).length,
          );
        } catch (e) {
          console.error('invalid parameterFilterAttribute');
        }
      }

      resp.data.forEach(item => {
        if (item.ts_value) item.ts_value = parseFloat(item.ts_value);
        if (item.ts_values?.temp?.ts_value)
          item.ts_values.temp.ts_value = parseFloat(
            item.ts_values.temp.ts_value,
          );
      });
      // console.timeEnd('clientFilter');
      const data = new Classifier(resp.config.classification).classify(
        this.parseData(resp.data),
      );

      // FIXME: @Fabian please fix Dictionary type, see here: https://stackoverflow.com/questions/57497126/typescript-why-does-generic-dictionary-type-not-return-possibly-undefined-valu
      // eslint-disable-next-line no-undef
      let tagCount: Dictionary<number> | null = null;

      if (this.uniqueStations) {
        const seen = {};
        const uniq = data
          .map(dat => ({
            id: `${dat.station_id}${dat.__tag}`,
            station: dat.station_id,
            __tag: dat.__tag,
          }))
          // See here: https://eslint.org/docs/latest/rules/no-prototype-builtins
          .filter(item =>
            Object.prototype.hasOwnProperty.call(seen, item.id)
              ? false
              : (seen[item.id] = true),
          );

        tagCount =
          resp.config.classification.tagCount !== false
            ? countBy(uniq, '__tag')
            : null;
      } else {
        tagCount =
          resp.config.classification.tagCount !== false
            ? countBy(data, '__tag')
            : null;
      }
      this._loader._$loaderCount = 0;
      this._dataLayer = {
        config: resp.config,
        data,
        tagCount,
        creationDateInMillis: resp.creationDateInMillis,
      };
      if (!sameLayer) {
        this._legends = [];
      }
      if (!this.mapsLoaded) {
        registerProjections(this.mapConfig);
        this._view = this.mapConfig.view || this._view;
        this.homeExtent = this.mapConfig.homeExtent;
        this.homeExtentMobile =
          this.mapConfig.homeExtentMobile ?? this.mapConfig.homeExtent;
        this.positionProjections = this.mapConfig.positionProjections;
        this.displayStationsInLayerControl =
          this.mapConfig.displayStationsInLayerControl;
        this.mapsLoaded = true;
      }
      this.requestUpdate();
    }
  }

  get legendFactory() {
    // eslint-disable-next-line no-return-assign
    return (this._legendFactory ??= new LegendFactory(
      this._dataLayer.config.classification,
    ));
  }

  get legendOptions() {
    const opts = this.legendFactory.getLegendOptions();
    return [{ ...opts, tagCount: this._dataLayer.tagCount }];
  }

  get formatters() {
    const factory = this.legendFactory;
    return {
      status(val, locale, col) {
        return StatusIcon(factory.pngs[val], factory.labels[val], col);
      },
      info(val, locale, col) {
        // todo move into options.
        return val
          ? html`<div class="cell" style="text-align: center;">
              <ki-icon
                style="color:rgba(255,221,66,1);"
                title="${val}"
                icon="${col.icon || 'ki ki-info-circle'}"
              ></ki-icon>
            </div>`
          : html`<div></div> `;
      },
      trend(val) {
        const trend =
          val !== null && val !== undefined
            ? `transform: rotateZ(${getTrendArrow(val)}deg)`
            : 'display:none;';
        return html`<div class="cell">
          <ki-icon
            style="font-size:1.5em;${trend}"
            icon="ki ki-arrow-right"
          ></ki-icon>
        </div>`;
      },
      sparkline(val, opts, col, item) {
        const vals = opts.options.values
          .map(value => ({
            unit: item?.ts_values[item.mainParameter]?.ts_unitsymbol,
            name: item?.ts_values[item.mainParameter]
              ?.stationparameter_longname,
            value: item[value],
            date: item[value.replace('ts_value', 'timestamp')],
          }))
          .filter(f => f.value);
        return html`<ki-wwp-sparkline .data="${vals}"> </ki-wwp-sparkline>`;
      },
    };
  }

  legendChanged(e) {
    this._legends = e.detail.value;
  }
}

import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import {
  last,
  merge,
  find,
  orderBy,
  isSafeInteger,
  some,
  first,
} from 'lodash-es';
import {
  i18nMixin,
  responsiveMixin,
  ViewPort,
  SM,
  queryParamMixin,
} from '@kisters/wcp-base/decorators';
import '@kisters/wiski-web/components/ki-wwp-graph/ki-wwp-switcher-bottom';
import {
  LoaderMixin,
  template,
  copyTextToClipboard,
  Mix,
} from '@kisters/wcp-base/common';
import { getRouteOptionsAndParams } from '@kisters/wcp-base/components';
import dayjs from '@kisters/wcp-base/common/dayjsext';
import '@kisters/wiski-web/components/ki-wwp-graph/ki-wwp-ts-table';
import hclocales from '@kisters/wcp-water/common/highcharts-locales';
import Highcharts from 'highcharts/highstock';
import nodata from 'highcharts/modules/no-data-to-display';
import exporting from 'highcharts/modules/exporting';
import boost from 'highcharts/modules/boost';
import more from 'highcharts/highcharts-more';
import patternFill from 'highcharts/modules/pattern-fill';
import offlineexporting from 'highcharts/modules/offline-exporting';

import serieslabel from 'highcharts/modules/series-label';
import { getCurrentApi } from '@kisters/wiski-web/api/API';

import nls from '@kisters/wiski-web/locales/index';
import style from '@kisters/wiski-web/components/ki-wwp-graph/ki-wwp-graph-precip.css';
import LanuvTsAnnotations from '../lanuv-ts-annotations';

import '@ui5/webcomponents/dist/Button';
import '@ui5/webcomponents/dist/Select';
import '@ui5/webcomponents/dist/Option';
import { TsAnnotation } from '../lanuv-ts-annotations/TsAnnotation';

exporting(Highcharts);
patternFill(Highcharts);
offlineexporting(Highcharts);
nodata(Highcharts);

@customElement('ww-lanuv-graph-precip')
export default class WwLanuvGraphPrecip extends Mix(
  LitElement,
  LoaderMixin,
  responsiveMixin,
  [i18nMixin, { nls }],
  [
    queryParamMixin,
    {
      targetProperty: '_selectedPeriod',
      selector: 'period',
      defaultValue: '',
    },
  ],
) {
  // language=CSS
  static styles = [
    style,
    css`
      #annotation-wrapper {
        position: relative;
        overflow: auto;
        flex: 0 0 400px;
      }

      #annotation-wrapper.hidden {
        position: relative;
        overflow: auto;
        flex: 0 0 0;
      }

      lanuv-ts-annotations {
        position: absolute;
        height: 100%;
        width: 100%;
      }

      :host(.sm-screen) #table-wrapper,
      :host(.sm-screen) #annotation-wrapper {
        flex: 1 1 100%;
        background: white;
      }

      :host(.sm-screen) #table-wrapper.hidden,
      :host(.sm-screen) #annotation-wrapper.hidden {
        position: relative;
        overflow: auto;
        flex: 0 0 0;
      }

      .adminbemerkung {
        color: red;
        font-size: 0.65em;
      }
    `,
  ];

  highcharts = Highcharts;

  __selectedPeriod;

  @property({ type: Object })
  options = {};

  @property({ type: Boolean })
  miniGraph = false;

  @property({ type: String })
  stationId;

  @property({ type: String })
  adminStatus = '';

  @property({ type: String })
  adminBemerkung = '';

  @property({ type: Object })
  station;

  get _selectedPeriod() {
    return this.__selectedPeriod;
  }

  set _selectedPeriod(value) {
    this.__selectedPeriod = value;
    this.requestUpdate();
  }

  get files() {
    return this._files;
  }

  set files(files) {
    this._files = files;
    this.adminBemerkung = first(files['week.json'])?.AdminBemerkung || '';
    this.adminStatus = first(files['week.json'])?.AdminStatus || '';
  }

  constructor() {
    super();
    serieslabel(Highcharts);
    exporting(Highcharts);
    offlineexporting(Highcharts);
    nodata(Highcharts);
    more(Highcharts);
    boost(Highcharts);

    this._tableOpen = false;
    this._annotationsOpen = false;
  }

  async onAfterEnter(location) {
    const params = getRouteOptionsAndParams(location, [
      'layerName',
      'stationId',
      'stationLabel',
    ]);
    this.api = getCurrentApi();
    this.stationId = params.stationId;
    this.apppath = location.route;
    Object.assign(this, params.options);

  }

  firstUpdated() {
    if (!this.miniGraph) {
      this.refreshInterval = setInterval(() => {
        this.fetchData();
      }, 300000);
    }
    Object.assign(this, this.options);
    if (
      this.renderRoot.host.classList.contains('sm-screen') ||
      this.miniGraph
    ) {
      this.tableOpen = false;
    }
    this.api = getCurrentApi();
    this.fetchData();
  }

  getImageShare() {
    return this.api
      ? `${window.location.origin}${window.location.pathname.replace(
          /[^/]*$/,
          '',
        )}graph.html?stationId=${
          this.station.station_id
        }&component=ki-wwp-graph-precip-ext&key=${encodeURIComponent(
          this.apppath?.path,
        )}&period=${this._selectedPeriod}`
      : '';
  }

  connectedCallback() {
    if (super.connectedCallback) super.connectedCallback();
    if (this.renderRoot.host.classList.contains('sm-screen')) {
      this._tableOpen = false;
    }
    this.numberFormatter = new Intl.NumberFormat(
      this.i18n.language,
      this.numberFormat || {},
    );

    this._annotations = [];

    let resize;
    window.addEventListener('resize', () => {
      window.clearTimeout(resize);
      resize = setTimeout(() => {
        this.chart && this.chart.reflow();
      }, 220);
    });
  }

  formatnum(val) {
    return val === undefined || val === null || val === '' || Number.isNaN(val)
      ? ''
      : this.numberFormatter.format(val);
  }

  get tablecolumns() {
    return [
      {
        field: 'timestamp',
        label: this.timestampLabel || this.i18n.t('timestamp'),
        sortable: true,
        hideAble: false,
      },
      {
        field: 'value',
        label: this.yAxisLabel || this.i18n.t('precipmm'),
        sortable: false,
      },
    ];
  }

  createChart() {
    if (hclocales[this.i18n.language.substring(0, 2)]) {
      this.highcharts.setOptions({
        lang: hclocales[this.i18n.language.substring(0, 2)],
      });
    }
    this.chart = this.highcharts.chart(
      this.renderRoot.querySelector('#chart-node'),
      this.defaultOptions(),
      this.chartCreated.bind(this),
    );
  }

  chartCreated(chart) {
    const e = new CustomEvent('load', {
      detail: chart,
    });
    this.dispatchEvent(e);
  }

  updated(changedProperties) {
    if (changedProperties.get('stationId')) {
      //   console.log(this.stationId, changedProperties.get('stationId'))
      this.station = null;
      this.fetchData();
    }
    if (this.chart) {
      //   console.log("resize bfeore", this.renderRoot.querySelector('#contentnode').clientHeight, this.chart.chartHeight)
      this.chart.reflow();
      //    console.log("resize", this.renderRoot.querySelector('#contentnode').clientHeight, this.chart.chartHeight)
    }
  }

  disconnectedCallback() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
    }
  }

  drawSeries(period, min?, max?) {
    const cfg = find(this.ranges, { value: period });
    let _min = min;
    let _max = max;
    if (cfg.yearSelector) {
      _min = _min || dayjs().tz().startOf('year').valueOf();
      _max = _max || dayjs(this.coverage.max).tz().valueOf();
      this.renderRoot
        .querySelector('#yearselector')
        .classList.remove('hideButton');
    } else {
      this.renderRoot
        .querySelector('#yearselector')
        .classList.add('hideButton');
    }
    while (this.chart.series.length > 0) {
      this.chart.series[0].remove(false);
    }

    if (this.files[cfg.data]) {
      const minDate =
        _min || dayjs(_max).subtract(dayjs.duration(period)).valueOf();
      const maxDate = _max || dayjs().tz().valueOf();
      const xAxis = this.chart.get('mainXAxis');
      this.chart.zoomOut();

      /*  // Workaround for year view (avoid plotting of empty next month)
      if (cfg.value === 'P1Y')
        xAxis.setExtremes(
          minDate,
          max || dayjs().startOf('month'),
          false,
          false,
        ); 
      else */

      xAxis.setExtremes(minDate, maxDate, false, false);

      if (cfg.yAxisLabel) {
        this.yAxisLabel = cfg.yAxisLabel;
        this.chart.get('mainYAxis').setTitle({ text: cfg.yAxisLabel }, false);
      }

      this.chart.get('sumYAxis').update({ visible: !cfg.sumOnMainAxis }, false);

      const timeformat = cfg.dateFormat || '%d.%m.%Y %H:%M';
      let ts = find(this.files[cfg.data], { ts_shortname: cfg.ts });
      ts = ts || this.files[cfg.data][0];

      if (
        this.filterAccuracy &&
        ts.columns.indexOf('Aggregation Accuracy %') > -1
      ) {
        const { columns } = ts;
        const valueIndex = columns.indexOf('Value');
        const accIndex = columns.indexOf('Aggregation Accuracy %');
        ts.data.forEach(item => {
          if (item[accIndex] < this.filterAccuracy) {
            item[valueIndex] = null;
          }
        });
      }
      const tsdata = this._formatGraphData(ts.data, minDate, maxDate);
      const accData = tsdata;
      const tableData = orderBy(tsdata, 'x', ['desc']).map(item => ({
        timestamp: dayjs(item.x)
          .tz()
          .format(cfg.dateFormatTable || 'L LT'),
        value: this.formatnum(item.y),
      }));
      const table = this.shadowRoot.getElementById('dataTable');
      table.columns = this.tablecolumns;
      // eslint-disable-next-line no-multi-assign
      this.orderedTableData = table.data = tableData;
      table.data = this.orderedTableData;

      // Show Accuracy in tooltip
      this._addAccuracy(accData, timeformat);

      // Add bars
      this.chart.addSeries(
        {
          data: tsdata,
          name: cfg.tslabel || this.parameter_label,
          id: 'data',
          color: '#0056a0',
          selected: true,
          marker: { enabled: false },
          tooltip: merge(
            {
              valueDecimals: 1,
              valueSuffix: ` ${ts.ts_unitsymbol}`,
              xDateFormat: timeformat,
            },
            this.tooltipBar,
          ),
        },
        false,
      );

      // Add sum line
      this.chart.addSeries({
        data: this._formatGraphDataSum(
          ts.data,
          minDate,
          maxDate,
          cfg.resetSumAt,
        ),
        name: cfg.sumLabel || this.sumLabel || this.i18n.t('Sum line'),
        yAxis: cfg.sumOnMainAxis ? 'mainYAxis' : 'sumYAxis',
        type: 'line',
        step: 'left',
        marker: { enabled: false },
        color: 'rgb(170,70,67)',
        selected: true,
        tooltip: merge(
          {
            valueDecimals: 1,
            valueSuffix: ` ${ts.ts_unitsymbol}`,
            xDateFormat: timeformat,
          },
          this.tooltipSum,
        ),
      });
    }
    this.updateAnnotations(); // Annotations get extracted from rendered Legend
    this.requestUpdate();
  }

  updateAnnotations() {
    const result: Array<TsAnnotation> = [];

    // Add Range-specific annotations, see app.json -> options -> ranges -> annotations
    const r = find(
      this.getRangeOptions(),
      _r => _r.value === this._selectedPeriod,
    );
    if (r && r.annotations) {
      r.annotations.forEach(a => {
        if (typeof a === 'string')
          result.push({
            label: {
              text: a,
            },
            showInBox: true,
            exportAlways: true,
          } as TsAnnotation);
        else if (typeof a === 'object') {
          result.push({
            label: a,
            showInBox: true,
            exportAlways: true,
          } as TsAnnotation);
        }
      });
    }

    // Add Range-inidpendent annotations, see app.json -> optins -> annotations
    if (this.annotations)
      this.annotations.forEach(a => {
        if (typeof a === 'string')
          result.push({
            label: {
              text: a,
            },
            showInBox: true,
            exportAlways: true,
          } as TsAnnotation);
        else if (typeof a === 'object') {
          result.push({
            label: a,
            showInBox: true,
            exportAlways: true,
          } as TsAnnotation);
        }
      });
    this._annotations = result;

    // Update Component
    this._annotationsWidget = this.shadowRoot.querySelector('#annotations');
    this._annotationsWidget.updateAnnotations(result);
  }

  toggleAnnotations() {
    this._annotationsOpen = !this._annotationsOpen;
    this.updateAnnotations();
    this.requestUpdate();
  }

  getRangeOptions() {
    return this.ranges || [];
  }

  linkToUrl() {
    if (this.additionalAnnotationUrl) {
      console.log('Go to ...', this.additionalAnnotationUrl);
      window.open(this.additionalAnnotationUrl, '_blank');
    } else console.warn('No additionalAnnotationUrl provided');
  }

  render() {
    if (!this.station) {
      return html`<div>Loading...</div>`;
    }
    const title = this.graphTitle
      ? template(this.graphTitle, this.station)
      : '';
    return html` <div id="nav-node" class="${this.miniGraph ? 'hidden' : ''}">
        <div class="label" style="display: flex; flex-direction: column">
          <span>${title}</span> ${this._renderAdminStatus()}
        </div>
        <ki-wwp-switcher-bottom
          @changed="${e => {
            if (this.chart) {
              if (this._annotationsOpen) this._annotationsOpen = false;
              this.shadowRoot.querySelector('#descriptionButton').active =
                false;
              this._selectedPeriod = e.detail.value;
              this.drawSeries(e.detail.value);
            }
          }}"
          .options="${this.getRangeOptions()}"
          .value=${this._selectedPeriod}
          class="buttons"
        >
        </ki-wwp-switcher-bottom>
        <div class="actions">
          <ki-icon-btn
            class="ripple clipboard-btn ${this.imageShare ? '' : 'hideButton'}"
            title="${this.i18n.t('shareLink')}"
            icon="ki ki-share"
            ,
            id="link-btn"
            @click=${() => {
              const popup = this.renderRoot.querySelector('#download-popup');
              window.open(this.getImageShare(), '_blank');
              popup.visible ? popup.hide({}) : popup.show({});
            }}
          ></ki-icon-btn>
          <ki-popup
            for="link-btn"
            style="margin-right: 20px;"
            top="40px"
            left="-100"
            id="download-popup"
          >
            <div
              style="padding: 10px;font-size: 1.2em;border-bottom: 1px solid gray;display:flex;justify-content:space-between;align-items: center;"
            >
              ${this.i18n.t('embedGraph')}<ki-icon-btn
                title="schließen"
                @click="${() => {
                  this.renderRoot.querySelector('#download-popup').hide({});
                }}"
                style="margin:0;height: 20px;width: 20px;line-height: 20px;"
                icon="ki ki-times"
              ></ki-icon-btn>
            </div>
            <textarea
              disabled
              id="embeddedgraph"
              style="border: none;outline: none;resize:none;padding:10px;background:white;width: 90%;"
              rows="12"
            >
<iframe src="${this.getImageShare()}" style="width:80%;height:700px;border:0;"></iframe></textarea
            >
            <ui5-button
              @click="${() => {
                window.open(this.getImageShare(), '_blank');
              }}"
              title="${this.i18n.t('previewTitle')}"
              >${this.i18n.t('preview')}</ui5-button
            >
            <ui5-button
              @click="${() => {
                copyTextToClipboard(
                  this.renderRoot.querySelector('#embeddedgraph').value,
                );
              }}"
              title="${this.i18n.t('copyToClipboard')}"
              >${this.i18n.t('copy')}</ui5-button
            >
          </ki-popup>
          ${this._renderAnnotations()}
          <ki-icon-btn
            class="ripple"
            id="graph-download-button"
            title="${this.i18n.t('downloadGraph')}"
            icon="ki ki-download"
            @click="${this.downloadGraph}"
          ></ki-icon-btn>
          <ki-icon-btn
            class="ripple clipboard-btn ${this._tableOpen ? '' : 'hideButton'}"
            id="table-clipboard-btn"
            tooltip="${this.i18n.t('copyTableMessage')}"
            ,
            title="${this.i18n.t('copyTableTitle')}"
            icon="ki ki-clipboard"
            .value=${this.orderedTableData}
            @click=${() => {
              copyTextToClipboard(
                this.renderRoot
                  .querySelector('#dataTable')
                  .toCSV()
                  .replace(/(<([^>]+)>)/gi, ''),
              );
            }}
          ></ki-icon-btn>
          <ki-icon-btn
            toggle
            title="${this.i18n.t('tableTitle')}"
            icon="ki ki-table"
            .selected="${this._tableOpen}"
            @click="${this.showTable}"
          ></ki-icon-btn>
        </div>
      </div>
      <div class="contentnode">
        <div id="chart-wrapper">
          <ui5-select
            class="hideButton"
            id="yearselector"
            @change="${this._handleYearChange}"
          >
            ${this._years.map(
              year =>
                html`<ui5-option
                  ?selected=${year.value === 'YTD'}
                  value="${year.value}"
                  >${year.label}</ui5-option
                >`,
            )}
          </ui5-select>
          <div id="chart-node"></div>
        </div>
        <div id="table-wrapper" class="${this._tableOpen ? '' : 'hidden'}">
          <ki-wwp-ts-table
            id="dataTable"
            .columns="${this.tablecolumns}"
            sort='[{"field":"timestamp", "ascending":false}]'
            idproperty="timestamp"
          >
          </ki-wwp-ts-table>
        </div>
        <div
          id="annotation-wrapper"
          class="${this._annotationsOpen ? '' : 'hidden'}"
        >
          <lanuv-ts-annotations
            id="annotations"
            .additionalAnnotationUrl="${this.additionalAnnotationUrl}"
          ></lanuv-ts-annotations>
        </div>
      </div>`;
  }

  _renderAdminStatus() {
    return this.adminStatus || this.adminBemerkung
      ? html`<span class="adminbemerkung"
          >${!['', '---'].includes(this.adminStatus)
            ? html`<b>${this.adminStatus}: </b>`
            : ``}
          ${this.adminBemerkung}
        </span>`
      : html``;
  }

  get _internetBemerkung() {
    return this.station && this.station.INTERNET_BEMERKUNG !== ''
      ? `</br><span style="color: red"><b>Bemerkung:</b> ${this.station.INTERNET_BEMERKUNG}</span>`
      : '';
  }

  get _adminBemerkungHtml() {
    return this.adminBemerkung || this.adminStatus
      ? `</br><span style="color: red"><b> ${
          this.adminStatus ? this.adminStatus + ': ' : ''
        } </b> ${this.adminBemerkung}</span>`
      : '';
  }

  _renderAnnotations() {
    // Show Box if there are items to render
    // dependsOnOtherAnnotations - only shown if there is at least one not depending annotation
    const _showBox = some(
      this._annotations,
      a => a.showInBox && !a.label?.dependsOnOtherAnnotations,
    );
    if (_showBox)
      return html`<ki-icon-btn
        toggle
        id="descriptionButton"
        .selected="${this._annotationsOpen}"
        title="${this.i18n.t('descriptions')}"
        icon="ki ki-question"
        @click="${this.toggleAnnotations}"
      ></ki-icon-btn> `;
    // Link to provided URL if no boxitems available
    if (this.additionalAnnotationUrl)
      return html`<ki-icon-btn
        id="descriptionButton"
        title="${this.i18n.t('descriptions')}"
        icon="ki ki-question"
        @click="${this.linkToUrl}"
      ></ki-icon-btn> `;
    // Do not render Button if there is nothing to show or link to
    return html``;
  }

  // eslint-disable-next-line class-methods-use-this
  get _years() {
    if (this.coverage) {
      let max = dayjs(this.coverage.max).tz().get('year');
      const min = dayjs(this.coverage.min).tz().get('year');
      const years = [{ value: 'YTD', label: this.i18n.t('currentYear') }];
      while (max >= min) {
        years.push({ value: max, label: max.toString() });
        max -= 1;
      }
      return years;
    }
    return [{ value: 'YTD', label: this.i18n.t('currentYear') }];
  }

  _handleYearChange(e) {
    const { value } = e.target.selectedOption;
    const min =
      value && value !== 'YTD'
        ? dayjs(value, 'YYYY').tz().startOf('year')
        : dayjs().tz().startOf('year');
    const max =
      value && value !== 'YTD'
        ? dayjs(value, 'YYYY').tz().endOf('year')
        : dayjs().tz();
    this.drawSeries('P1Y', min.valueOf(), max.valueOf());
  }

  showTable() {
    this.renderRoot.querySelector('#table-wrapper').classList.toggle('hidden');
    this.renderRoot
      .querySelector('#table-clipboard-btn')
      .classList.toggle('hideButton');
    this._tableOpen = !this._tableOpen;
    this.chart && this.chart.reflow();
  }

  // Embed range annotations to Graph
  downloadGraph() {
    this.updateAnnotations();
    this.chart.update(
      {
        exporting: {
          chartOptions: {
            title: {
              text: this.graphTitle
                ? template(this.graphTitle, this.station)
                : '',
            },
            caption: {
              text: this._annotationsWidget.getAnnotationsAsHtml(),
            },
            subtitle: {
              text: `${this.i18n.t('station_no')}: ${
                this.station.station_no
              } | ${this.i18n.t('request_at')} ${dayjs()
                .tz()
                .format('LLLL')} <br>
            ${dayjs(this.chart.xAxis[0].min).format('L')} - ${dayjs(
                this.chart.xAxis[0].max,
              )
                .tz()
                .format('L')} ${this._internetBemerkung} ${
                this._adminBemerkungHtml
              }`,
            },
          },
          filename: `${this.parameter_label}_${this.station.station_name}_${
            this.station.station_no
          }_${dayjs().tz().format('YYYYMMDD')}`,
          fallbackToExportServer: false,
          buttons: {
            contextButton: {
              enabled: false,
            },
          },
          sourceWidth: 1600,
          sourceHeight: 800,
          scale: 1,
        },
      },
      false,
    );
    this.chart.exportChartLocal();
  }

  async fetchData() {
    const _files = {};
    this.station = this.station || (await this.api.getStation(this.stationId));
    await Promise.all(
      this.station.timeseries
        .filter(ts => ts.station_parameter === this.station_parameter)
        .map(async ts => {
          _files[last(ts.href.split('/'))] = await this.api.getTsData(ts.href);
        }),
    );
    this.files = _files;
    const yearcfg = find(this.ranges, { value: 'P1Y' });
    if (yearcfg && this.files[yearcfg.data]) {
      const tsdata = this.files[yearcfg.data][0].data;
      if (tsdata && tsdata[0]) {
        this.coverage = {
          max: last(tsdata)[0],
          min: tsdata[0][0],
        };
      }
    }
    this.createChart();
    let selected = find(this.ranges, { selected: true });
    selected = this._selectedPeriod || selected.value || this.ranges[0].value;
    this._selectedPeriod = selected;
    this.drawSeries(selected);
    this.updateAnnotations();
    this.requestUpdate();
    return this.files;
  }

  // eslint-disable-next-line class-methods-use-this
  _formatGraphData(data, minDate, maxDate) {
    const tsData = [];
    data.forEach(dp => {
      const ts = new Date(dp[0]).getTime();
      if (ts > minDate && ts < maxDate) {
        const _acc = dp[2] ? Math.floor(dp[2]) : undefined;
        const _p = {
          x: ts,
          y: dp[1],
          acc: _acc,
        };
        const _path = 'M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2';
        _p.acc < 100
          ? (_p.color = {
              pattern: {
                path: _path,
                color: '#0056a0',
                width: 4,
                height: 4,
              },
            })
          : (_p.color = '#0056a0');
        if (_p.acc < 100) _p.borderColor = '#0056a0';
        tsData.push(_p);
      }
    });
    return tsData;
  }

  // eslint-disable-next-line class-methods-use-this
  _formatGraphDataSum(data, minDate, maxDate, resetSumAt) {
    let sum = 0;
    const tsData = [];
    data.forEach(dp => {
      const ts = dayjs(dp[0]);
      const tsms = ts.valueOf();
      if (tsms >= minDate && tsms <= maxDate) {
        if (resetSumAt && resetSumAt === ts.format('HH:mm')) {
          sum = 0;
        }
        sum = dp[1] === null ? null : (sum += dp[1]);
        tsData.push([tsms, sum]);
      }
    });
    return tsData;
  }

  createGraphOptions() {
    const self = this;
    const graphOptions = {
      chart: {
        animation: false,
        type: 'column',
        pinchType: undefined,
        zoomType: 'x',
        panning: {
          enabled: false,
        },
      },

      series: [],
      plotOptions: {
        column: {
          groupPadding: 0,
          dataLabels: {
            enabled: true,
            formatter() {
              // Show data coverage
              const color = this.point.acc < 100 ? 'red' : 'rgba(29,29,29)';
              const val =
                // eslint-disable-next-line no-nested-ternary
                this.point.acc === 0.01
                  ? 'k.a.'
                  : this.point.acc
                  ? `${this.point.acc}%`
                  : '';
              return self.showAccuracy
                ? `<tspan style="color:${color}">${val}</tspan>`
                : '';
            },
          },
        },
        series: {
          animation: false,
          showCheckbox: ViewPort.size !== SM,
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
      },
      yAxis: [
        merge(
          {
            softMax: 2,
            min: 0,
            allowDecimals: false,
            id: 'mainYAxis',
            minorTicks: true,
            tickAmount: 8,
            title: {
              text: this.yAxisLabel || `${this.i18n.t('precip')} [mm]`,
            },
          },
          this.yAxisBar,
        ),
        merge(
          {
            softMax: 2,
            min: 0,
            allowDecimals: false,
            id: 'sumYAxis',
            minorTicks: true,
            tickAmount: 8,
            opposite: true,
            title: {
              text: this.yAxisLabelSum || `${this.i18n.t('Sum line')} [mm]`,
            },
          },
          this.yAxisSum,
        ),
        {
          id: 'accAxis',
          labels: {
            enabled: false,
          },
          title: {
            enabled: false,
          },
          gridLineWidth: 0,
        },
      ],
      xAxis: [
        {
          id: 'mainXAxis',
          type: 'datetime',
          max: new Date().getTime(),
        },
        {
          visible: false,
          id: 'alarmAxis',
        },
      ],
    };
    return graphOptions;
  }

  _addAccuracy(tsdata, timeformat) {
    // Transform Accuracy to Value
    const _data: Array<object> = [];
    tsdata.forEach(d => {
      const newPoint = {};
      newPoint.x = d.x;
      newPoint.y = d.acc;
      if (d.acc) _data.push(newPoint); // Catch periods without accuracy
    });

    // Add Accuracy as series to show accuracy in tooltip
    // Hide series in graph and legend
    if (_data.length > 0)
      this.chart.addSeries(
        {
          data: _data,
          yAxis: 'accAxis',
          name: this.i18n.t('accuracy'),
          id: 'acc',
          color: 'black',
          selected: true,
          marker: { enabled: false },
          tooltip: {
            valueDecimals: 0,
            valueSuffix: `%`,
            xDateFormat: timeformat,
          },
          showInLegend: false,
          opacity: 0,
          grouping: false,
        },
        false,
      );
  }

  get creditOptions() {
    const credits = this.credits ? { ...this.credits } : { enabled: false };
    if (credits?.text) {
      credits.text = template(credits.text, this.station);
    }
    if (this.credits && ViewPort.size === SM) {
      credits.position = credits.positionMobile || credits.position;
    }
    credits.enabled = !this.miniGraph;
    return credits;
  }

  defaultOptions() {
    const timezoneOffset = -dayjs
      .tz(
        dayjs().tz(),
        dayjs.defaultZone ? dayjs.defaultZone.name : dayjs.tz.guess(),
      )
      .utcOffset();
    const conf = merge(
      {
        title: {
          text: '',
        },
        subtitle: {
          text: this.subTitle || '',
          floating: true,
        },
        credits: this.creditOptions,
        exporting: {
          chartOptions: {
            title: {
              text: this.graphTitle
                ? template(this.graphTitle, this.station)
                : '',
            },
            subtitle: {
              text: `${this.i18n.t('station_no')}: ${
                this.station.station_no
              } | ${this.i18n.t('request_at')} ${dayjs().tz().format('LLLL')}`,
            },
          },
          filename: `${this.parameter_label}_${this.station.station_name}_${
            this.station.station_no
          }_${dayjs().tz().format('YYYYMMDD_kkmmss')}`,
          fallbackToExportServer: false,
          sourceWidth: 1600,
          sourceHeight: 800,
          scale: 1,
          buttons: {
            contextButton: {
              enabled: false,
            },
          },
        },
        plotOptions: {
          series: {
            label: {
              enabled: false,
            },
            turboThreshold: 2000,
            showCheckbox: true,
            events: {
              checkboxClick(event) {
                if (event.checked) {
                  this.show();
                  // this.legendSymbol.hide();
                } else {
                  this.hide();
                  //  this.legendSymbol.hide();
                }
              },
              legendItemClick() {
                if (this.checkbox) {
                  this.checkbox.checked = !this.visible;
                }
              },
              hide() {
                this.yAxis.update({
                  visible: false,
                });
              },
              show() {
                this.yAxis.update({
                  visible: true,
                });
              },
            },
          },
        },
        legend: {
          enabled: !this.miniGraph,
          align: 'left',
          symbolPadding: 5,
          symbolRadius: 0,
          itemDistance: 40,
          squareSymbol: true,
          itemCheckboxStyle: {
            marginLeft: '-30px',
          },
        },
        tooltip: {
          valueSuffix: 'cm',
          shared: true,
        },
        time: {
          // eslint-disable-next-line no-unused-vars
          getTimezoneOffset() {
            return timezoneOffset;
          },
        },
        yAxis: {
          id: 'mainYAxis',
          minRange: 3,
          title: {
            text: this.yAxisLabel || this.i18n.t('precipmm'),
          },
        },
        series: [],
      },
      this.createGraphOptions(),
    );
    return conf;
  }
}

import WwMapPopupBase from '@kisters/wiski-web/components/common/ww-map-popup-base';
import { customElement } from 'lit/decorators.js';
import { html } from 'lit';
import '@kisters/ww-lanuv-common/components/ww-lanuv-graph/ww-lanuv-graph';
import dayjs from '@kisters/wcp-base/common/dayjsext';
import { orderBy } from 'lodash-es';

@customElement('ww-map-popup-lanuv-simple')
export default class WwMapPopupLanuv extends WwMapPopupBase {
  // eslint-disable-next-line class-methods-use-this

  getGraph(station) {
    return html`<ki-wwp-graph
      miniGraph
      .options="${graph}"
      stationId="${station.station_id}"
      style="width:360px;height:200px;"
    ></ki-wwp-graph>`;
  }

  _getRow(station, mainParameter) {
    const isMain =
      mainParameter === station.stationparameter_no ||
      mainParameter === station.stationparameter_name;
    const parameter = station.ts_name;
    const value =
      station.ts_value != null
        ? `${this.valFormatter(station.ts_value)} ${station.ts_unitsymbol}`
        : '-';
    const timestamp = station.timestamp
      ? dayjs(station.timestamp).tz().format('L LT')
      : '-';
    return html`<div class="row ${isMain ? 'highlight' : ''}">
      <div class="cell label">${parameter}</div>
      <div class="cell"><b>${value}</b> (${timestamp})</div>
    </div>`;
  }

  _stationTmpl(stations, graph = true) {
    const station = stations[0];
    this.stationRemark = station[this.config.remarkAttr];
    const river = station.WTO_OBJECT ? `(${station.WTO_OBJECT})` : '';

    return html`<div class="station-name">
        ${station.station_no} | ${station.station_name} ${river}
      </div>
      <div class="remark">${this.stationRemark}</div>
      <hr />
      <div class="table">
        ${station.ts_values[station.stationparameter_name]
          ? orderBy(
              Object.values(station.ts_values[station.stationparameter_name]),
              [item => item.ts_name.toLowerCase()],
            ).map(st => this._getRow(st, station.mainParameter))
          : stations.map(st => this._getRow(st, station.mainParameter))}
      </div>`;
  }
}

import { merge } from 'lodash-es';
/* eslint-disable lit-a11y/click-events-have-key-events */
/* eslint-disable consistent-return */
/* eslint-disable func-names */
/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-const */
/* eslint-disable class-methods-use-this */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { i18nMixin } from '@kisters/wcp-base/decorators';
import { LegendFactory, Classifier } from '@kisters/wcp-water/components';
import { find, keyBy, orderBy, countBy, filter, first } from 'lodash-es';
import { navigateTo } from '@kisters/wcp-base/app';
import { getCurrentApi } from '@kisters/wiski-web/api';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { Mix } from '@kisters/wcp-base/common';
import dayjs from '@kisters/wcp-base/common/dayjsext';
import { getRouteOptionsAndParams } from '@kisters/wcp-base/components';

import style from './wwp-lanuv-berichte.css';
import nls from '../../locales/index';
import Bericht from './Bericht';
import { BerichteHelper as Helper } from './BerichtHelper';

// import {wasserstand} from '@kisters/ww-lanuv-common/testdata/alarmlayer';

@customElement('wwp-lanuv-berichte')
export default class WwpLanuvBerichte extends Mix(LitElement, [
  i18nMixin,
  { nls },
]) {
  api = getCurrentApi();

  // language=CSS
  static styles = style;

  constructor() {
    super();
    this.numberFormatter = new Intl.NumberFormat(this.i18n.language || 'de', {
      minimumFractionDigits: 1,
    });
    this.tableInformationswertueberschreitungen = [];
    this.tableBerichte = [];
    this.tableHochwasserMeldepegel = [];
    this.tableGewaesserkundlichePegel = [];
    this.legendFactory = {};
  }

  @property({ type: Boolean })
  showLoader = true;

  @property({ type: String })
  resourcePath = 'https://hydrologie-test.nrw.de/test/lanuv/';

  @property({ type: Boolean })
  errorInfoFetch = false;

  @property({ type: Boolean })
  errorReportFetch = false;

  @property({ type: Object })
  legendFactory = {}; // StatusIcons

  @property({ type: Array })
  wasserstand: [] = []; // Formatted

  @property({ type: Array })
  wasserstandMax: [] = []; // Formatted

  @property({ type: Object })
  sort = { t1: {}, t2: {}, t3: {}, t4: {} };

  async onAfterEnter(location) {
    const params = getRouteOptionsAndParams(location, []);
    Object.assign(this, JSON.parse(JSON.stringify(params.options)));

    this.fetchData();
  }

  /**   *
   * @param station Dataset of latest waterlevel (not TagMax)
   */
  showStation(station) {
    this.layerName = 'Wasserstand';
    const label = station.station_longname || station.station_name;
    if (station.external && station.url) {
      window.open(station.url, '_new');
    } else {
      const stationId = station.station_id;
      navigateTo(
        `/overview/${this.layerName}/station/${stationId}/${encodeURIComponent(
          label,
        )}`,
      );
    }
  }

  _renderTableBerichte() {
    return html` <h2 class="breaklong">
        <span>Aktuelle Informationen/Lageberichte zu Hochwasser</span>
      </h2>
      <div class="table-wrapper">
        ${this.tableBerichte.length >= 1
          ? /**Table 1 - Berichte */

            html`<div id="berichteTable" class="table-container">
            <div class="table">
              <div class="header">
                <div class="row">
                <div class="cell" @click="${() => {
                  this.sortTable('t1', 'ausgabezeit');
                }}"><div class="header-content sortable">Ausgabezeit<ki-icon icon="ki ki-sort-v"></ki-icon></div></div>

                <div class="cell" @click="${() => {
                  this.sortTable('t1', 'catchment');
                }}"
><div class="header-content sortable">Gewässer/Gebiet<ki-icon icon="ki ki-sort-v"></ki-icon></div></div>
                <div class="cell"><div class="header-content">Bericht</div></div>
                </div>
              </div>
              ${this.tableBerichte.map(
                e =>
                  html`<div
                    class="row hrefrow berichtrow"
                    @click=${event => {
                      if (e.filename) {
                        this.downloadPdf(event);
                      }
                    }}
                  >
                    <div class="cell" .value="${e.filename}">
                      ${dayjs(e.ausgabezeit).format('DD.MM.YYYY HH:mm')}
                    </div>
                    <div
                      class="cell"
                      .value=${e.filename}
                    >
                      ${Helper._unescapeXml(e.catchment)}
                    </div>
                    <div
                      style="max-width: 60em;"
                      class="cell preserve-line-breaks ${e.description
                        ? 'nofile'
                        : 'file'}"
                      .value=${e.filename}
                    >${e.description !== undefined
                          ? Helper._unescapeXml(e.description)
                          : e.filename}
                    </div>
                  </div>`,
              )}
            </div>
          </div>
          </div>`
          : this.errorReportFetch
          ? html`<h4 class="error">
              <ki-icon
                style="margin-right: 40px; color: rgb(255, 0, 0); "
              ></ki-icon>
              Daten konnten nicht geladen werden
            </h4>`
          : html`<h4>
              <ki-icon
                style="margin-right: 40px; color: rgb(0, 200, 50); "
                icon="ki ki-circle"
              ></ki-icon
              >Zurzeit gibt es keine Lageberichte
            </h4>`}
      </div>`;
  }

  _renderTableHochwasser() {
    return html` <h2 class="breaklong">
        <span
          >Aktuelle Informationswert&shy;überschreitungen am
          ${dayjs().format('LL')}</span
        >
        ${this.tableInformationswertueberschreitungen.length >= 1
          ? html`<ki-icon-btn
                class="dl-icon ripple"
                title="Tabelle herunterladen"
                .value=${'#info-table'}
                .table=${'Informationswertueberschreitungen'}
                @click=${event => {
                  this.downloadCsv(event);
                }}
                icon="ki ki-download"
              ></ki-icon-btn
              ><ki-icon-btn
                class="dl-icon ripple"
                title="Tabelle kopieren"
                .value=${'#info-table'}
                @click=${event => {
                  this.copyToClipboardCsv(event);
                }}
                icon="ki ki-clipboard"
              ></ki-icon-btn>`
          : html``}
      </h2>

      <div class="table-wrapper">
        ${this.tableInformationswertueberschreitungen.length >= 1
          ? html`
              <div id="info-table" class="table table-container">
                <div class="header">
                  <div class="row">
                    <div
                      class="cell"
                      @click="${() => {
                        this.sortTable('t2', 'priority');
                      }}"
                    >
                      <div class="header-content sortable">
                        Status<ki-icon icon="ki ki-sort-v"></ki-icon>
                      </div>
                    </div>
                    <div
                      class="cell"
                      @click="${() => {
                        this.sortTable('t2', 'station_name');
                      }}"
                    >
                      <div class="header-content sortable">
                        Station<ki-icon icon="ki ki-sort-v"></ki-icon>
                      </div>
                    </div>
                    <div
                      class="cell"
                      @click="${() => {
                        this.sortTable('t2', 'WTO_OBJECT');
                      }}"
                    >
                      <div class="header-content sortable">
                        Gewässer<ki-icon icon="ki ki-sort-v"></ki-icon>
                      </div>
                    </div>
                    <div
                      class="cell"
                      @click="${() => {
                        this.sortTable('t2', 'catchment_name');
                      }}"
                    >
                      <div class="header-content sortable">
                        Einzugsgebiet<ki-icon icon="ki ki-sort-v"></ki-icon>
                      </div>
                    </div>
                    <div class="cell hideable-xs">
                      <div class="header-content header-info">
                        Informationswert(e) (cm)
                      </div>
                    </div>
                    <div class="cell">
                      <div class="header-content header-tsvalue">
                        Letzter Messwert (cm)
                      </div>
                    </div>
                    <div class="cell">
                      <div class="header-content">Vermerk</div>
                    </div>
                  </div>
                </div>
                <div class="body">
                  ${this.tableInformationswertueberschreitungen.map(
                    wl =>
                      html`<div
                        class="row hrefrow"
                        @click="${() => {
                          this.showStation(wl);
                        }}"
                      >
                        <div class="cell multi multi-status">
                          ${this.getStationStatusIcon(wl)}
                        </div>
                        <div class="cell stationname">${wl.station_name}</div>
                        <div class="cell">${wl.WTO_OBJECT}</div>
                        <div class="cell">${wl.catchment_name}</div>
                        <div
                          class="cell multi multi-info hideable-xs"
                          style="display: flex; justify-content: center"
                        >
                          ${this.getInfowerteCell(wl)}
                        </div>
                        <div class="cell multi multi-tsvalue">
                          <div class="left">
                            ${dayjs(wl.timestamp).format('L LT')}
                          </div>
                          <div class="right">
                            ${wl.ts_value === 0
                              ? ''
                              : this.numberFormatter.format(wl.ts_value)}
                          </div>
                        </div>

                        <div class="cell break-xs">
                          ${this.getStationRemark(wl)}
                        </div>
                      </div>`,
                  )}
                </div>
              </div>
            `
          : this.errorInfoFetch
          ? /**Table 3 - Hochwassermeldepegel Vortag */
            html`<h4 class="error">
              <ki-icon
                style="margin-right: 40px; color: rgb(255, 0, 0);"
                icon="kim kim-circle"
              ></ki-icon>
              Daten konnten nicht geladen werden
            </h4>`
          : html`<h4>
              <ki-icon
                style="margin-right: 40px; color: rgb(0, 200, 50);"
                icon="ki ki-circle"
              ></ki-icon>
              Zurzeit gibt es keine Informationswertüberschreitungen
            </h4>`}
      </div>`;
  }

  _renderTableGefahr() {
    return html` <h2 class="breaklong">
        <span
          >Gewässer&shy;kundliche Pegel mit erhöhtem Wasserstand am
          ${dayjs().format('LL')} (> 0,8 MHW)</span
        >${
          this.tableGewaesserkundlichePegel.length >= 1
            ? html`<ki-icon-btn
                  class="dl-icon ripple"
                  title="Tabelle herunterladen"
                  .value=${'#gewaesser-table'}
                  .table=${'GewaesserkundlichePegel'}
                  @click=${event => {
                    this.downloadCsv(event);
                  }}
                  icon="ki ki-download"
                ></ki-icon-btn
                ><ki-icon-btn
                  class="dl-icon ripple"
                  title="Tabelle kopieren"
                  .value=${'#gewaesser-table'}
                  @click=${event => {
                    this.copyToClipboardCsv(event);
                  }}
                  icon="ki ki-clipboard"
                ></ki-icon-btn>`
            : html``
        }
      </h2>
      <div class="table-wrapper">
        ${
          this.tableGewaesserkundlichePegel.length >= 1
            ? html`
                <div class="table-container">
                  <div class="table" id="gewaesser-table">
                    <div class="header">
                      <div class="row">
                        <div
                          class="cell"
                          @click="${() => {
                            this.sortTable('t4', 'priority');
                          }}"
                        >
                          <div class="header-content sortable">
                            Status<ki-icon icon="ki ki-sort-v"></ki-icon>
                          </div>
                        </div>
                        <div
                          class="cell"
                          @click="${() => {
                            this.sortTable('t4', 'station_name');
                          }}"
                        >
                          <div class="header-content sortable">
                            Station<ki-icon icon="ki ki-sort-v"></ki-icon>
                          </div>
                        </div>
                        <div
                          class="cell"
                          @click="${() => {
                            this.sortTable('t4', 'catchment_name');
                          }}"
                        >
                          <div class="header-content sortable">
                            Einzugsgebiet<ki-icon icon="ki ki-sort-v"></ki-icon>
                          </div>
                        </div>
                        <div
                          class="cell"
                          @click="${() => {
                            this.sortTable('t4', 'WTO_OBJECT');
                          }}"
                        >
                          <div class="header-content sortable">
                            Gewässer<ki-icon icon="ki ki-sort-v"></ki-icon>
                          </div>
                        </div>
                        <div class="cell hideable-s">
                          <div class="header-content">MNW (cm)</div>
                        </div>
                        <div class="cell hideable-s">
                          <div class="header-content">MW (cm)</div>
                        </div>
                        <div class="cell hideable-s">
                          <div class="header-content">MHW (cm)</div>
                        </div>
                        <div class="cell">
                          <div class="header-content header-tsvalue">
                            Höchster Messwert (cm)
                          </div>
                        </div>
                        <div class="cell">
                          <div class="header-content header-tsvalue">
                            Letzter Messwert (cm)
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="body">
                      ${this.tableGewaesserkundlichePegel.map(wl => {
                        return html`<div
                          class="row hrefrow"
                          @click="${() => {
                            this.showStation(wl);
                          }}"
                        >
                          <div class="cell multi multi-status">
                            ${this.getStationStatusIcon(wl)}
                          </div>
                          <div class="cell stationname">${wl.station_name}</div>
                          <div class="cell">${wl.catchment_name}</div>
                          <div class="cell">${wl.WTO_OBJECT}</div>
                          <div class="cell number hideable-s">
                            ${wl.LANUV_MNW === 0 ? '' : wl.LANUV_MNW}
                          </div>
                          <div class="cell number hideable-s">
                            ${wl.LANUV_MW === 0 ? '' : wl.LANUV_MW}
                          </div>
                          <div class="cell number hideable-s">
                            ${wl.LANUV_MHW === 0 ? '' : wl.LANUV_MHW}
                          </div>
                          <div class="cell multi multi-tsvalue">
                            <div class="left">
                              ${dayjs(wl.maxToday.occ_timestamp).format('L LT')}
                            </div>
                            <div class="right">
                              ${wl.maxToday.value === 0
                                ? ''
                                : this.numberFormatter.format(
                                    wl.maxToday.value,
                                  )}
                            </div>
                          </div>
                          <div class="cell multi multi-tsvalue">
                            <div class="left">
                              ${html`${dayjs(wl.timestamp).format('L LT')} `}
                            </div>
                            <div class="right">
                              ${html`${wl.ts_value === 0
                                ? ''
                                : this.numberFormatter.format(wl.ts_value)}`}
                            </div>
                          </div>
                        </div>`;
                      })}
                    </div>
                  </div>
                </div>
              `
            : this.errorInfoFetch
            ? html`<h4 class="error">
                <ki-icon
                  style="margin-right: 40px; color: rgb(255, 0, 0); "
                  icon="kim kim-circle"
                ></ki-icon>
                Daten konnten nicht geladen werden
              </h4>`
            : html`<h4>
                <ki-icon
                  style="margin-right: 40px; color: rgb(0, 200, 50); "
                  icon="ki ki-circle"
                ></ki-icon
                >Zurzeit gibt es keine Pegel mit erhöhtem Wasserstand
              </h4>`
        }
      </div></div>`;
  }

  _renderHochwasserVortag() {
    return html`<h2 class="breaklong">
        <span>Hochwasser&shy;meldepegel mit Überschreitungen am Vortag</span>
        ${this.tableHochwasserMeldepegel.length >= 1
          ? html`<ki-icon-btn
                class="dl-icon ripple clipboard-btn"
                .value=${'#hochwasser-table'}
                .table=${'Hochwassermeldepegel'}
                @click=${event => {
                  this.downloadCsv(event);
                }}
                icon="ki ki-download"
              ></ki-icon-btn
              ><ki-icon-btn
                class="dl-icon ripple clipboard-btn"
                title="Tabelle kopieren"
                .value=${'#hochwasser-table'}
                @click=${event => {
                  this.copyToClipboardCsv(event);
                }}
                icon="ki ki-clipboard"
              ></ki-icon-btn> `
          : html``}
      </h2>
      <div class="table-wrapper">
        ${this.tableHochwasserMeldepegel.length >= 1
          ? html` <div id="hochwasser-table" class="table table-container">
              <div class="header">
                <div class="row">
                  <div
                    class="cell"
                    @click="${() => {
                      this.sortTable('t3', 'station_name');
                    }}"
                  >
                    <div class="header-content sortable">
                      Station<ki-icon icon="ki ki-sort-v"></ki-icon>
                    </div>
                  </div>

                  <div
                    class="cell"
                    @click="${() => {
                      this.sortTable('t3', 'WTO_OBJECT');
                    }}"
                  >
                    <div class="header-content sortable">
                      Gewässer<ki-icon icon="ki ki-sort-v"></ki-icon>
                    </div>
                  </div>
                  <div
                    class="cell"
                    @click="${() => {
                      this.sortTable('t3', 'catchment_name');
                    }}"
                  >
                    <div class="header-content sortable">
                      Einzugsgebiet<ki-icon icon="ki ki-sort-v"></ki-icon>
                    </div>
                  </div>
                  <div class="cell hideable-s">
                    <div class="header-content">Informationswert 1 (cm)</div>
                  </div>
                  <div class="cell hideable-s">
                    <div class="header-content">Informationswert 2 (cm)</div>
                  </div>
                  <div class="cell hideable-s">
                    <div class="header-content">Informationswert 3 (cm)</div>
                  </div>
                  <div class="cell">
                    <div class="header-content header-tsvalue">
                      Höchster Messwert des Vortages (cm)
                    </div>
                  </div>
                  <div class="cell">
                    <div class="header-content header-tsvalue">
                      Letzter Messwert (cm)
                    </div>
                  </div>
                </div>
              </div>
              <div class="body">
                ${this.tableHochwasserMeldepegel.map(wl => {
                  return html`<div
                    class="row hrefrow"
                    @click="${() => {
                      this.showStation(wl);
                    }}"
                  >
                    <div class="cell stationname">${wl.station_name}</div>
                    <div class="cell ">${wl.WTO_OBJECT}</div>
                    <div class="cell ">${wl.catchment_name}</div>
                    <div class="cell number hideable-s">
                      ${Number.isNaN(wl.LANUV_Info_1) ? '-' : wl.LANUV_Info_1}
                    </div>
                    <div class="cell number hideable-s">
                      ${Number.isNaN(wl.LANUV_Info_2) ? '-' : wl.LANUV_Info_2}
                    </div>
                    <div class="cell number hideable-s">
                      ${Number.isNaN(wl.LANUV_Info_3) ? '-' : wl.LANUV_Info_3}
                    </div>
                    <div class="cell multi multi-tsvalue">
                      <div class="left">
                        ${dayjs(wl.maxYesterday.occ_timestamp).format('L LT')}
                      </div>
                      <div class="right">
                        ${wl.maxYesterday.value === 0
                          ? ''
                          : this.numberFormatter.format(wl.maxYesterday.value)}
                      </div>
                    </div>
                    <div class="cell multi multi-tsvalue">
                      <div class="left">
                        ${dayjs(wl.timestamp).format('L LT')}
                      </div>
                      <div class="right">
                        ${wl.ts_value === 0
                          ? ''
                          : this.numberFormatter.format(wl.ts_value)}
                      </div>
                    </div>
                  </div>`;
                })}
              </div>
            </div>`
          : this.errorInfoFetch
          ? /**Table 3 - Hochwassermeldepegel Vortag */
            html`<h4 class="error">
              <ki-icon
                style="margin-right: 40px; color: rgb(255, 0, 0); "
                icon="kim kim-circle"
              ></ki-icon>
              Daten konnten nicht geladen werden
            </h4>`
          : html`<h4>
              <ki-icon
                style="margin-right: 40px; color: rgb(0, 200, 50); "
                icon="ki ki-circle"
              ></ki-icon>
              Am Vortag gab es keine Informationswertüberschreitungen
            </h4>`}
      </div>`;
  }

  _renderContent() {
    return html`${this._renderTableBerichte()} ${this._renderTableHochwasser()}
    ${this._renderTableGefahr()} ${this._renderHochwasserVortag()}`;
  }

  render() {
    return html` <div id="lageberichteComponent">
      ${this.showLoader
        ? html` <div id="loader-container">
            <div class="cover"></div>
            <div class="loader centered"></div>
          </div>`
        : html`${this._renderContent()}`}
    </div>`;
  }

  // Vermerk für Informationswertüberschreitung
  getStationRemark(rowItem) {
    const remarks: string[] = [];
    if (['Info 1', 'Info 2', 'Info 3'].includes(rowItem.classification)) {
      remarks.push(
        `${this.getFullInfoName(rowItem.classification)} überschritten`,
      );
    }
    if (dayjs(rowItem.timestamp) < dayjs().subtract(1, 'day'))
      remarks.push('Messwert veraltet');
    if (rowItem.INTERNET_AKTIV === 'no') remarks.push('Station inaktiv');
    if (rowItem.error) remarks.push(rowItem.error);
    return remarks.join(', ');
  }

  getFullInfoName(shortName) {
    return shortName.replace('Info', 'Informationswert');
  }

  // Icon für Status in Informationswertüberschreitung
  getStationStatusIcon(rowItem) {
    /** Fetch  */
    if (dayjs(rowItem.timestamp) < dayjs().subtract(1, 'day')) {
      rowItem.priority = 7;
      return html`<div class="inner" title="Nicht aktueller Wert">
        <img src ="${this.legendFactory.pngs.none}" alt="Veraltet"></img>
      </div>`;
    }
    if (rowItem.INTERNET_AKTIV === 'no') {
      rowItem.priority = 6;
      return html`<div class="inner" title="Inaktiver Pegel">
        <img src ="${this.legendFactory.pngs.inaktiv}" alt="Inaktiv"></img>
      </div>`;
    }
    if (
      rowItem.INTERNET_AKTIV === 'yes' &&
      rowItem.WEB_STATYPE === 'Gewässerkundlicher Pegel'
    ) {
      rowItem.priority = 5;
      return html`<div class="inner" title="Gewässerkundlicher Pegel">
        <img src ="${this.legendFactory.pngs.GKP}" alt="Inaktiv"></img>
      </div>`;
    }
    if (['NORMAL', null, 'IVorW', 'VorW'].includes(rowItem.classification)) {
      rowItem.priority = 4;
      return html`<div class="inner" title="OK">
        <img src="${this.legendFactory.pngs.good}" alt="OK" />
      </div>`;
    }
    if (rowItem.classification === 'Info 1') {
      rowItem.priority = 3;
      return html`<div class="inner" title="Info 1">
        <img src="${this.legendFactory.pngs.AS1}" alt="Info1" />
      </div>`;
    }
    if (rowItem.classification === 'Info 2') {
      rowItem.priority = 2;
      return html`<div class="inner" title="Info 2">
        <img src="${this.legendFactory.pngs.AS2}" alt="Info2" />
      </div>`;
    }
    if (rowItem.classification === 'Info 3') {
      rowItem.priority = 1;
      return html`<div class="inner" title="Info 3">
        <img src="${this.legendFactory.pngs.AS3}" alt="Info3" />
      </div>`;
    }
    if (['HN7W', 'MN7W', 'N7W'].includes(rowItem.classification)) {
      rowItem.error = `Klassifizierung unplausibel - ${rowItem.classification} > Infowert`;
      return html`<div class="inner" title="Klassifizierung unplausibel">
        <img src="${this.legendFactory.pngs.inaktiv}" alt="Error" />
      </div>`;
    }
    rowItem.error = `Unbehandelte Klassifizierung - ${rowItem.classification}`;
    console.warn(`Unbehandelte Klassifizierung - ${rowItem}`);
    return html`<div class="inner" title="Unbehandelte Klassifizierung">
      <ki-icon style="color: rgb(255, 0, 0);" icon="kim kim-circle"></ki-icon>
    </div>`;
  }

  getInfowerteCell(rowItem) {
    let str = '<div style="width: 130px;"><span style="">';
    if (rowItem.LANUV_Info_1)
      ['Info 1', 'Info 2', 'Info 3'].includes(rowItem.classification)
        ? (str += `<b>${rowItem.LANUV_Info_1}</b>`)
        : (str += rowItem.LANUV_Info_1);
    if (rowItem.LANUV_Info_2) {
      str += ' | ';
      ['Info 2', 'Info 3'].includes(rowItem.classification)
        ? (str += `<b>${rowItem.LANUV_Info_2}</b>`)
        : (str += rowItem.LANUV_Info_2);
    }
    if (rowItem.LANUV_Info_3) {
      str += ' | ';
      ['Info 3'].includes(rowItem.classification)
        ? (str += `<b>${rowItem.LANUV_Info_3}</b>`)
        : (str += rowItem.LANUV_Info_3);
    }
    str += '</span></div>';
    return html`${unsafeHTML(str)}`;
  }

  // LANUV customizations
  parseTableToCsv(table) {
    const delim = ';';
    let csvContent = ''; // data:text/csv;charset=utf-8,

    [...table.querySelectorAll('.row')].map(r => {
      [...r.querySelectorAll('.cell')].map(c => {
        // Catch multi value cells
        if (c.classList.contains('multi')) {
          // Wert-Zeit-Paar als zwei Spalten
          if (c.classList.contains('multi-tsvalue')) {
            csvContent +=
              c.querySelector('.left').innerText +
              delim +
              c.querySelector('.right').innerText +
              delim;
          }
          // Infowertüberschreitungen - Staus: Tooltip als Text
          else if (c.classList.contains('multi-status'))
            csvContent += c.firstElementChild?.title + delim;
          // Infowertüberschreitungen - Info 1/2/3 als separate Spalten + Header
          else if (c.classList.contains('multi-info')) {
            const arr = c.innerText.replaceAll(' ', '').split('|');
            // eslint-disable-next-line no-inner-declarations
            function pushEmptyUntilThree(_arr) {
              if (_arr.length < 3) {
                _arr.push('');
                pushEmptyUntilThree(_arr);
              }
            }
            pushEmptyUntilThree(arr);
            // eslint-disable-next-line no-return-assign
            arr.map(i => (csvContent += i + delim));
          }
        } else if (c.firstElementChild?.classList.contains('header-content')) {
          if (c.firstElementChild?.classList.contains('header-tsvalue')) {
            c.firstElementChild.innerText.includes('Letzter')
              ? (csvContent += `Letzter Messzeitpunkt${delim}Letzter Messwert (cm)${delim}`)
              : (csvContent += `Messzeitpunkt Höchstwert${delim}Höchster Messwert (cm)${delim}`);
          } else if (c.firstElementChild?.classList.contains('header-info')) {
            csvContent += `Informationswert 1 (cm)${delim}Informationswert 2 (cm)${delim}Informationswert 3 (cm)${delim}`;
          } else
            csvContent += c.innerText.replace(/(\r\n|\n|\r)/gm, ' ') + delim;
        } else csvContent += c.innerText.replace(/(\r\n|\n|\r)/gm, ' ') + delim;
      });
      csvContent += '\r\n';
    });
    return csvContent;
  }

  copyToClipboardCsv(e) {
    const table = this.shadowRoot.querySelector(e.target.value);

    function _copyToClipboard(csv) {
      if (navigator.clipboard) {
        // default: modern asynchronous API
        return navigator.clipboard.writeText(csv);
      }
      if (window.clipboardData?.sedivata) {
        // for IE11
        window.clipboardData.sedivata('Text', csv);
        return Promise.resolve();
      }
      // workaround: create dummy input
      const input = document.createElement('input', { type: 'text' });
      input.value = csv;
      document.body.append(input);
      input.focus();
      input.select();
      document.execCommand('copy');
      input.remove();
      return Promise.resolve();
    }
    const csvContent = this.parseTableToCsv(table);
    _copyToClipboard(csvContent);
  }

  downloadCsv(e) {
    const table = this.shadowRoot.querySelector(e.target.value);
    const prefix = e.target.table;
    const csv = this.parseTableToCsv(table);
    const universalBOM = '\uFEFF';
    const link = document.createElement('a');
    link.setAttribute(
      'href',
      `data:text/csv; charset=utf-8,${encodeURIComponent(universalBOM + csv)}`,
    );
    // Generate filename
    let filename = `Lanuv_${prefix}${dayjs().format('YYMMDD[_]HHmmss')}`;
    filename += '.csv';
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  }

  downloadPdf(event) {
    window.open(`${this.api.dataPath}/lhp/${event.target.value}`);
  }

  async formatData(arr) {
    arr.map(e => {
      const numericValues = [
        'LANUV_N7W',
        'LANUV_MN7W',
        'LANUV_HN7W',
        'LANUV_MW',
        'LANUV_MNW',
        'LANUV_MHW',
        'LANUV_Info_1',
        'LANUV_Info_2',
        'LANUV_Info_3',
        'ts_value',
      ];
      numericValues.map(prop => {
        if (e[prop] !== undefined && e[prop] !== null) {
          if (typeof e[prop] !== 'number') e[prop] = parseFloat(e[prop]);
        }
      });
    });
    return arr;
  }

  async fetchData() {
    // Aktuelle Informationen/Lageberichte zu Hochwasser
    try {
      const fetchCall = await fetch(
        `${
          this.api.dataPath
        }/kisters/nw_bericht_utf8.xml?cache=${new Date().getTime()}`, //`${this.api.dataPath}/lhp/nw_bericht.xml?cache=${new Date().getTime()}`,
      );
      const xmlberichteUTF8 = await fetchCall.text();
      console.log(xmlberichteUTF8);

      const parser = new DOMParser();
      const doc = parser.parseFromString(xmlberichteUTF8, 'text/xml');

      const pdfBerichte = doc.querySelectorAll('pdf');
      const volltextBerichte = doc.querySelectorAll('volltext');
      const berichte: Bericht[] = [];
      for (let i = 0; i < pdfBerichte.length; i += 1) {
        let pdf = pdfBerichte[i];
        berichte.push({
          ausgabezeit: pdf?.getAttribute('ausgabezeit'),
          catchment: pdf?.getAttribute('gebiet'),
          filename: pdf?.getAttribute('dateiname'),
        });
      }

      for (let i = 0; i < volltextBerichte.length; i += 1) {
        let volltext = volltextBerichte[i];
        berichte.push({
          ausgabezeit: volltext?.getAttribute('ausgabezeit'),
          catchment:
            volltext?.getAttribute('gebiet') || volltext?.getAttribute('titel'),
          description: volltext?.innerHTML,
        });
      }

      // Sort - LANUV req: newest report on top
      this.tableBerichte = orderBy(
        berichte,
        e => dayjs(e.ausgabezeit).valueOf(),
        'desc',
      );
    } catch (e) {
      console.warn('Fehler beim Abrufen der Berichte', e);
      this.errorReportFetch = true;
    }

    // Get data and convert (remove localization artifacts)
    try {
      const layers = await this.api.getLayers();
      console.log('Fetch layers -> Success');
      //  this.wasserstand = await this.formatData(wasserstand)
      this.wasserstand = await this.formatData(
        await this.api.getLayerData(
          layers[this.wasserstandLayerName] || layers.Wasserstand,
        ),
      );
      console.log('Fetch Wasserstand -> Success');

      const layerMax = layers.WasserstandMax;
      this.wasserstandMax = await this.formatData(
        await this.api.getLayerData(layerMax),
      );
      console.log('Fetch WasserstandMax -> Success');

      // Create Icons
      const data = new Classifier(
        layers.Wasserstand?.config.classification,
      ).classify(this.wasserstand);
      const tagCount =
        layers.Wasserstand?.config.classification.tagCount !== false
          ? countBy(data, '__tag')
          : null;
      this._dataLayer = {
        config: layers.Wasserstand?.config,
        data,
        tagCount,
        creationDateInMillis: layers.Wasserstand?.creationDateInMillis,
      };
      this.legendFactory = new LegendFactory(
        this._dataLayer.config.classification,
      );

      // Aktuelle Informationswertüberschreitungen
      const yesterday = dayjs().subtract(1, 'day').valueOf();
      this.tableInformationswertueberschreitungen = orderBy(
        filter(this.wasserstand, o => {
          return (
            (o.classification === 'Info 1' ||
              o.classification === 'Info 2' ||
              o.classification === 'Info 3') &&
            o.INTERNET_AKTIV === 'yes' &&
            (o.WEB_STATYPE === 'Infopegel' ||
              o.WEB_STATYPE === 'Weiter Betreiber Infostufen') &&
            new Date(o.timestamp).getTime() > yesterday
          );
        }),
        [e => e.classification, e => e['station_name'].toLowerCase()],
        ['desc'],
      );

      // Avoid that station is shown twice
      const _index = this.tableInformationswertueberschreitungen.map(
        i => i.station_id,
      );

      // Gewässerkundliche Pegel des aktuellen Tages mit erhöhtem Wasserstand

      this.tableGewaesserkundlichePegel = orderBy(
        filter(this.wasserstandMax, o => {
          merge(o, find(this.wasserstand, { station_id: o.station_id }));
          o.maxYesterday = Helper.resolveMax(o.data[0]);
          o.maxToday = Helper.resolveMax(o.data[1]);
          if (
            o.maxToday &&
            this._isToday(o.maxToday.occ_timestamp) &&
            o.maxToday.value >= o.LANUV_MHW * 0.8 &&
            !Number.isNaN(o.LANUV_MHW) &&
            o.LANUV_MHW !== undefined &&
            o.LANUV_MHW !== 0.0 &&
            !_index.includes(o.station_id)
          )
            return o;
        }),
        e => e.station_name.toLowerCase(),
      );

      // Hochwassermeldepegel vom Vortag
      this.tableHochwasserMeldepegel = orderBy(
        filter(this.wasserstandMax, o => {
          merge(o, find(this.wasserstand, { station_id: o.station_id }));
          o.maxYesterday = Helper.resolveMax(o.data[0]);
          o.maxToday = Helper.resolveMax(o.data[1]);
          if (
            o.maxYesterday &&
            this._isYesterday(o.maxYesterday.occ_timestamp) &&
            o.maxYesterday.value >= o.LANUV_Info_1
          )
            return o;
        }),
        e => e.station_name.toLowerCase(),
      );
    } catch (e) {
      console.warn('Fehler beim Abruf der Wasserstandsdaten', e);
      this.errorInfoFetch = true;
    } finally {
      this.showLoader = false;
      await this.requestUpdate();
    }
  }

  sortTable(table, key) {
    // Manage table sorting states
    if (this.sort[table][key]) {
      this.sort[table][key] === 'asc'
        ? (this.sort[table][key] = 'desc')
        : (this.sort[table][key] = 'asc');
    } else this.sort[table][key] = 'asc';

    switch (table) {
      case 't1': {
        this.tableBerichte = orderBy(
          this.tableBerichte,
          [key],
          this.sort[table][key],
        );
        break;
      }
      case 't2': {
        this.tableInformationswertueberschreitungen = orderBy(
          this.tableInformationswertueberschreitungen,
          [
            e => (e[key].toLowerCase ? e[key].toLowerCase() : e[key]),
            e => e['station_name'].toLowerCase(),
          ],
          this.sort[table][key],
        );
        break;
      }
      case 't3': {
        this.tableHochwasserMeldepegel = orderBy(
          this.tableHochwasserMeldepegel,
          [
            e => (e[key].toLowerCase ? e[key].toLowerCase() : e[key]),
            e => e['station_name'].toLowerCase(),
          ],
          this.sort[table][key],
        );
        break;
      }
      case 't4': {
        this.tableGewaesserkundlichePegel = orderBy(
          this.tableGewaesserkundlichePegel,
          [
            e => (e[key].toLowerCase ? e[key].toLowerCase() : e[key]),
            e => e['station_name'].toLowerCase(),
          ],
          this.sort[table][key],
        );
        break;
      }
      default: {
        console.warn('Invalid table', table, key);
      }
    }

    this.requestUpdate();
  }

  /** Checks if a provided timestamp is yesterday
   * @remarks - Required for table 3 and 4 (show limit)
   * @returns - True if timestamp is yesterday
   */
  _isYesterday(timestamp: string): boolean {
    const yesterday = dayjs().subtract(1, 'days').format('DD.MM.YYYY');
    return dayjs(timestamp).format('DD.MM.YYYY') === yesterday;
  }

  _isToday(timestamp: string): boolean {
    const today = dayjs().format('DD.MM.YYYY');
    return dayjs(timestamp).format('DD.MM.YYYY') === today;
  }
}

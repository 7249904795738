/// <reference types="echarts" />

import dayjs from '@kisters/wcp-base/common/dayjsext';

/** TODO: i18n for empty graph */
export const echartsConfigTemplate = {
  title: {
    show: false,
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      animation: false,
    },
  },
  graphic: {
    type: 'text',
    left: 'center',
    top: 'middle',
    style: {
      text: 'Please select a timeseries',
      fontSize: 18,
      textAlign: 'center',
      fill: '#333',
    },
  },
  legend: {
    bottom: 10,
    textStyle: {
      fontSize: 14,
    }
  },
  grid: {
    left: '2%',
    right: '2%',
    containLabel: true,
    tooltip: {
      axisPointer: {
        label: {
          formatter: (params: any) => dayjs(params.value).format('L LT'),
        },
      },
    },
  },
  toolbox: {
    feature: {
      dataZoom: {
        filterMode: 'none', // Required for Thresholds to avoid filtering of outliers in time (e.g. 1950)
        type: 'inside',
      },
    },
  },
  xAxis: {
    type: 'time',
    tooltip: {
      valueFormatter: 'dd.MM.yyyy HH:mm',
    },
    axisLine: {
      onZero: false,
    },
    splitLine: {
      show: false,
    },
    axisLabel: {
      formatter: {
        year: '{yearStyle|{yyyy}}',
        month: '{normalStyle|{MMM}.}\n{subStyle|{yyyy}}',
        day: '{normalStyle|{dd}.}\n{subStyle|{MMM}. {yyyy}}',
        hour: '{normalStyle|{HH}:{mm}}\n{subStyle|{dd}.{MM}.{yyyy}}',
      },
      rich: {
        yearStyle: {
          fontWeight: 'bold',
          color: '#222',
          fontSize: 12,
        },
        normalStyle: { fontSize: 12 },
        subStyle: {
          fontSize: 12,
        },
      },
    },
  },
  yAxis: [{}],
  series: [],
} as echarts.EChartOption;

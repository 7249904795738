import { openGroup, openArray } from 'zarr';

export interface LayerMetadata {
  id: string;
  alias: string;
  layer_type: string;
  arrayNames: Array<string>;
}

export interface AllLayerMetadata {
  layers: Array<LayerMetadata>;
  creationDateInMillis: number;
}

export interface TimeseriesMetadata {
  arrayNames: Array<string>;
}

export interface AllTimeseriesMetadata {
  [tsName: string]: TimeseriesMetadata;
}

export interface ParameterMetadata {
  timeseries: AllTimeseriesMetadata;
}

export interface AllParameterMetadata {
  [parameterName: string]: ParameterMetadata;
}

export interface StationMetadata {
  zattr: {
    station_id: string;
    station_no: number;
    station_name: string;
    station_latitude: number;
    station_longitude: number;
    owner: string;
    river: string;
    basin: string;
    subbasin: string;
    state: string;
    district: string;
    subsystem: number;
    fcst_file_prefix: string;
    division: string;
    type: string;
    wl: number;
    dl: number;
    hfl_value: number;
    hfl_date: string;
  };
  parameter: AllParameterMetadata;
}

export interface AllStationsMetadata {
  [stationName: string]: StationMetadata;
}

export interface SiteMetadata {
  zattr: {
    site_no: string;
    site_name: string;
  };
  stations: AllStationsMetadata;
}

export interface AllSitesMetadata {
  [siteName: string]: SiteMetadata;
}

export interface DataMetadata {
  parameters: Array<{
    param_name: string;
    param_shortname: string;
    units: string;
  }>;
  timeseries: Array<{
    ts_name: string;
    ts_shortname: string;
    interpolation_type: number;
    ts_decimals: number;
    ts_resolution: string;
    is_forecast: boolean;
  }>;
  sites: AllSitesMetadata;
}

export const openZarrGroup = (groupPath: string) =>
  openGroup(`${window.location.origin}/internet`, groupPath, 'r');

export const openZarrArray = (arrayPath: string) =>
  openArray({
    store: `${window.location.origin}/internet`,
    path: arrayPath,
    mode: 'r',
  });

/*
 * Loads the layer Metadata File.
 *
 * @returns contents of the layer Metadata File.
 */
export const loadLayerMetadata = (): Promise<AllLayerMetadata> =>
  fetch(`/internet/layer/metadata.json`).then(meta => meta.json());

/*
 * Loads the data Metadata File.
 *
 * @returns contents of the data Metadata File.
 */
export const loadMetadata = (): Promise<DataMetadata> =>
  fetch(`/internet/data/metadata.json`).then(meta => meta.json());

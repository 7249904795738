import { html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { StatusIcon } from '@kisters/wcp-water/components/ki-status-icon/ki-status-icon';
import WwOverview from '@kisters/wiski-web/views/wwp-overview/ww-overview';
import StationsChartLayer from '@kisters/wcp-water/components/ki-station-map/StationsChartLayer';

@customElement('wwp-overview-lanuv')
export default class WwpOverviewLanuv extends WwOverview {
  get formatters() {
    const layer = this.___dataLayer;
    const factory = this.legendFactory;
    return {
      status(val, locale, col) {
        return StatusIcon(factory.pngs[val], factory.labels[val], col);
      },
      chart(val, locale, col, item) {
        const style = StationsChartLayer.getStationStyle(item, layer.config);
        item.__tagpriority = style.sortPriority;
        return html`<div class="cell" style="text-align: center;">
          <img src="${style.getImage().getImagePNGLegend()}" />
        </div>`;
      },
      info(val, locale, col) {
        // todo move into options.
        return val
          ? html`<div class="cell" style="text-align: center;">
              <ki-icon
                style="color:rgba(255,221,66,1);"
                title="${val}"
                icon="${col.icon || 'ki ki-info-circle'}"
              ></ki-icon>
            </div>`
          : html`<div></div> `;
      },
    };
  }
}

export type MetaMax = {
  timestamp: string;
  value: string;
  occ_timestamp: string;
  occ_count: number;
};

export class BerichteHelper {
  /** Return Wasserstand-Item */
  public static resolveMax(
    arr: [string, string, string, number],
  ): MetaMax | undefined {
    if (arr)
      return {
        timestamp: arr[0],
        value: arr[1],
        occ_timestamp: arr[2],
        occ_count: arr[3],
      };
    return undefined;
  }

  public static _unescapeXml(escapedXml) {
    if (!escapedXml) escapedXml = '';
    const temp = escapedXml
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&amp;/g, '&')
      .replace(/&apos;/g, "'")
      .replace(/&quot;/g, '"');

    return temp.replace(/<br\s*\/?>/g, '\n');
  }
}

/* eslint-disable class-methods-use-this */
import { i18nMixin } from '@kisters/wcp-base/decorators';
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import { Mix } from '@kisters/wcp-base/common';
import { some } from 'lodash-es';
import nls from '../../locales/index';
import { TsAnnotation } from './TsAnnotation';

@customElement('lanuv-ts-annotations')
export default class LanuvTsAnnotations extends Mix(LitElement, [
  i18nMixin,
  { nls },
]) {
  static styles = css`
    :host {
      display: block;
      position: relative;
      overflow: auto;
    }

    .hidden {
      display: none;
    }

    .container {
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
    }

    .annotation {
      padding: 15px 10px;
      display: inline-float;
      overflow: hidden;
    }

    .anno-title {
      width: 60px;
      float: left;
      font-weight: bold;
      font-size: small;
    }

    .anno-text {
      max-width: 320px;
      overflow: hidden;
      font-size: small;
      float: left;
    }

    .anno-single-col {
      float: left;
      font-weight: bold;
      font-size: small;
    }

    #infolink > a {
      font-size: small;
      margin-left: 10px;
      text-decoration: none;
      color: rgba(0, 158, 224, 1);
    }

    #infolink > a:hover {
      font-weight: bold;
      color: #005596;
    }

    #infolink > a:visited {
      color: #005596;
    }
  `;

  @property({ type: Array })
  annotations: TsAnnotation[] = [];

  @property({ type: String })
  additionalAnnotationUrl: string = '';

  updateAnnotations(annos: TsAnnotation[]) {
    this.annotations = annos;
    this.requestUpdate();
  }

  // Used for Chart Exporting (Legend)
  getAnnotationsAsHtml(): string {
    let str = `<div style="width:800px">`;
    // eslint-disable-next-line array-callback-return
    this.annotations.forEach(annot => {
      if (annot.visibleInChart || annot.exportAlways)
        str += `<span style="color: ${annot.color}">${
          annot.symbol ? annot.symbol : ''
        } </span><span style="margin: 10px"><b>${
          annot.label?.text
        }</b></span> ${
          annot.label?.description
            ? ` - <span>${annot.label.description}</span><br/>`
            : '<br/>'
        }`;
    });
    str += `</div>`; // End of exported div
    console.log('HTML-Export: ', str);
    return str;
  }

  constructor() {
    super();
    this.annotations = [];
  }

  _hasAnyShowInBox() {
    const show = some(this.annotations, a => a.showInBox === true);
    const ac = this.shadowRoot.querySelector('#anno-container');
    if (!show && ac) {
      ac.classList.add('tiny');
    } else ac.classList.remove('tiny');
    return show;
  }

  // Show key/label with description in separate rows
  _renderMultiCol(annot) {
    return html`<div class="annotation">
      <div class="anno-title" title="${annot.label.text}">
        ${annot.label.shortText ? annot.label.shortText : annot.label.text}
      </div>
      <div class="anno-text">${annot.label.description}</div>
    </div>`;
  }

  // Show annotation in single row
  _renderSingleCol(annot) {
    return html`<div class="annotation">
      <div class="anno-single-col" title="${annot.label.text}">
        ${annot.label.shortText ? annot.label.shortText : annot.label.text}
      </div>
    </div>`;
  }

  //
  _renderRow(annot) {
    if (annot.showInBox) {
      return annot.label.description
        ? this._renderMultiCol(annot)
        : this._renderSingleCol(annot);
    }
    return html``;
  }

  render() {
    // language=html
    return html` <div id="anno-container" class="container">
      ${this.annotations.map(annot =>
        annot.showInBox && !annot.dependsOnOtherAnnotations
          ? this._renderRow(annot)
          : html``,
      )}
      ${this.annotations.map(annot =>
        annot.showInBox && annot.dependsOnOtherAnnotations
          ? this._renderRow(annot)
          : html``,
      )}

      <div
        id="infolink"
        class="${this.additionalAnnotationUrl &&
        this.additionalAnnotationUrl !== ''
          ? ''
          : 'hidden'}"
      >
        <a href="${this.additionalAnnotationUrl}">Weitere Informationen</a>
      </div>
    </div>`;
  }
}

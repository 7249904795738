/* eslint-disable func-names */
/* eslint-disable array-callback-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */
import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
// eslint-disable-next-line import/extensions
import { property } from 'lit/decorators/property.js';
import { getRouteOptionsAndParams } from '@kisters/wcp-base/components';
import { i18nMixin, responsiveMixin } from '@kisters/wcp-base/decorators';
import { Mix } from '@kisters/wcp-base/common';
import { SM, ViewPort } from '@kisters/wcp-base/decorators';
import nls from '../locales/index';

@customElement('ww-lanuv-downloads')
export default class WwLanuvDownloads extends Mix(LitElement, responsiveMixin, [
  i18nMixin,

  { nls },
]) {
  // language=CSS
  static styles = css`
    :host {
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
    }

    .box {
      margin: 10pt;
      margin-right: 0px;
      width: 48vw;
      max-width: 700px;
      float: left;
    }

    .container {
      display: flex;
    }

    :host(.sm-screen) .container {
      display: block;
    }

    fieldset {
      height: 95%;
      min-height: 400px;
    }

    @media screen and (max-width: 1280px) {
      :host {
        overflow: auto;
        display: block;
      }

      .box,
      .box-wide {
        width: 95vw;
        max-width: 950px;
        font-size: 0.9em;
        margin: 5px;
      }

      .kennzahlen-table {
        margin: 0px;
      }
    }

    h3 {
      color: #383838;
    }

    table,
    th,
    td {
      border-bottom: 1px solid #686868;
      border-collapse: collapse;
      color: #383838;
    }

    th {
      text-align: left;
      padding: 10px;
    }

    th.val {
      text-align: right;
      padding: 10px;
    }

    td {
      padding: 10px;
    }

    tr:hover {
      background: lightgray;
      cursor: pointer;
    }

    td:nth-child(2) {
      color: #666;
    }

    tr:first-child:hover {
      background: unset;
      cursor: unset;
    }

    tr:last-child td {
      border-bottom-style: hidden;
    }

    a:link {
      text-decoration: none;
      color: rgba(0, 158, 224, 1);
    }

    a:visited {
      text-decoration: none;
      color: #005596;
    }

    a:hover {
      text-decoration: none;
      font-weight: bold;
      color: #005596;
    }

    legend {
      color: #686868;
    }

    fieldset {
      border: 1px #686868 solid;
    }

    a:active {
      text-decoration: none;
    }

    .center {
      text-align: center;
    }
  `;

  static get properties() {
    return {
      content: { type: String },
    };
  }

  @property({ type: String })
  prefix;

  onAfterEnter(location) {
    this.prefix =
      getRouteOptionsAndParams(location, ['dataPrefix']).options?.dataPrefix ||
      '';
  }

  goTo(href) {
    window.open(this.prefix + href);
  }

  renderDownloads() {
    return html`<div class="box">
    <fieldset>
      <legend>Rohdaten zum Herunterladen</legend>
      <div>
      <table>
<tr>
  <th>Inhalt der Datei</th>
  <th>Intervall</th>
  <th>Download</th>
</tr>
<tr @click="${() => {
      this.goTo('messwerte.zip');
    }}"
  >
  <td><b>Wasserstanddaten</b> (zeitlich hochaufgelöste Werte) der LANUV-Stationen der letzten Woche</td>
  <td>kontinuierlich</td>
  <td class="center"><img width="18px" src="./images/download.svg" alt="Download" /></td>
  </a></tr>
<tr @click="${() => {
      this.goTo('pegeldaten.zip');
    }}"
 >
  <td><b>Wasserstanddaten</b> (zeitlich hochaufgelöste Werte, Tagesmittelwerte, Tagesmaxima und Metadaten) der LANUV-Stationen der letzten zwei Monate bzw. zwei Jahre</td>
  <td>täglich (Tageswechsel)</td>
  <td class="center"><img width="18px" src="./images/download.svg" alt="Download" /></td>
</tr>
<tr @click="${() => {
      this.goTo('niederschlag.zip');
    }}">
  <td><b>Niederschlagdaten</b> (Stundensummen) der LANUV-Stationen der letzten Woche</td>
  <td>kontinuierlich</td>
  <td class="center"><img width="18px" src="./images/download.svg" alt="Download" /></td>
</tr>
<tr @click="${() => {
      this.goTo('niederschlagsdaten.zip');
    }}">
  <td><b>Niederschlagdaten</b> (Stundensummen, Tagessummen und  Metadaten) der LANUV-Stationen der letzten zwei Monate bzw. zwei Jahre</td>
  <td>täglich (Tageswechsel)</td>
  <td class="center"><img width="18px" src="./images/download.svg" alt="Download" /></td>
</tr>
<tr @click="${() => {
      this.goTo('temperatur.zip');
    }}">
  <td><b>Wassertemperaturdaten</b> (zeitlich hochaufgelöste Werte) der LANUV-Stationen der letzten Woche</td>
  <td>kontinuierlich</td>
  <td class="center"><img width="18px" src="./images/download.svg" alt="Download" /></td>
</tr>
<tr @click="${() => {
      this.goTo('temperaturdaten.zip');
    }}">
  <td><b>Wassertemperaturdaten</b> (zeitlich hochaufgelöste Werte, Tagesmittelwerte, Tagesmaxima und Metadaten) der LANUV-Stationen der letzten zwei Monate bzw. zwei Jahre</td>
  <td>täglich (Tageswechsel)</td>
  <td class="center"><img width="18px" src="./images/download.svg" alt="Download" /></td>
</tr>
</table>
      </div>
    </fieldset>
  </div>`;
  }

  renderAnnotations() {
    return html`<div class="box">
      <fieldset>
        <legend>Erläuterungen</legend>
        <div>
          <p>
            Alle auf dieser Internetseite veröffentlichten Beiträge, Abbildungen
            und Daten sind urheberrechtlich geschützt. Jede vom
            Urheberrechtsgesetz nicht zugelassene Verwertung bedarf der
            vorherigen Zustimmung des Herausgebers. Wenn Sie eine kommerzielle
            Nutzung der Daten beabsichtigen, wenden Sie sich bitte an das
            <a href="https://www.lanuv.nrw.de/">LANUV</a>, um eine
            Nutzungsvereinbarung abzuschließen.
            <br />
            Der Herausgeber übernimmt keine Gewähr über die Richtigkeit und
            Vollständigkeit. Die Haftung für Schäden materieller oder ideeller
            Art, die durch die Nutzung der Inhalte verursacht wurden, ist
            ausgeschlossen, sofern nicht Vorsatz oder grobe Fahrlässigkeit
            vorliegt.
          </p>
          <p class="warning">
            Bei den hier abrufbaren Daten handelt es sich um ungeprüfte
            Rohdaten, die mit Fehlern und Datenlücken behaftet sein können. Aus
            diesem Grund kann für die Vollständigkeit und Richtigkeit dieser
            Daten keine Gewähr übernommen werden. Diese Daten sind für
            Simulationen, Berechnungen, Analysen usw. nicht geeignet und können
            zu massiven Fehlaussagen führen.
          </p>
          <p>
            <b>Geprüfte Daten</b> finden Sie im
            <a href="https://www.elwasweb.nrw.de/elwas-web/index.xhtml"
              >Fachinformationssystem ELWAS</a
            >
          </p>
          <p>
            Zur Nutzung von Daten für Simulationen, Berechnungen, Analysen usw.
            sollten Sie Kontakt mit den zuständigen Stellen im
            <a href="https://www.lanuv.nrw.de/">LANUV</a> aufnehmen, damit dort
            eine Einschätzung zur Verwendbarkeit der vorhandenen Daten für den
            von Ihnen vorgesehenen Zweck vorgenommen werden kann. Im LANUV kann
            auch geprüft werden, ob weitere Daten für Ihr Anliegen zur Verfügung
            gestellt werden können.
          </p>
        </div>
      </fieldset>
    </div>`;
  }

  render() {
    // Customer wants different box order for different screen sizes (LANUVTEMP-56)
    return html`<div class="container">
      ${ViewPort.size === SM
        ? this.renderAnnotations()
        : this.renderDownloads()}
      ${ViewPort.size === SM
        ? this.renderDownloads()
        : this.renderAnnotations()}
    </div>`;
  }
}
